const dataWatch = [
  {
    title: 'KOL Verification and Reputation System',
    desc: `KOLWatch employs a rigorous verification process to onboard legitimate KOLs and influencers. This process includes verifying their track record, credibility, and historical performance in the crypto space. Each KOL is assigned a reputation score that reflects their reliability and accuracy over time.`,
    class: 'pad'
  },
  {
    title: 'Staking Pools',
    desc: `Users can stake their tokens in staking pools associated with specific KOLs. These staking pools represent a vote of confidence in the KOL's calls and picks. The more tokens staked in a particular pool, the more significant the impact on that KOL's profits.`,
    class: 'pad'
  },
  {
    title: 'Profit Distribution',
    desc: `When a KOL's calls result in profits, the earnings are distributed in a predefined ratio: 75% to the staking pool and 25% to the KOL. This mechanism aligns the interests of KOLs and their followers while ensuring that the community benefits from accurate and well-researched calls. This pool is Funded by KOL WATCH FUND and the community.`,
    class: 'pad'
  }, {
    title: 'Transparent Performance Tracking',
    desc: `KOLWatch provides a user-friendly dashboard that tracks the performance of each KOL's calls and picks. Real-time data, historical performance graphs, and success rates are presented to users, allowing token holders to make informed decisions about where to stake or bet their tokens.`,
    class: 'pad'
  }, {
    title: 'Fraud Detection and Bad Actor Tagging',
    desc: `To discourage and eliminate bad actors, KOLWatch closely monitors the performance of each KOL. If a KOL's account goes to zero due to consistently inaccurate calls or unethical behavior, they are tagged as a "FAKE" and "BAD ACTOR." This designation is prominently displayed on their profile, providing a clear warning to potential followers and showing what sort of bad calls or scam projects they promote it, not only scams but including pump and dumps, farm projects etc`,
    class: 'pad'
  }, {
    title: 'Community Governance',
    desc: `KOLWatch involves the community in making decisions about the platform's rules, new KOL onboarding, and improvements. Decisions related to KOL verification and penalties for bad actors are made collectively through decentralized governance mechanisms.`,
    class: 'pad'
  }, {
    title: 'Educational Resources',
    desc: `In addition to tracking performance, KOLWatch offers educational content provided by verified KOLs. This content helps users learn about investment strategies, market analysis, and risk management, enhancing their crypto investment skills.`,
    class: 'pad'
  }, {
    title: 'Token Economy',
    desc: `The KOLWatch ecosystem operates on its native utility token (WATCH). Tokens are used for staking, voting, betting and participating in governance decisions. They can also be earned by contributing accurate market insights and analyses.`,
    class: 'pad'
  },
];
export default dataWatch;
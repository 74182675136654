import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss'
import AboutItem from '../../features/about/about-item';

Watch.propTypes = {
    data: PropTypes.array,
};

function Watch(props) {

    const { data } = props;

    const [dataBlock] = useState(
        {
            subtitle: 'WATCH PAPER',
            title: 'KOLWatch',
        }
    )
    const [dataSubBlock] = useState({
        subtitle: 'Key Features:',
        title: 'Description',
        desc: `KOLWatch is a revolutionary crypto project designed to bring transparency and accountability to the world of cryptocurrency Key Opinion Leaders (KOLs) and influencers. It addresses the problem of misleading token calls and picks by providing a platform where users can confidently stake their tokens in their favorite KOL Staking Pools. The project aims to reward genuine and reliable KOLs while identifying and exposing fake and irresponsible actors in the industry.`
    })
    return (
        <section className="tf-section tf-about">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="content-about mobie-40" data-aos="fade-up" data-aos-duration="800">
                            <div className="tf-title">
                                <p className="h8 sub-title">{dataBlock.subtitle}</p>
                                <h4 className="title">{dataBlock.title}</h4>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row background pt60">
                <div className="container">
                    <div className="col-md-12 ">
                        <div className="st2 mb-60" data-aos="fade-up" data-aos-duration="800">
                            <h4 className="title m-b17">{dataSubBlock.title}</h4>
                            <p className="p-r40">{dataSubBlock.desc} </p>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="st2" data-aos="fade-up" data-aos-duration="800">
                            <h4 className="title m-b17">{dataSubBlock.subtitle}</h4>
                        </div>

                    </div>
                    <div className="row" data-aos="fade" data-aos-delay="00" data-aos-duration="800">
                        {
                            data.map(item => (
                                <div key={item.id} className="col-xl-6 col-lg-6 col-md-12 pad" >
                                    <AboutItem item={item} />
                                </div>
                            ))
                        }
                    </div>
                    <div className="row">
                        <div className="col-xl-7 col-md-12">
                            <div className="" data-aos="fade-up" data-aos-duration="800">

                                <p>KOLWatch envisions a crypto landscape where reliable KOLs are rewarded for their insights, and users can confidently make investment decisions based on accurate information. By promoting transparency, accountability, and community-driven governance, KOLWatch aims to create a more trustworthy and informed crypto ecosystem.</p>

                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </section >
    );
}

export default Watch;
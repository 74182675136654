import React, { FunctionComponent } from 'react';
import { AreaChart, Area, XAxis, ResponsiveContainer, YAxis, Tooltip, CartesianGrid } from 'recharts';
import { colors } from 'src/colors'

//////
//Gh//
//////

const data = [
  {
    price: Math.random() + 1,
    time: '23:00',
  },
  {
    price: Math.random(),
    time: '2:00',
  },
  {
    price: Math.random(),
    time: '5:00',
  },
  {
    price: Math.random(),
    time: '8:00',
  },
  {
    price: Math.random(),
    time: '11:00',
  },
  {
    price: Math.random(),
    time: '14:00',
  },
  {
    price: Math.random(),
    time: '17:00',
  },
  {
    price: Math.random(),
    time: '20:00',
  },
];

const CustomizedXAxisTick: FunctionComponent<any> = (props: any) => {
    const { x, y, payload, fill } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={5}
          y={0}
          dy={25}
          fontSize={'12px'}
          fontWeight={400}
          textAnchor="start"
          fill={fill}
        >
          {payload.value}
        </text>
      </g>
    );
};

const CustomizedYAxisTick: FunctionComponent<any> = (props: any) => {
    const { x, y, payload, fill } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={5}
          fontSize={'14px'}
          fontWeight={500}
          textAnchor="end"
          fill={fill}
        >
          {payload.value}
        </text>
      </g>
    );
};

const TooltipContent = (props: any) => {
  const { payload } = props;
  const total = payload.reduce((result: any, entry: any) => result + entry.value, 0);

  return (
    <div style={{
      backgroundColor: colors.black04,
      borderRadius: '12px',
      border: `1px solid ${colors.green0E}`,
      padding: '12px',
      height: '25px',
      maxHeight: '25px',
      lineHeight: '25px', 
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      opacity: '70%'
    }}>
      <p style={{
        color: colors.green1B,
        fontSize: 12,
        fontWeight: 700,
        textAlign: 'center',
        marginBottom: 0
      }}>{total > 1 ? `High: ${total}` : `Low: ${total}`}
      </p>
    </div>
  );
};

export default function MyAreaChart() {

    return (
      <ResponsiveContainer width='100%' height='100%'>
        <AreaChart width={400} height={149.2} data={data}>
        <defs>
            <linearGradient id="backgroundGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="1%" stopColor="#00E0B5" stopOpacity={.003}/>
                <stop offset="80%" stopColor="#0b2036" stopOpacity={.2322}/>
            </linearGradient>
        </defs>
        <defs>
            <linearGradient id="lineGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%"  stop-color="#4675E9"/>
                <stop offset="50%"  stop-color="#A3927E"/>
                <stop offset="100%" stop-color="#CAA43B"/>
            </linearGradient>
        </defs>
          <CartesianGrid strokeDasharray="1 0" horizontal={true} vertical={false} stroke='#0c1226' />
          <XAxis dataKey="time" stroke='transparent' tick={<CustomizedXAxisTick fill={'#00E0B5'} />} />
          <YAxis dataKey='price' stroke='transparent' tick={<CustomizedYAxisTick fill={'#00E0B5'} />}/>
          <Tooltip content={<TooltipContent stroke='transparent' />} cursor={{ fill: 'transparent' }} />
          <Area dataKey='price' stroke="url(#lineGradient)" fillOpacity={1} fill="url(#backgroundGradient)" strokeWidth={2} />
        </AreaChart >

      </ResponsiveContainer>
    );
 
}

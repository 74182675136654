import { styled } from '@mui/material/styles';
import MuiTextField from '@mui/material/TextField';

import { colors } from 'src/colors';

export const TextField = styled(MuiTextField)(() => ({
    '& .MuiInputBase-root': {
        height: 100,
        width: "136.8px",
        fontSize: 18,
        fontWeight: 700,
        color: colors.white,
        padding: 0,
        backgroundColor: `${colors.green12} !important`,
        border: `1px solid ${colors.green}`,
        borderRadius: 0,
        '& input': {
            height: 70,
            width: "136.8px",
            color: colors.white,
            padding: 15,
            textAlign: 'center'
        },
        '& fieldset': {
            borderRadius: 0,
        },
        '&:hover fieldset': {
            borderColor: colors.green
        },
        '&.Mui-focused fieldset': {
            border: 0
        },
    }

}));
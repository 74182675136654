import { styled } from '@mui/material/styles';
import MuiTextField, { TextFieldProps } from '@mui/material/TextField';

//projectName:string agency-panel|admin-panel
type customTextFieldProps = TextFieldProps & { projectName?: string };

export const TextFieldSearch = styled(MuiTextField)<customTextFieldProps>((props) => ({
    height: '36px',
    '& .MuiInputBase-root': {
        background: '#123142',
        fontSize: 16,
        fontWeight: 400,
        border: '2px solid #0E3841',
        borderRadius: 0,
        fieldset: {
            border: '2px solid #0E3841',
        },
        
    },
    '& .MuiOutlinedInput-input': {
        width: '273px',
        height: '36px',
        border: 'none',
        color: "white",
        padding: 0,
        '&::placeholder': {
            color: '#B1BBCE',
            opacity: 1
        }
    },
    '& .MuiOutlinedInput-root': {
        height: '36px',
        width: '273px',
        '& fieldset': {
            border: '2px solid #0E3841',
        },
        '&:hover fieldset': {
            border: '2px solid #0E3841',
        },
        '&.Mui-focused fieldset': {
            border: '2px solid #0E3841',
        },
    },
}));
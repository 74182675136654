import React, { Dispatch, SetStateAction } from "react";
import { Stack, Typography } from '@mui/material';
import { colors } from 'src/colors';
import { Input, Button } from 'src/components';
import { LeftArrowIcon } from 'src/assets/icons';
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/hooks/ReduxHooks";
import { useUpdateProfileMutation } from "src/api/user";
import { useFormik } from "formik";
import { setUpdateUserInfo } from "src/slices/mainSlice";
import { toast } from "react-toastify";
export const initialValues: {
  youtube: string
  telegram: string,
  instagram: string,
  twitter: string,
  reddit: string,
  ethWallet: string,
  bnbWallet: string,
  trcWallet: string,
  btcWallet: string,
  ftmWallet: string,
} = {
  youtube: '',
  telegram: '',
  instagram: '',
  twitter: '',
  reddit: '',
  ethWallet: '',
  bnbWallet: '',
  trcWallet: '',
  btcWallet: '',
  ftmWallet: '',
}

const MoreAboutYou: React.FC<{ setStep: Dispatch<SetStateAction<number>> }> = ({ setStep }) => {
  const navigate = useNavigate();
  const Dispatch = useAppDispatch();
  const [updateProfile] = useUpdateProfileMutation();
  const user = useAppSelector(store => store.mainSlice.user);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {

      updateProfile({ data: values, token: user.accessToken ?? '' })
        .unwrap()
        .then((res) => {
          Dispatch(setUpdateUserInfo(values));
          toast.success('Update Profile Success');
          navigate('/auth/submit/subscribe');
        })
    }
  });
  const { getFieldProps } = formik;
  return (
    <form onSubmit={formik.handleSubmit}>

      <Stack sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors.green0E2,
        justifyContent: 'flex-start',
        padding: '8px 24px 60px 24px',
        gap: '20px',
        // height: '780px',
        marginY: '25px',
        marginRight: '22px'
      }}>
        <Stack
          flexDirection={'row'}
          alignItems={'center'}
          gap={'12px'}
          marginLeft={'13px'}
          sx={{ cursor: 'pointer' }}
          onClick={() => setStep((prev: number) => prev - 1)}
        >
          <LeftArrowIcon />
          <Typography
            fontSize={16}
            fontWeight={600}
            lineHeight={'60px'}
            color={colors.white}
          >
            Back
          </Typography>
        </Stack>
        <Stack gap={'.5px'}>
          <Typography
            fontSize={14}
            fontWeight={400}
            color={colors.grayB1}
            lineHeight={'21px'}
          >
            Submit a KOL application
          </Typography>
          <Typography
            fontSize={18}
            fontWeight={700}
            color={colors.white}
            lineHeight={'27px'}
            whiteSpace={'pre-wrap'}
          >
            More About you
          </Typography>
        </Stack>


        <Input
          label="YouTube:"
          id="youtube"
          {...getFieldProps('youtube')}
        />
        <Input
          label="Telegram:"
          id="telegram"
          {...getFieldProps('telegram')}
        />
        <Input
          label="Instagram:"
          id="instagram"
          {...getFieldProps('instagram')}
        />
        <Input
          label="Twitter:"
          id="twitter"
          {...getFieldProps('twitter')}
        />
        <Input
          label="Reddit:"
          id="reddit"
          {...getFieldProps('reddit')}
        />
        <Input
          label="ETH WALLET:"
          id="ethWallet"
          {...getFieldProps('ethWallet')}
        />
        <Input
          label="BNB WALLET:"
          id="bnbWallet"
          {...getFieldProps('bnbWallet')}
        />
        <Input
          label="TRC WALLET:"
          id="trcWallet"
          {...getFieldProps('trcWallet')}
        />
        <Input
          label="BITCOIN WALLET:"
          id="btcWallet"
          {...getFieldProps('btcWallet')}
        />
        <Input
          label="FTM WALLET:"
          id="ftmWallet"
          {...getFieldProps('ftmWallet')}
        />



        <Stack display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
          <Button
            variant={"outlined"}
            LabelColor={colors.black}
            borderColor={colors.green22}
            backgroundColor={colors.green}
            bgHover={colors.green}
            sx={{ height: 52, width: '265px' }}
            type="submit"
          >
            <Typography
              fontSize={16}
              fontWeight={700}
              lineHeight={'16px'}
              color={colors.white}
            >
              Next
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default MoreAboutYou;
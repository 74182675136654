import { Stack } from "@mui/material";
import { ReactElement } from "react";
import PortfolioDetails from "src/features/portfolio";

export default function Portfolio(): ReactElement {
  return (
    <Stack height={'100%'}>
      <PortfolioDetails />
    </Stack>
  );
}
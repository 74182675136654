import { Stack } from "@mui/material";
import { ReactElement } from "react";
import ForgotPassword from "src/features/authentication/pages/login/ForgotPassword";
import Header from "src/components/header";

export default function ForgotPasswordPage(): ReactElement {
  return (<>
        <Header />
        <Stack height={'100%'} alignItems={'center'}>
            <ForgotPassword />
        </Stack>
    </>);
}
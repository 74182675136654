import React from "react";
import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { colors } from 'src/colors';
import { Input, Button } from 'src/components';
import { LeftArrowIcon } from 'src/assets/icons';
import { REGISTER_CHOOSE_PICTURE_URL } from 'src/config/urls';
import { useFormik } from "formik";
import { initialValues, validationSchema } from "src/formValidations/setPassword";
import { useSetPasswordMutation } from "src/api/auth";
import { useAppDispatch, useAppSelector } from "src/hooks/ReduxHooks";
import { toast } from "react-toastify";
import { setUserRegisterInfo } from "src/slices/mainSlice";

const CreatePassword: React.FC<{}> = () => {
    const [setPassword] = useSetPasswordMutation();
    const userSetPasswordToken = useAppSelector(s => s.mainSlice.user.setPasswordToken);
    const Dispatch = useAppDispatch();
    const user = useAppSelector(s => s.mainSlice.user);
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setPassword({ password: values.password, token: userSetPasswordToken ?? '' })
                .unwrap()
                .then((res) => {
                    Dispatch(setUserRegisterInfo(
                        {
                            ...user,
                            registered: res.data.registered,
                            accessToken: res.data.accessToken,
                            refreshToken: res.data.refreshToken
                        }
                    ));
                    toast.success(res.message);
                    navigate(REGISTER_CHOOSE_PICTURE_URL);

                })
        }
    });
    const { getFieldProps } = formik;
    const navigate = useNavigate();

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: colors.green0E2,
                justifyContent: 'flex-start',
                padding: '8px 24px 24px 24px',
                gap: '20px',
                height: '780px',
                marginY: '25px',
                marginRight: '22px'
            }}>
                {/* <ChangePasswordIcon /> */}
                <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    gap={'12px'}
                    marginLeft={'13px'}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => navigate(-1)}
                >
                    <LeftArrowIcon />
                    <Typography
                        fontSize={16}
                        fontWeight={600}
                        lineHeight={'60px'}
                        color={colors.white}
                    >
                        Back
                    </Typography>
                </Stack>
                <Stack gap={'.5px'}>
                    <Typography
                        fontSize={14}
                        fontWeight={400}
                        color={colors.grayB1}
                        lineHeight={'21px'}
                    >
                        Choose a password
                    </Typography>
                    <Typography
                        fontSize={18}
                        fontWeight={700}
                        color={colors.white}
                        lineHeight={'27px'}
                        whiteSpace={'pre-wrap'}
                    >
                        {`Make sure your new password is least 8 characters contains\nletters - numbers - special characters`}
                    </Typography>
                </Stack>

                <Input
                    label="New password"
                    id="password"
                    type="password"
                    {...getFieldProps('password')}
                />
                <Input
                    label="Confirm new password"
                    id="confirmpassword"
                    type="password"
                    {...getFieldProps('confirmpassword')}
                />
                <Stack display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
                    <Button
                        variant={"outlined"}
                        LabelColor={colors.black}
                        borderColor={colors.green22}
                        backgroundColor={colors.green}
                        bgHover={colors.green}
                        sx={{ height: 52, width: '265px' }}
                        disabled={!formik.isValid}
                        type='submit'
                    >
                        <Typography
                            fontSize={16}
                            fontWeight={700}
                            lineHeight={'16px'}
                            color={colors.white}
                        >
                            Next
                        </Typography>
                    </Button>
                </Stack>
            </Stack>
        </form>
    );
};

export default CreatePassword;
import { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss'
import AboutItem from './about-item';
import { useNavigate } from 'react-router-dom';
import { Grid, Stack, Typography } from '@mui/material';
import { Button } from "../../components";
import { colors } from "../../colors";

About.propTypes = {
    data: PropTypes.array,
};

function About(props) {
    const navigate = useNavigate();
    const { data } = props;

    const [dataBlock] = useState(
        {
            subtitle: 'About us',
            title: 'What is kolwatch?',
            desc: `KOLWatch is a revolutionary crypto project designed to bring transparency and accountability to the \nworld of cryptocurrency Key Opinion Leaders (KOLs) and influencers. It addresses the problem of \nmisleading token calls and picks by providing a platform where users can confidently stake their tokens \nin their favorite KOL Staking Pools. The project aims to reward genuine and reliable KOLs while \nidentifying and exposing fake and irresponsible actors in the industry.`
        }
    )

    return (
        <section id='about-us' className="tf-section tf-about">
            <div className="container">
                <Stack display={'flex'} flexDirection={'column'} marginBottom={'40px'} alignItems={'center'}>
                    <Stack gap={'10px'} alignItems={'center'}>
                        <Typography 
                                fontSize={20}
                                fontWeight={700}
                                color={colors.green}
                                lineHeight={'32px'}
                                textTransform={'uppercase'}
                            >
                                {dataBlock.subtitle}
                            </Typography>
                            <Typography 
                                fontSize={44}
                                fontWeight={700}
                                color={colors.white}
                                lineHeight={'52.8px'}
                                textTransform={'uppercase'}
                            >
                                {dataBlock.title}
                            </Typography>
                    </Stack>
                    <Typography 
                        fontSize={17}
                        fontWeight={400}
                        color={colors.grayB9}
                        lineHeight={'28px'}
                        textAlign={'center'}
                        whiteSpace={'pre-wrap'}
                        margin={'12px 0 30px 0'}
                        sx={{ wordSpacing: '8px' }}
                    >
                        {dataBlock.desc}
                    </Typography> 
                    <Button
                        variant={"outlined"}
                        LabelColor={colors.white}
                        borderColor={colors.green}
                        borderWidth="2px"
                        bgHover={colors.green}
                        sx={{
                            width: 218,
                            height: 64,
                        }}
                        onClick={() => { navigate('/watch-paper') }}
                    >
                        <Typography
                            fontSize={16}
                            fontWeight={600}
                            lineHeight={'24px'}
                            color={colors.white}
                            textTransform={'uppercase'}
                            >
                            read whitepaper
                        </Typography>
                    </Button>
                </Stack>
                <Grid container spacing={3} columns={16}
                    data-aos="fade-up" data-aos-duration="800"
                >
                    {
                        data.map(item => (
                            <AboutItem key={item.id} item={item} />
                        ))
                    }
                </Grid>
            </div>
        </section>
    );
}

export default About;
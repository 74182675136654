import { FC, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { colors } from 'src/colors';
import { Stack, CircularProgress, Box } from '@mui/material';
import { ProfileIcon, PlusIcon } from 'src/assets/icons';
import { useUploadProfileMutation } from 'src/api/user';
import { useAppSelector } from 'src/hooks/ReduxHooks';

export const ImageUpload: FC<IProps> = ({
    maxSize = 2000000,
    disabled,

    display,
    onFileUrl,
    onError
}) => {

    const [loading,] = useState<any>(false);
    const [uploadProfile] = useUploadProfileMutation();
    const user = useAppSelector(s => s.mainSlice.user);

    const onDrop = useCallback((_acceptedFiles: any) => {

        if (_acceptedFiles?.length > 0) {
            _acceptedFiles.map((_file: any) => {

                if (_file?.size <= maxSize) {
                    const formData = new FormData();
                    formData.append('photo', _file);
                    uploadProfile({ photo: formData, token: user.accessToken ?? '' })
                        .unwrap()
                        .then((res: any) => {
                            onFileUrl(res.data.photo);

                        });
                    // onFileUrl(_file);
                } else {
                    onError!('The maximum photo size is 2mb');
                }

            });
        }
    }, []);

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        disabled: disabled,
        accept: { 'image/png': [], 'image/jpg': [], 'image/svg': [], 'image/jpeg': [] },
        onDrop,
    });

    return (
        <Box
            {...getRootProps({ className: 'dropzone' })}
            width={'100%'}
            height={'100%'}
            display={display}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{ cursor: 'pointer', borderRadius: '100%' }}
            bgcolor={colors.gray3D}
        >
            <input {...getInputProps()} />
            {loading ? <CircularProgress
                style={{
                    width: '24px',
                    height: '24px',
                    color: colors.green0E22
                }} /> : <>
                <ProfileIcon width={'100%'} height={'100%'} />
                <Stack sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }} >
                    <PlusIcon width={33} height={33} />
                </Stack>
            </>}
        </Box>
    );
};
export interface IProps {
    width?: string;
    height?: string;
    disabled?: boolean;
    maxSize?: string | number;
    imageUrl?: any;
    display?: string;
    onFileUrl: (fileUrl: string) => void;
    onError?: (txt: string) => void;
}
export default ImageUpload;
import { FORGOT_PASSWORD_API_URL, LOGIN__API_URL, REGISTER_API_URL, SEND_VERIFICATION__EMAIL_URL, SET_PASSWORD_URL, VERIFICATION_EMAIL_URL } from 'src/config/apiUrls';
import { api } from './api';
import { ILogin } from 'src/models/authApi';
export const authApi = api.injectEndpoints({
    endpoints: (build) => ({
        login: build.mutation<ILogin.Response, ILogin.Request>({
            query: (params) => ({
                url: LOGIN__API_URL,
                method: 'POST',
                body: params
            }),
        }),
        register: build.mutation<any, ILogin.Register>({
            query: (params) => ({
                url: REGISTER_API_URL,
                method: 'POST',
                body: params
            })

        }),
        forgetPassword: build.mutation<ILogin.ForgotPassword, { email: string }>({
            query: (params) => ({
                url: FORGOT_PASSWORD_API_URL,
                method: 'POST',
                body: params
            })
        }),
        verificationEmail: build.mutation<any, ILogin.VerificationEmail>({
            query: (params) => ({
                url: VERIFICATION_EMAIL_URL,
                method: 'POST',
                body: params
            })
        }),
        sendVerificationEmail: build.mutation<any, ILogin.SendVerification>({
            query: (params) => ({
                url: SEND_VERIFICATION__EMAIL_URL,
                method: 'POST',
                body: params
            })
        }),
        setPassword: build.mutation<any, { password: string, token: string }>({

            query: (params) => ({


                url: SET_PASSWORD_URL,
                method: 'POST',
                body: { password: params.password },
                headers: {
                    Authorization: `Bearer ${params.token}`,
                }

            })
        })
    }),
});

export const { useLoginMutation,
    useRegisterMutation,
    useForgetPasswordMutation,
    useVerificationEmailMutation,
    useSendVerificationEmailMutation,
    useSetPasswordMutation
} = authApi;
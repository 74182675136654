import React,{ useState } from "react";
import { Stack, Typography, Divider } from '@mui/material';
import { colors } from 'src/colors';
import { Input, Button } from 'src/components';
import { UserProfile } from 'src/assets/icons';
import { GoogleIcon } from 'src/assets/icons';
import { getGoogleAccountInfo } from 'src/helpers/utils';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { LOGIN_URL, REGISTER_CREATE_ACCOUNT_URL } from 'src/config/urls';
import { isLogin } from 'src/helpers/utils';

const Register: React.FC<{}> = () => {
    const [profile, setProfile] = useState<any>();
    const navigate = useNavigate();

    const register = useGoogleLogin({
        onSuccess: async (response: any) => {
            getGoogleAccountInfo(response, setProfile);
            navigate('/auth');
        }
    });

  return (
    <Stack sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors.green0E2,
        justifyContent: 'center',
        padding: '34px 24px 150px 24px',
        gap: '16px',
        marginY: '25px',
        marginRight: '22px'
    }}>
        <Typography
            fontSize={18}
            fontWeight={700}
            color={colors.white}
            lineHeight={'27px'}
            paddingBottom={'20px'}
        >
            Join today
        </Typography>
        <Button
            variant={"outlined"}
            LabelColor={colors.black}
            borderColor={colors.green}
            backgroundColor={colors.green12}
            sx={{ height: 75 }}
            startIcon={<GoogleIcon />}
            disabled={isLogin() ? true : false}
            onClick={() => register()}
        >
            <Typography
                fontSize={18}
                fontWeight={700}
                lineHeight={'24px'}
                color={colors.white}
            >
                Register with Google
            </Typography>
       </Button>

        <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} paddingY={'20px'}>
            <Divider 
                orientation={"vertical"}
                sx={{
                    height: "1px",
                    width: '46%',
                    borderWidth: "1px",
                    borderColor: colors.green2D
                }} 
            />
            <Typography
                fontSize={14}
                fontWeight={400}
                color={colors.white}
                lineHeight={'18.2px'}
                sx={{ opacity: '50%' }}
            >
                 or
            </Typography>
            <Divider 
            orientation={"vertical"}
            sx={{
                height: "1px",
                width: '46%',
                borderWidth: "1px",
                borderColor: colors.green2D
            }} 
        />
        </Stack>

        <Button
            variant={"outlined"}
            LabelColor={colors.black}
            borderColor={colors.green}
            backgroundColor={colors.green}
            bgHover={colors.green}
            sx={{ height: 64, width: '748px' }}
            onClick={() => navigate(REGISTER_CREATE_ACCOUNT_URL)}
        >
            <Typography
                fontSize={16}
                fontWeight={600}
                lineHeight={'60px'}
                color={colors.white}
                textTransform={'uppercase'}
            >
                Create account
            </Typography>
       </Button>    
        <Stack display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
            <Stack flexDirection={'row'} alignItems={'center'} >
                <Typography
                    fontSize={14}
                    fontWeight={400}
                    lineHeight={'21px'}
                    color={colors.grayB1}
                >
                    By registering, you agree to the
                </Typography>
                <Typography
                    fontSize={14}
                    fontWeight={400}
                    lineHeight={'21px'}
                    color={colors.green}
                >
                    &nbsp;Terms of Service
                </Typography>
            </Stack>
            <Stack flexDirection={'row'} alignItems={'center'} >
                <Typography
                    fontSize={14}
                    fontWeight={400}
                    lineHeight={'21px'}
                    color={colors.grayB1}
                >
                    and
                </Typography>
                <Typography
                    fontSize={14}
                    fontWeight={400}
                    lineHeight={'21px'}
                    color={colors.green}
                >
                    &nbsp;Privacy Policy,
                </Typography>
                <Typography
                    fontSize={14}
                    fontWeight={400}
                    lineHeight={'21px'}
                    color={colors.grayB1}
                >
                    &nbsp;including
                </Typography>
                <Typography
                    fontSize={14}
                    fontWeight={400}
                    lineHeight={'21px'}
                    color={colors.green}
                >
                    &nbsp;Cookie Use.
                </Typography>
            </Stack>
        </Stack>
        <Divider 
            orientation={"vertical"}
            sx={{
                height: "1px",
                borderWidth: "1px",
                borderColor: colors.green16,
                marginY: '25px'
            }} 
        />
        <Stack display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
                <Typography
                    fontSize={18}
                    fontWeight={700}
                    lineHeight={'27px'}
                    color={colors.white}
                >
                    Have an account already?
                </Typography>
                <Typography
                    fontSize={18}
                    fontWeight={700}
                    lineHeight={'27px'}
                    color={colors.green}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => navigate(LOGIN_URL)}
                >
                    &nbsp;Log in
                </Typography>
        </Stack>
    </Stack>
  );
};

export default Register;
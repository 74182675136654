import React, { ReactElement, useState } from "react";
import { Stack, Typography, Grid, Card, Divider, Menu, MenuItem, FormControlLabel } from "@mui/material";
import { Button } from "src/components";
import { colors } from "src/colors";
import {
    BottomArrowIcon,
} from 'src/assets';
import Chart from './chart/Chart';
import Tab from './chart/Tab';
import { AssetsTable } from "./basicTable";
import { ActivityTable } from "./activityTable";
import Charts from "./charts";
import {
    TitleIcon1,
    TitleIcon2,
    TitleIcon3,
    ArrowBottom,
    NftImg1,
    NftImg2,
    NftImg3,
    NftImg4
} from 'src/assets/icons';

//////
//Gh//
//////

export default function PortfolioContent(): ReactElement {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <Stack width={'77.5%'} gap={'25px'}>
            <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} gap={'35px'} marginTop={'-9px'}>
                <Button
                    variant={"contained"}
                    backgroundColor={colors.green}
                    LabelColor={colors.black}
                    borderColor={colors.green}
                    bgHover={colors.green}
                    sx={{ width: '66px', height: 38, borderRadius: '5px' }}
                >
                    <Typography
                        fontSize={16}
                        fontWeight={700}
                        lineHeight={'16px'}
                        color={colors.white}
                        textTransform={'uppercase'}
                    >
                        BACK
                    </Typography>
                </Button>
                <Stack display={'flex'} flexDirection={'row'} gap={'15px'}>
                    <Stack display={'flex'} flexDirection={'row'} gap={'7px'}>
                        <TitleIcon1 />
                        <Typography
                            fontSize={16}
                            fontWeight={700}
                            lineHeight={'24px'}
                            color={colors.white}
                        >
                            Ethereum
                        </Typography>
                    </Stack>
                    <Stack display={'flex'} flexDirection={'row'} gap={'7px'}>
                        <TitleIcon2 />
                        <Typography
                            fontSize={16}
                            fontWeight={700}
                            lineHeight={'24px'}
                            color={colors.green}
                        >
                            BNB Chain
                        </Typography>
                    </Stack>
                    <Stack display={'flex'} flexDirection={'row'} gap={'7px'}>
                        <TitleIcon3 />
                        <Typography
                            fontSize={16}
                            fontWeight={700}
                            lineHeight={'24px'}
                            color={colors.green}
                        >
                            Polygon
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
            <Typography
                fontSize={32}
                fontWeight={700}
                lineHeight={'42px'}
                color={colors.white}
            >
                Portfolio
            </Typography>
            <Stack>
                <Button
                    id="network-button"
                    aria-controls={open ? 'network-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    variant='contained'
                    endIcon={<BottomArrowIcon color={colors.green} width="10.57px" height="6.49px" />}
                    backgroundColor={colors.green0E2}
                    borderColor={colors.green0E2}
                    sx={{ width: '168px', borderRadius: '8px' }}
                >
                    <Typography
                        fontSize={18}
                        fontWeight={500}
                        lineHeight={'26px'}
                        color={colors.white}
                    >
                        All Networks
                    </Typography>
                </Button>
                {/* <Menu
                id="network-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                sx={{
                    '& .MuiPaper-root': {
                    background: "#123142",
                    borderRadius: 0,
                    }
                }}
            >
                <MenuItem sx={{ color: 'white' }} onClick={handleClose}>
                    <Typography 
                        fontSize={14} 
                        fontWeight={700} 
                        lineHeight={'20px'}
                        color={colors.white} 
                    >
                        1
                </Typography>
                </MenuItem>
                <MenuItem sx={{ color: 'white' }} onClick={handleClose}>
                    <Typography 
                        fontSize={14} 
                        fontWeight={700} 
                        lineHeight={'20px'}
                        color={colors.grayB1} 
                    >
                        2
                    </Typography>
                </MenuItem>
                <MenuItem sx={{ color: 'white' }} onClick={handleClose}>
                    <Typography 
                        fontSize={14} 
                        fontWeight={700} 
                        lineHeight={'20px'}
                        color={colors.grayB1} 
                    >
                        3
                    </Typography>
                </MenuItem>
            </Menu> */}
            </Stack>
            <Stack display={'flex'} flexDirection={'column'} sx={{ backgroundColor: colors.green0E2, borderRadius: '24px' }} >
                <Stack
                    sx={{
                        flexDirection: 'row',
                        borderRadius: '5px'
                    }}>
                    {worths.slice(0, 3).map((item: any) => (
                        <Stack
                            key={item.id}
                            sx={{
                                flexDirection: 'column',
                                gap: '10px',
                                padding: '24px',
                                borderRadius: item.id === 1 ? '5px' : 0,
                                width: '304.14px',
                                backgroundColor: item.id === 1 ? colors.green0F : 0
                            }}
                        >
                            <Typography
                                fontSize={12}
                                fontWeight={400}
                                lineHeight={'15.96px'}
                                color={colors.gray8A}
                            >
                                {item.title}
                            </Typography>
                            <Stack flexDirection={'column'}>
                                <Stack flexDirection={'row'} alignItems={'center'} gap={'5px'}>
                                    <Typography
                                        fontSize={item.id === 1 ? 29 : 16}
                                        fontWeight={item.id === 1 ? 700 : 400}
                                        lineHeight={item.id === 1 ? '38px' : '20.96px'}
                                        color={item.id === 1 ? colors.grayE5 : colors.white}
                                    >
                                        {item.highestPrice}
                                    </Typography>
                                    {item.id === 1 && <ArrowBottom />}
                                </Stack>
                                <Typography
                                    fontSize={18}
                                    fontWeight={500}
                                    lineHeight={'26px'}
                                    color={colors.redFA}
                                >
                                    {item.lowestPrice}
                                </Typography>
                            </Stack>
                        </Stack>
                    ))}
                </Stack>
                <Stack flexDirection={'column'} padding={'16px 16px 0 16px'}>
                    <Card
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            borderRadius: 0,
                            backgroundColor: colors.green0E2,
                            paddingTop: '20px',
                            paddingBottom: '70px',
                            boxShadow: 'none'
                        }}
                    >
                        <Stack
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Stack
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <Typography
                                    fontSize={21}
                                    fontWeight={700}
                                    lineHeight={'31.5px'}
                                    color={colors.white}
                                >
                                    Tokens worth
                                </Typography>
                                <Typography
                                    fontSize={14}
                                    fontWeight={500}
                                    lineHeight={'19px'}
                                    color={colors.white}
                                >
                                    $3.68
                                </Typography>
                            </Stack>
                            <Stack>
                                <Tab />
                            </Stack>
                        </Stack>
                        <Stack sx={{ height: '180px', width: '100%', paddingLeft: '10px' }}>
                            <Chart />
                        </Stack>
                    </Card>
                    <Stack flexDirection={'row'} alignItems={'center'} gap={'10px'} paddingBottom={'15px'}>
                        <Typography
                            fontSize={26}
                            fontWeight={500}
                            lineHeight={'36px'}
                            color={colors.grayCC}
                        >
                            Assets
                        </Typography>
                        <Typography
                            fontSize={23}
                            fontWeight={400}
                            lineHeight={'32px'}
                            color={colors.grayCC}
                        >
                            £8,574,698,965.95
                        </Typography>
                    </Stack>
                    <AssetsTable />
                </Stack>
            </Stack>

            <Stack
                display={'flex'}
                flexDirection={'column'}
                gap={'40px'}
                sx={{
                    backgroundColor: colors.green0E2,
                    borderRadius: '24px',
                    padding: '16px 19.95px 16px 20px'
                }}
            >
                <Stack flexDirection={'row'} justifyContent={'space-between'} >
                    <Typography
                        fontSize={26}
                        fontWeight={500}
                        lineHeight={'36px'}
                        color={colors.grayCC}
                    >
                        Charts
                    </Typography>
                    <Button
                        variant={"outlined"}
                        LabelColor={colors.white}
                        backgroundColor={colors.green0E2}
                        borderColor={colors.default}
                        bgHover={colors.green0E2}
                        sx={{ borderRadius: '12px', height: '32px' }}
                    >
                        <Typography
                            fontSize={14}
                            fontWeight={500}
                            color={colors.grayCC}
                            lineHeight={'20px'}
                        >
                            See More Charts
                        </Typography>
                    </Button>
                </Stack>

                <Charts />

            </Stack>

            <Stack
                display={'flex'}
                flexDirection={'column'}
                gap={'10px'}
                sx={{ backgroundColor: colors.green0E2, borderRadius: '24px', padding: '16px 19.95px 16px 20px' }} >
                <Stack flexDirection={'row'} justifyContent={'space-between'} >
                    <Stack flexDirection={'row'} gap={'8px'} alignItems={'center'}>
                        <Typography
                            fontSize={26}
                            fontWeight={500}
                            lineHeight={'36px'}
                            color={colors.grayCC}
                        >
                            NFTs
                        </Typography>
                        <Typography
                            fontSize={23}
                            fontWeight={400}
                            lineHeight={'32px'}
                            color={colors.grayCC}
                        >
                            £8.46
                        </Typography>
                    </Stack>
                    <Button
                        variant={"outlined"}
                        LabelColor={colors.white}
                        backgroundColor={colors.green0E2}
                        borderColor={colors.default}
                        bgHover={colors.green0E2}
                        sx={{ borderRadius: '12px', height: '32px' }}
                    >
                        <Typography
                            fontSize={14}
                            fontWeight={500}
                            color={colors.grayCC}
                            lineHeight={'20px'}
                        >
                            See More NFTs
                        </Typography>
                    </Button>
                </Stack>
                <Stack>
                    <Grid container spacing={2} columns={16}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        {nfts.slice(0, 4).map((item: any) => (
                            <Grid item xs={4} key={item.id}>
                                <Stack sx={{
                                    backgroundColor: colors.black04,
                                    borderRadius: '16px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: '12px 12px 20px 12px',
                                    justifyContent: 'center',
                                    opacity: '80%',
                                    gap: '8px'
                                }}>
                                    {item.img}
                                    <Stack gap={'3px'}>
                                        <Typography
                                            fontSize={16}
                                            fontWeight={400}
                                            lineHeight={'23.11px'}
                                            color={colors.green1B}
                                        >
                                            {item.title}
                                        </Typography>
                                        <Stack display={'flex'} flexDirection={'row'} gap={'5px'}>
                                            <TitleIcon1 />
                                            <Typography
                                                fontSize={16}
                                                fontWeight={700}
                                                lineHeight={'24px'}
                                                color={colors.white}
                                            >
                                                {item.amount}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Typography
                                        fontSize={16}
                                        fontWeight={400}
                                        lineHeight={'24px'}
                                        color={colors.green1B}
                                    >
                                        {`£${item.price}`}
                                    </Typography>

                                </Stack>
                            </Grid>
                        ))}
                    </Grid>
                </Stack>

            </Stack>

            <Stack display={'flex'} flexDirection={'column'} sx={{ backgroundColor: colors.green0E2, borderRadius: '24px', padding: '16px 19.95px 16px 20px' }} >
                <Stack flexDirection={'row'} justifyContent={'space-between'} >
                    <Typography
                        fontSize={26}
                        fontWeight={500}
                        lineHeight={'36px'}
                        color={colors.grayCC}
                    >
                        Activity
                    </Typography>
                    <Button
                        variant={"outlined"}
                        LabelColor={colors.white}
                        backgroundColor={colors.green0E2}
                        borderColor={colors.default}
                        bgHover={colors.green0E2}
                        sx={{ borderRadius: '12px', height: '32px' }}
                    >
                        <Typography
                            fontSize={14}
                            fontWeight={500}
                            color={colors.grayCC}
                            lineHeight={'20px'}
                        >
                            See More Activity
                        </Typography>
                    </Button>
                </Stack>
                <ActivityTable />

            </Stack>

            {/* <Grid container spacing={2}
            sx={{ 
                display: 'flex',
                flexDirection: 'row',
                marginTop: 0
            }}
        >
            <Grid item xs={8}> 
       
                <Grid container spacing={2}
                    sx={{ 
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >

                    <Grid item> 
                        <Card sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            backgroundColor: colors.green0E2, 
                            borderRadius: '5px',
                            padding: '15px'
                        }}>
                            <Stack sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}>
                                <Stack 
                                    sx={{ 
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <Typography
                                        fontSize={21}
                                        fontWeight={700}
                                        lineHeight={'31.5px'}
                                        color={colors.white}
                                    >
                                        Tokens worth
                                    </Typography>
                                    <Typography
                                        fontSize={14}
                                        fontWeight={500}
                                        lineHeight={'19px'}
                                        color={colors.white}
                                    >
                                        $3.68
                                    </Typography>
                                </Stack>
                                <Stack>
                                    <Tabs
                                            sx={{
                                            '& .MuiTab-root': {
                                                maxWidth: '41.',
                                                height: '36px',
                                                minHeight: 0,
                                                minWidth: 0,
                                                fontWeight: 700,
                                                color: colors.grayB1,
                                                padding: '7.5px 8px 8.5px 8px'
                                                
                                            },
                                            '& .MuiTab-root.Mui-selected': {
                                                color: colors.white,
                                                fontSize: '14px',
                                                fontWeight: 700,
                                                backgroundColor: colors.gray44,
                                                borderRadius: '8px',
                                                padding: '7.5px 8px 8.5px 8px'
                                            },
                                            '& .MuiTabs-flexContainer': {
                                                gap: '7px'
                                            },
                                            '& .MuiTabs-indicator': {
                                                height: 0
                                            }
                                            }}
                                            value={value} onChange={handleChange} aria-label='basic tabs example'>
                                            <Tab label='24h' {...a11yProps(0)} />
                                            <Tab label='7d' {...a11yProps(1)} />
                                            <Tab label='30d' {...a11yProps(2)} />
                                            <Tab label='6d' {...a11yProps(3)} />
                                    </Tabs>
                                </Stack> 
                            </Stack> 
                            <Stack sx={{ height: '180px', width: '100%' }}>
                                <Chart />
                            </Stack>               
                        </Card> 
                    </Grid>
                    <Grid item> 
                        <Card sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            backgroundColor: colors.green0E2, 
                            borderRadius: '5px',
                            padding: '15px'
                        }}>

                            <Grid container spacing={2} columns={16}>
                                <Grid item xs={8}>
                                    <Card sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        backgroundColor: colors.green0F,
                                        padding: '15px',
                                        gap: '5px',
                                        height: '100%'
                                    }}>
                                        <Typography
                                            fontSize={12}
                                            fontWeight={400}
                                            lineHeight={'15.96px'}
                                            color={colors.gray8A}
                                        >
                                            Dominant token
                                        </Typography>
                                        <Stack sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between'
                                        }}>
                                            <Stack flexDirection={'row'} gap={'10px'} >
                                                <DominantImg />
                                                <Typography
                                                    fontSize={21}
                                                    fontWeight={700}
                                                    lineHeight={'31.5px'}
                                                    color={colors.white}
                                                    textTransform={'uppercase'}
                                                >
                                                    kdoe
                                                </Typography>
                                            </Stack>
                                            <Typography
                                                fontSize={14}
                                                fontWeight={500}
                                                lineHeight={'21px'}
                                                color={colors.white}
                                            >
                                                $1.86 (50.48%)
                                            </Typography>
                                        </Stack>
                                    </Card>
                                </Grid>
                                <Grid item xs={8}>
                                    <Card sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        backgroundColor: colors.green0F,
                                        padding: '15px',
                                        gap: '5px',
                                        height: '100%'
                                    }}>
                                        <Typography
                                            fontSize={12}
                                            fontWeight={400}
                                            lineHeight={'15.96px'}
                                            color={colors.gray8A}
                                        >
                                            Tokens
                                        </Typography>
                                        <Typography
                                            fontSize={21}
                                            fontWeight={700}
                                            lineHeight={'27.93px'}
                                            color={colors.white}
                                        >
                                            3
                                        </Typography>
                                    </Card>
                                </Grid>
                            </Grid>
                        
                            <Stack sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                paddingY: '8px'
                            }}>

                                <Stack flexDirection={'row'} gap={'10px'} alignItems={'center'}>
                                    <img src={dominantImg} alt={'dominant-img'} width={24} height={24} />
                                    <Stack flexDirection={'column'}>
                                        <Typography
                                            fontSize={14}
                                            fontWeight={700}
                                            lineHeight={'21px'}
                                            color={colors.white}
                                            textTransform={'uppercase'}
                                        >
                                            Kudoe
                                        </Typography>
                                        <Typography
                                            fontSize={14}
                                            fontWeight={400}
                                            lineHeight={'18.62px'}
                                            color={colors.gray8A}
                                            textTransform={'uppercase'}
                                        >
                                            300 kdoe • $0.01
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Stack flexDirection={'row'} gap={'10px'} alignItems={'center'}>   
                                    <Stack flexDirection={'column'} alignItems={'end'}>
                                        <Typography
                                            fontSize={14}
                                            fontWeight={500}
                                            lineHeight={'19px'}
                                            color={colors.white}
                                        >
                                            $1.86
                                        </Typography>
                                        <Typography
                                            fontSize={14}
                                            fontWeight={400}
                                            lineHeight={'18.62px'}
                                            color={colors.redFA}
                                        >
                                            -1.86% ($0.0352)
                                        </Typography>
                                    </Stack>
                                    <Sign />
                                </Stack>
                            </Stack>
                            <Divider 
                                orientation={"vertical"}
                                sx={{
                                    height: "1px",
                                    borderWidth: "1px",
                                    borderColor: colors.blue05
                                }} 
                            />
                            <Stack sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                paddingY: '8px'
                            }}>

                                <Stack flexDirection={'row'} gap={'10px'} alignItems={'center'}>
                                   <Ethereum />
                                    <Stack flexDirection={'column'}>
                                        <Typography
                                            fontSize={14}
                                            fontWeight={700}
                                            lineHeight={'21px'}
                                            color={colors.white}
                                            textTransform={'uppercase'}
                                        >
                                            ethereum
                                        </Typography>
                                        <Typography
                                            fontSize={14}
                                            fontWeight={400}
                                            lineHeight={'18.62px'}
                                            color={colors.gray8A}
                                            textTransform={'uppercase'}
                                        >
                                            0.00114 eth • $1,590.19
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Stack flexDirection={'row'} gap={'10px'} alignItems={'center'}>   
                                    <Stack flexDirection={'column'} alignItems={'end'}>
                                        <Typography
                                            fontSize={14}
                                            fontWeight={500}
                                            lineHeight={'19px'}
                                            color={colors.white}
                                        >
                                            $1.83
                                        </Typography>
                                        <Typography
                                            fontSize={14}
                                            fontWeight={400}
                                            lineHeight={'18.62px'}
                                            color={colors.redFA}
                                        >
                                            -1.87% ($0.0348)
                                        </Typography>
                                    </Stack>
                                    <img src={signImg} alt={'signImg'} width={23} height={23} />
                                </Stack>
                            </Stack>
                            <Divider 
                                orientation={"vertical"}
                                sx={{
                                    height: "1px",
                                    borderWidth: "1px",
                                    borderColor: colors.blue05
                                }} 
                            />
                            <Stack sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                paddingY: '8px'
                            }}>

                                <Stack flexDirection={'row'} gap={'10px'} alignItems={'center'}>
                                    <Dogs/>
                                    <Stack flexDirection={'column'}>
                                        <Typography
                                            fontSize={14}
                                            fontWeight={700}
                                            lineHeight={'21px'}
                                            color={colors.white}
                                            textTransform={'uppercase'}
                                        >
                                            Dogs Of Elon
                                        </Typography>
                                        <Typography
                                            fontSize={14}
                                            fontWeight={400}
                                            lineHeight={'18.62px'}
                                            color={colors.gray8A}
                                            textTransform={'uppercase'}
                                        >
                                            300 doe
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Stack flexDirection={'row'} gap={'10px'} alignItems={'center'}>   
                                    <Stack flexDirection={'column'} alignItems={'end'}>
                                        <Typography
                                            fontSize={14}
                                            fontWeight={500}
                                            lineHeight={'19px'}
                                            color={colors.white}
                                        >
                                            -
                                        </Typography>
                                        <Typography
                                            fontSize={14}
                                            fontWeight={400}
                                            lineHeight={'18.62px'}
                                            color={colors.white}
                                        >
                                            -
                                        </Typography>
                                    </Stack>
                                    <img src={signImg} alt={'signImg'} width={23} height={23} />
                                </Stack>
                            </Stack>
                            <Stack paddingTop={'25px'}>
                                <Button
                                    variant={"outlined"}
                                    LabelColor={colors.white}
                                    backgroundColor={colors.green}
                                    borderColor={colors.green}
                                    bgHover={colors.green}
                                    sx={{ borderRadius: '8px' }}
                                >
                                    <Typography 
                                        fontSize={16}
                                        fontWeight={700}
                                        color={colors.white}
                                        lineHeight={'16px'}
                                    >
                                        Open tokens
                                    </Typography>
                                </Button>
                            </Stack>
                        </Card>
                    </Grid>

                </Grid>

            </Grid>
            <Grid item xs={4}>
                <Grid container spacing={2.5}
                    sx={{ 
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Grid item>     
                        <Card sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            backgroundColor: colors.green0E2, 
                            borderRadius: '5px',
                            padding: '15px 15px 40px 15px',
                            gap: '28px'
                        }}>
                            <Typography
                                    fontSize={21}
                                    fontWeight={700}
                                    lineHeight={'27.93px'}
                                    color={colors.white}
                                >
                                NFTs
                            </Typography>
                            <Stack 
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: '15px'
                                }}>
                                    <Empty />
                                    <Typography
                                        fontSize={16}
                                        fontWeight={400}
                                        lineHeight={'20.96px'}
                                        color={colors.white}
                                    >
                                        No NFTs detected in this wallet
                                    </Typography>
                            </Stack>
                            <Divider 
                                orientation={"vertical"}
                                sx={{
                                    height: "1px",
                                    borderWidth: "1px",
                                    borderColor: colors.blue06,
                                    marginTop: '35px'
                                }} 
                            />
                            <Stack justifyContent={'center'} alignItems={'center'}>
                                <Typography
                                    fontSize={14}
                                    fontWeight={700}
                                    color={colors.green}
                                    lineHeight={"20px"}
                                    >
                                    Discover NFTs
                                </Typography>
                            </Stack>
                        </Card>  
                    </Grid>
                    <Grid item>     
                        <Card sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            backgroundColor: colors.green0E2, 
                            borderRadius: '5px',
                            padding: '15px 15px 117px 15px',
                            gap: '28px'
                        }}>
                            <Typography
                                    fontSize={21}
                                    fontWeight={700}
                                    lineHeight={'27.93px'}
                                    color={colors.white}
                                >
                                POAPs
                            </Typography>
                            <Stack 
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: '15px'
                                }}>
                                    <img src={empty} alt={'empty'} width={72.69} height={65.32} />
                                    <Typography
                                        fontSize={16}
                                        fontWeight={400}
                                        lineHeight={'20.96px'}
                                        color={colors.white}
                                    >
                                        No POAPs found
                                    </Typography>
                            </Stack>
                        </Card>  
                    </Grid>
                </Grid>
            </Grid>
        </Grid> */}

        </Stack>
    );
}

const worths = [
    {
        id: 1,
        title: "Total worth",
        highestPrice: "£8,574,698,965.95",
        lowestPrice: "-£1,727,578,571.66 (-19.67%)",
    },
    {
        id: 2,
        title: "NFTs worth",
        highestPrice: "£8,574,698,965.95",
        lowestPrice: "",
    },
    {
        id: 3,
        title: "NFTs worth",
        highestPrice: "£8.46",
        lowestPrice: "",
    }
];

const nfts = [
    {
        id: 1,
        img: <NftImg1 />,
        title: 'Stay Decentralized',
        amount: '0.01',
        price: '13.43'
    },
    {
        id: 2,
        img: <NftImg2 />,
        title: 'Rektokens vs Moonies',
        amount: 0,
        price: 0
    },
    {
        id: 3,
        img: <NftImg3 />,
        title: 'OpenSea Collection',
        amount: 0,
        price: 0
    },
    {
        id: 4,
        img: <NftImg4 />,
        title: 'Greeting Cards 2023 Limite…',
        amount: 0,
        price: 0
    }
];

import { Paper } from "@mui/material";
// eslint-disable-next-line
import Table from './Table';

export const ActivityTable = () => {

  return (
      <Paper sx={{ borderRadius: "0px", backgroundColor: "transparent", paddingRight: '16px', boxShadow: 'none', }}>
        <Table data={data} />
      </Paper>
  );
};

const data = [
  {
    id: 1,
    avatar: "",
    type: "Received",
    amount: '0.00000001',
    price: '0.000007969',
    portfolio: 'Base'
  },
  {
    id: 2,
    avatar: "",
    type: "Received",
    amount: '0',
    price: '0.000000050782',
    portfolio: 'Arbitrum'
  },
  {
    id: 3,
    avatar: "",
    type: "Received",
    amount: '0',
    price: '0.00000005615',
    portfolio: 'zkEVM Polygon'
  },
  {
    id: 4,
    avatar: "",
    type: "Received",
    amount: '0',
    price: '0.000000047946',
    portfolio: 'Base'
  },
  {
    id: 5,
    avatar: "",
    type: "Received",
    amount: '0',
    price: '0.000000062175',
    portfolio: 'Base'
  },
  {
    id: 6,
    avatar: "",
    type: "Received",
    amount: '0',
    price: '0.0000007179',
    portfolio: 'Arbitrum Nova'
  },
  {
    id: 7,
    avatar: "",
    type: "Received",
    amount: 0,
    price: 0,
    portfolio: 'Ethereum'
  },
  {
    id: 8,
    avatar: "",
    type: "Received",
    amount: '0',
    price: '0.000000020394',
    portfolio: 'Arbitrum'
  },
  {
    id: 9,
    avatar: "",
    type: "Received",
    amount: 0,
    price: '0.000000021043',
    portfolio: 'Arbitrum'
  },
  {
    id: 10,
    avatar: "",
    type: "Received",
    amount: 0,
    price: '0.000000019874',
    portfolio: 'Arbitrum'
  }
];

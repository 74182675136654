import React, { FC, useState } from 'react';
import {
    SxProps,
    Typography,
    Button,
    Menu,
    Stack,
    MenuItem
} from '@mui/material';
import { colors } from '../../colors';
import { makeStyles } from "@mui/styles";
import { WhiteArrowDownIcon } from 'src/assets/icons';
import { ExitingDialog } from '../../components/dialog/exitingdialog/ExcitingDialog';
import { Navigate, useNavigate } from 'react-router-dom';
export interface MenuButtonProps {
    items?: any;
    onClick?: (event: any) => void;
    sx?: SxProps
}

const useStyles: any = makeStyles(() => ({
    popOverRoot: {
        pointerEvents: "none"
    }
}));

export const MenuButton: FC<MenuButtonProps> = ({ items, onClick, sx }) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    let currentlyHovering = false;
    const [openDialog, setOpenDialog] = useState(false);

    const styles = useStyles();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleHover = () => {
        currentlyHovering = true;
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseHover = () => {
        currentlyHovering = false;
        setTimeout(() => {
            if (!currentlyHovering) {
                handleClose();
            }
        }, 50);
    };

    const handleClickItem = () => {
        setOpenDialog(true);
        handleClose();
    }

    return (
        <Stack direction="row" spacing={2}>
            <Button
                sx={{
                    gap: '10px',
                    textTransform: 'none',
                    cursor: 'pointer',
                    ...sx
                }}
                aria-owns={open ? 'simple-menu' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                onMouseOver={handleClick}
                onMouseLeave={handleCloseHover}
            >
                <Typography
                    fontSize={14}
                    fontWeight={600}
                    color={colors.white}
                    onClick={(e: any) => {
                        if (e.target.innerText === 'Challenges') {
                            handleClickItem();
                        }
                    }}
                >
                    {items.title}
                </Typography>
                {items.list.length > 0 && <WhiteArrowDownIcon />}
            </Button>
            {items.list.length > 0 &&
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    MenuListProps={{
                        onMouseEnter: handleHover,
                        onMouseLeave: handleCloseHover,
                        style: { pointerEvents: "auto" }
                    }}
                    PopoverClasses={{
                        root: styles.popOverRoot
                    }}
                    anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                    sx={{
                        '& .MuiPaper-root': {
                            backgroundColor: colors.default,
                            border: `1px solid ${colors.green}`,
                            borderRadius: '0'
                        }
                    }}
                >
                    {items.list.map((item: any) => {
                        const _url = String(item.url);
                        if (_url === 'dialog') {
                            return <MenuItem
                                key={item.id}
                                sx={{
                                    '& .MuiMenuItem-root': {
                                        color: colors.white
                                    }
                                }}
                                onClick={handleClickItem}
                            >
                                <Typography
                                    fontSize={16}
                                    fontWeight={600}
                                    color={colors.white}
                                    lineHeight={'34px'}
                                >
                                    {item.text}
                                </Typography>
                            </MenuItem>
                        } else {
                            return <MenuItem
                                key={item.id}
                                component={"a"}
                                // href={`${item.url}`}
                                sx={{
                                    '& .MuiMenuItem-root': {
                                        color: colors.white
                                    }
                                }}
                                onClick={() => {
                                    navigate(item.url);
                                    handleClose();
                                }}
                            >
                                <Typography
                                    fontSize={16}
                                    fontWeight={600}
                                    color={colors.white}
                                    lineHeight={'34px'}
                                >
                                    {item.text}
                                </Typography>
                            </MenuItem>
                        }
                    })}
                </Menu>
            }
            <ExitingDialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
            />
        </Stack>
    );
};
import * as Yup from 'yup';

export interface initialValuesProps {
    code: string,
    // code2: string,
    // code3: string,
    // code4: string,
    // code5: string,
}

export const initialValues = {
    code: '',
    // code2: '',
    // code3: '',
    // code4: '',
    // code5: '',
};

export const validationSchema = Yup.object().shape({
    code: Yup.string().required('required')
        .min(5),
    // code2: Yup.string().required('required'),
    // code3: Yup.string().required('required'),
    // code4: Yup.string().required('required'),
    // code5: Yup.string().required('required'),
});
import { FC, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button } from 'src/components';

import { colors } from 'src/colors';
import { Stack, CircularProgress, Box, Typography } from '@mui/material';
import { useUploadProfileMutation } from 'src/api/user';
import { useAppSelector } from 'src/hooks/ReduxHooks';

export const LogoUpload: FC<IProps> = ({
    maxSize = 2000000,
    disabled,
    width,
    height,
    imageUrl,
    display,
    onFileUrl,
    onError
}) => {

    const [loading, setLoading] = useState<any>(false);
    const [selectedFile, setSelectedFile] = useState<any>(undefined);
    const [uploadProfile] = useUploadProfileMutation();
    const user = useAppSelector(s => s.mainSlice.user);

    const onDrop = useCallback((_acceptedFiles: any) => {
        if (_acceptedFiles?.length > 0) {
            _acceptedFiles.map((_file: any) => {

                if (_file?.size <= maxSize) {
                    const formData = new FormData();

                    formData.append('photo', _file);
                    uploadProfile({ photo: formData, token: user.accessToken ?? '' })
                        .unwrap()
                        .then((res: any) => {
                            onFileUrl(res.data.photo);

                        });
                    onFileUrl(_file);
                    // setSelectedFile(_file);
                    // console.log(_file);
                } else {
                    onError!('The maximum photo size is 4mb');
                }

            });
        }
    }, []);

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        disabled: disabled,
        accept: { 'image/png': [], 'image/jpg': [], 'image/svg': [], 'image/jpeg': [] },
        onDrop,
    });

    return (
        <Stack direction={'row'} spacing={'21px'} alignItems={'center'}>
            <Box
                {...getRootProps({ className: 'dropzone' })}
                sx={acceptedFiles.length > 0
                    ?
                    {
                        width: "144px",
                        height: "144px",
                        display: 'flex',
                        backgroundImage: `url(${URL.createObjectURL(acceptedFiles[0])})`,
                        backgroundPosition: '50% 50%',
                        backgroundSize: 'cover'
                    }
                    : {

                        width: "144px",
                        height: "144px",
                        display: 'flex',
                        flexShrink: 0,
                        cursor: 'pointer',
                        backgroundColor: colors.green,

                    }
                }
                alignItems={'center'}
                justifyContent={'center'}

            >
                <input {...getInputProps()} />
                {loading ? <CircularProgress
                    style={{
                        width: '24px',
                        height: '24px',
                        color: colors.green0E22
                    }} />
                    :
                    acceptedFiles.length > 0
                        ? null
                        : <Stack
                            direction={'row'}
                            sx={{
                                justifyContent: "center",
                                alignItems: "center",
                            }} >
                            <Typography
                                sx={{
                                    color: colors.white,
                                    fontFamily: "Chakra Petch",
                                    fontSize: "18px",
                                    fontStyle: "normal",
                                    fontWeight: 700,
                                    lineHeight: "27px"
                                }}
                            >Logo</Typography>
                        </Stack>
                }
            </Box>
            <Stack justifyContent={'center'} spacing={'12px   '}>
                <Button
                    variant={"outlined"}
                    LabelColor={colors.black}
                    borderColor={colors.green}
                    bgHover={colors.green}
                    sx={{ height: 52, width: '199px' }}
                    {...getRootProps({ className: 'dropzone' })}
                >
                    <Typography
                        fontSize={16}
                        fontWeight={700}
                        lineHeight={'16px'}
                        color={colors.white}
                    >
                        Upload logo
                    </Typography>
                </Button>
                <Typography
                    sx={{
                        textAlign: "center",
                        fontFamily: "Chakra Petch",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "16px",
                        letterSpacing: "0.6px"
                    }}
                    color={colors.white}
                >
                    Pick an image up to 4 MB.
                </Typography>
            </Stack>
        </Stack >
    );
};
export interface IProps {
    width?: string;
    height?: string;
    disabled?: boolean;
    maxSize?: string | number;
    imageUrl?: any;
    display?: string;
    onFileUrl: (url: any) => void;
    onError?: (txt: string) => void;
}
export default LogoUpload;
const dataCookies = [
    {
        id: 1,
        title : `**1. What Are Cookies?**`,
        content1: `Cookies are small text files that are placed on your device (computer, smartphone, tablet, etc.) when you visit a website. They are widely used to make websites work more efficiently and to provide information to the website owner.`,
        content2: ``,
        content3: ``
    },
    {
        id: 2,
        title : `**2. How We Use Cookies:**`,
        content1: `**2.1. Essential Cookies:** These cookies are necessary for the basic functionality of our website. They enable you to navigate the website and use its features, such as accessing secure areas and making transactions. Without these cookies, the website may not function properly.`,
        content2: `**2.2. Performance and Analytics Cookies:** We use these cookies to collect information about how visitors use our website. They help us understand which pages are popular, how visitors move around the site, and if they encounter any errors. This information is used to improve the website's performance and user experience.`,
        content3: `**2.3. Functional Cookies:** Functional cookies enable our website to remember choices you make, such as language preferences, and provide enhanced features tailored to your needs.`,
        content4: `**2.4. Advertising and Targeting Cookies:** These cookies may be used to deliver advertisements that are relevant to your interests. They are also used to limit the number of times you see an ad and measure the effectiveness of ad campaigns.`
    },
    {
        id: 3,
        title : `**3. Your Cookie Choices:**`,
        content1: `**3.1. Consent:** By using our website, you consent to the use of cookies in accordance with this Cookies Policy. You can withdraw your consent at any time by changing your cookie preferences through your browser settings.`,
        content2: `**3.2. Browser Settings:** You can control and manage cookies through your web browser settings. Most browsers allow you to block or delete cookies. However, please note that blocking cookies may affect the functionality of our website.`,
        content3: `**3.3. Third-Party Cookies:** Some cookies on our website may be placed by third-party services, such as analytics providers or advertisers. These third parties may have their own cookies policies, and we recommend reviewing their policies for more information about how they use cookies.`,
        content4: ``
    },
    {
        id: 4,
        title : `**4. Changes to this Cookies Policy:**`,
        content1: `We may update this Cookies Policy from time to time to reflect changes in our use of cookies or for other operational, legal, or regulatory reasons. Any changes will be posted on this page, and the date of the latest revision will be indicated at the top.`,
        content2: ``,
        content3: ``,
        content4: ``
    },
    {
        id: 5,
        title : `**5. Contact Us:**`,
        content1: ``,
        content2: ``,
        content3: ``,
        content4: ``
    },
    
]

export default dataCookies;

import React, { useEffect, useState } from "react";
import { Stack, Typography, Divider } from '@mui/material';
import { colors } from 'src/colors';
import { Input, Button } from 'src/components';
import { useFormik, Form, FormikProvider } from 'formik';
import { initialValues, validationSchema } from 'src/formValidations/login';
import { LeftArrowIcon, TikIcon } from 'src/assets/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LOGIN_FORGOT_PASSWORD_URL, REGISTER_URL, _REFRESH_TOKEN, _TOKEN_NAME } from 'src/config/urls';
import { useLoginMutation } from "src/api/auth";
import { toast } from "react-toastify";
import { ILogin } from "src/models/authApi";
const CreatePassword: React.FC<{}> = () => {
    const navigate = useNavigate();
    const [params, setParams] = useSearchParams();
    const [login] = useLoginMutation();
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        // enableReinitialize: true,
        onSubmit: (values: any) => {
            console.log('values', values);
            login(values)
                .unwrap()
                .then((res: ILogin.Response) => {

                    console.log(res);
                    localStorage.setItem(_TOKEN_NAME, res.data.accessToken);
                    localStorage.setItem(_REFRESH_TOKEN, res.data.refreshToken);
                    navigate('/');

                })
                .catch((err) => {

                    console.log(err);
                });
        },
    });


    const { errors, touched, handleSubmit, getFieldProps } = formik;
    useEffect(() => {
        formik.setValues((prev: any) => { return { ...prev, 'email': params.get('email') }; });

    }, [params]);
    return (


        <form onSubmit={formik.handleSubmit} >
            <Stack sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: colors.green0E2,
                justifyContent: 'center',
                padding: '4px 24px 24px 24px',
                gap: '16px',
                marginY: '25px',
                marginRight: '22px'
            }}>
                <Stack flexDirection={'row'} alignItems={'center'} gap={'12px'} marginBottom={'20px'}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => navigate(-1)}
                >
                    <LeftArrowIcon />
                    <Typography
                        fontSize={16}
                        fontWeight={600}
                        lineHeight={'60px'}
                        color={colors.white}
                    >
                        Back
                    </Typography>
                </Stack>
                <Typography
                    fontSize={18}
                    fontWeight={700}
                    color={colors.white}
                    lineHeight={'27px'}
                >
                    Enter your password
                </Typography>
                <Input
                    label="Email Address"
                    id="email"
                    type="email"
                    disabled={true}
                    {...getFieldProps('email')}


                />
                {errors.email && touched.email && <div>{errors.email.toString()}</div>}
                <Input
                    label="Password"
                    id="password"
                    type="password"
                    {...getFieldProps('password')}
                />
                {errors.password && touched.password && <div>{errors.password.toString()}</div>}
                <Stack spacing={'6px'}>
                    <Typography fontSize={14} fontWeight={700} color='white'>
                        Your password must:
                    </Typography>
                    <Stack direction='row' spacing={'6px'}>
                        <TikIcon />
                        <Typography fontSize={14} fontWeight={500} color='white'>
                            Be at least 8 characters long
                        </Typography>
                    </Stack>

                    <Stack direction='row' spacing={'6px'}>
                        <TikIcon />
                        <Typography fontSize={14} fontWeight={500} color='white'>
                            Contain at least one special characters
                        </Typography>
                    </Stack>
                    <Stack direction='row' spacing={'6px'}>
                        <TikIcon />
                        <Typography fontSize={14} fontWeight={500} color='white'>
                            Contain at least one number
                        </Typography>
                    </Stack>

                </Stack>
                <Divider
                    orientation={"vertical"}
                    sx={{
                        height: "1px",
                        borderWidth: "1px",
                        borderColor: colors.green16
                    }}
                />
                <Stack flexDirection={'row'} gap={'16px'} justifyContent={'flex-end'}>
                    <Button
                        variant={"text"}
                        bgHover={colors.green}
                        sx={{ width: '213px', height: '52px' }}
                        onClick={() => navigate(LOGIN_FORGOT_PASSWORD_URL)}

                    >
                        <Typography
                            fontSize={16}
                            fontWeight={400}
                            lineHeight={'16px'}
                            color={colors.white}
                        >
                            Forgot Password
                        </Typography>
                    </Button>
                    <Button
                        variant={"text"}
                        backgroundColor={colors.green}
                        LabelColor={colors.black}
                        disabled={!formik.isValid}
                        bgHover={colors.green}
                        sx={{ width: '213px', height: '52px' }}
                        type='submit'
                    >
                        <Typography
                            fontSize={16}
                            fontWeight={700}
                            lineHeight={'16px'}
                            color={colors.white}
                            textTransform={'uppercase'}
                        >
                            Login
                        </Typography>
                    </Button>
                </Stack>
                <Divider
                    orientation={"vertical"}
                    sx={{
                        height: "1px",
                        borderWidth: "1px",
                        borderColor: colors.green16
                    }}
                />
                <Stack gap={'16px'} paddingTop={'30px'} paddingBottom={'135px'}>
                    <Typography
                        fontSize={18}
                        fontWeight={700}
                        lineHeight={'27px'}
                        color={colors.white}
                        textAlign={'center'}
                    >
                        Do not have an account?
                    </Typography>
                    <Button
                        variant={"outlined"}
                        LabelColor={colors.black}
                        borderColor={colors.green22}
                        sx={{ height: 64 }}
                        onClick={() => navigate(REGISTER_URL)}
                    >
                        <Typography
                            fontSize={16}
                            fontWeight={600}
                            lineHeight={'60px'}
                            color={colors.white}
                            textTransform={'uppercase'}
                        >
                            Register
                        </Typography>
                    </Button>
                </Stack>
            </Stack>
        </form>
    );
};

export default CreatePassword;
import * as Yup from 'yup';

export interface initialValuesProps {
    avatar: any
}

export const initialValues = {
    avatar: undefined
};

export const validationSchema = Yup.object().shape({
    // logo: Yup.string().required('Logo is required'),
});

export const validationSchemaEditLogo = Yup.object().shape({
    // logo: Yup.string().required('Logo is required'),
});
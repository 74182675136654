import { ReactElement, useState } from 'react';
import "./styles.scss";
import { Stack, Typography, Divider, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Button, Input } from 'src/components';
import { colors } from 'src/colors';
import { RankingCard } from '../../components/Card';
import { RadioIcon } from 'src/assets/icons';

export default function Bet(): ReactElement {
    
    const [value, setValue] = useState('Number 1 of the Month');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    return (
        <Stack id='bet' sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: colors.green0E2,
            padding: '24px'
        }}>
            <Stack gap='20px'>
                <Stack gap={'4px'}>
                    <Typography 
                        fontSize={14}
                        fontWeight={400}
                        color={colors.grayB1}
                        lineHeight={'18px'}
                    >
                        Betting in favour or against the KOL
                    </Typography>
                    <Typography 
                        fontSize={18}
                        fontWeight={700}
                        color={colors.white}
                        lineHeight={'27px'}
                    >
                        Bet in this KOL Trades and calls:
                    </Typography>
                </Stack>

                <Typography 
                    fontSize={14}
                    fontWeight={400}
                    color={colors.grayB1}
                    lineHeight={'18px'}
                    whiteSpace={'pre-wrap'}
                >
                    {`Win a 2x amount of tokens invested if this KOL WINS THE MONTHLY \nRanking. If you place bet this Trader/influencer will be in the TOP 1 \nTrader of the month, you will win Double your Tokens!`}
                </Typography>
            </Stack> 
              <Divider 
                orientation={"vertical"}
                className='bet-divider'
                sx={{
                    height: "1px",
                    borderWidth: "1px",
                    borderColor: colors.green16,
                    marginY: '20px'
                }} 
                />
                <Stack gap={'10px'}>
                    <Stack className='bet-section' display={'flex'} flexDirection={'row'} gap={'16px'}>
                        {bets.map((bet)=>(<RankingCard className='bet-card-box' width='25%' items={bet} />))}
                    </Stack>
                </Stack>
                <Divider 
                    orientation={"vertical"}
                    sx={{
                        height: "1px",
                        borderWidth: "1px",
                        borderColor: colors.green16,
                        marginY: '20px'
                    }} 
                />
                <Input
                    label="Your bet"
                    id="bet"
                    className='bet-input-box'
                    // width={'743.14px'}
                />
                <Stack paddingTop={'20px'}>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={handleChange}
                        sx={{
                            '& .MuiButtonBase-root': {
                                color: 'rgba(255, 255, 255, 0.32)'
                            },
                            // '& .MuiButtonBase-root.Mui-checked': {
                            //     color: colors.green
                            // },
                            '& .MuiTypography-root': {
                                fontSize: '18px',
                                fontWeight: 700,
                                color: colors.white
                            }
                        }}
                    >
                        <Stack className='bet-switch-section' sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            gap: '15px'
                        }}>
                            <Stack className='bet-switch-box' sx={{border: value === 'Number 1 of the Month' ? `1px solid ${colors.green}` : `1px solid ${colors.green12}` , padding: '14px', width: '50%'}}>
                                <FormControlLabel value="Number 1 of the Month" control={<Radio checkedIcon={<RadioIcon/>} />} label="Number 1 of the Month" />      
                            </Stack>
                            <Stack className='bet-switch-box' sx={{border: value === 'Worst performance of the month' ? `1px solid ${colors.green}` : `1px solid ${colors.green12}`, padding: '14px', width: '50%'}}>
                                <FormControlLabel value="Worst performance of the month" control={<Radio checkedIcon={<RadioIcon/>} />} label="Worst performance of the month" />
                            </Stack>
                        </Stack>
                    </RadioGroup>
                </Stack> 

                <Stack className='bet-btn-box' sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    paddingTop: '30px',
                    gap: '10px'
                }}>
                    <Button
                        variant={"outlined"}
                        LabelColor={colors.white}
                        borderColor={colors.green}
                        sx={{
                            width: 213,
                            height: 52,
                            justifyContent: 'center'
                        }}
                        onClick={() => console.log("")}
                        className='bet-btn'
                    >
                        <Typography
                            fontSize={16}
                            fontWeight={400}
                            color={colors.white}
                            lineHeight={"16px"}
                            >
                            Collect Prize
                        </Typography>
                    </Button>
                    <Button
                        variant={"contained"}
                        LabelColor={colors.white}
                        backgroundColor={colors.green}
                        borderWidth="2px"
                        sx={{
                            width: 213,
                            height: 52,
                            justifyContent: 'center'
                        }}
                        onClick={() => console.log("")}
                    >
                        <Typography
                            fontSize={16}
                            fontWeight={700}
                            color={colors.white}
                            lineHeight={"16px"}
                        >
                            BET
                        </Typography>
                    </Button>
                </Stack>
        </Stack>
        
    );
}

const bets = [
    {
        id: 1,
        text: "Pool Max Cap",
        price: '2,000,000'
    },
    {
        id: 2,
        text: 'Pool Current Cap',
        price: 'TBA'
    },
    {
        id: 3,
        text: 'Unlocked Time',
        price: 'TBA'
    },
    {
        id: 4,
        text: 'Fixed APR',
        price: '200%'
    }
];
import { ReactElement } from 'react';
import './styles.scss';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Avatar, Table as MuiTable, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import TableHeader from './TableHeader';
import { Link } from "react-router-dom";
import Chart from '../Chart';
import { Adobe } from 'src/assets/icons';

export function Table({ data, isBlockChain, selectedMenu }: { data: any, isBlockChain?: boolean, selectedMenu: string }): ReactElement {

    const cellStyle = {
        textAlign: "right",
        borderBottom: 'inherit',
        color: 'white',
    }

    return (
        <>
            <TableContainer sx={{ width: '100%' }}>
                <MuiTable>
                    <TableHeader />
                    <TableBody>
                        {
                            data
                                .sort((a: any, b: any) => { return b[selectedMenu] - a[selectedMenu] })
                                .map((_el: any, index: number) => {
                                    return (
                                        <TableRow
                                            key={_el.id}
                                            hover
                                            sx={{ borderBottom: data.length - 1 === index ? undefined : '1px solid #0E3841 !important' }}
                                            component={Link}
                                            to={`/top-influencers/${_el.id}`}
                                        >
                                            {/* {isBlockChain && index === 0 ? <TableCell sx={{ ...cellStyle, textAlign: 'left', width: "60px"  }}>

                                            <Stack direction='row' alignItems='center' justifyContent={'center'}>
                                                <Adobe />
                                            </Stack>
                                        </TableCell>
                                        : */}
                                            <TableCell className='digit-column' sx={{ ...cellStyle, textAlign: 'left', width: "60px" }}>
                                                <Stack direction='row' alignItems='center' justifyContent={'center'}>
                                                    <Typography fontSize={14} fontWeight={400}
                                                        color='white'>
                                                        {/* {isBlockChain ? index : index + 1 ?? ''} */}
                                                        {index + 1 ?? ''}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell className='name-column' sx={{ ...cellStyle, textAlign: 'center', width: "60px", padding: '5px' }}>
                                                <img
                                                    src={_el.img}
                                                    alt={`img-${_el.id}`}
                                                    width={40}
                                                    height={40}
                                                    style={{ borderRadius: '8px' }}
                                                />
                                                {/* <Avatar src={''}
                                                variant='square'
                                                sx={{ width: "40px", height: "40px", borderRadius: "8px" }}>
                                            </Avatar> */}
                                            </TableCell>

                                            <TableCell sx={{ ...cellStyle, textAlign: 'left', width: "551.8px" }}>
                                                <Box
                                                    display={'flex'}
                                                >

                                                    <Box
                                                        display={'flex'}
                                                        flexDirection={'column'}
                                                        padding={'4px'}
                                                    >
                                                        <Typography fontSize={14}
                                                            color="white"
                                                            fontWeight={700} >{_el.fullname ?? ''}</Typography>
                                                        {/* <Typography
                                                        fontSize={12}
                                                        fontWeight={400}
                                                        color="white"
                                                        noWrap={true}

                                                    >
                                                        Near
                                                    </Typography> */}

                                                    </Box>
                                                </Box>

                                            </TableCell>
                                            <TableCell sx={cellStyle}>
                                                <Box
                                                    display={'flex'}
                                                    alignItems={'center'}
                                                    justifyContent={'right'}
                                                    gap={'8px'}
                                                >
                                                    <Typography fontSize={13}
                                                        color='white'
                                                        fontWeight={400} >
                                                        {/* {_el?.balance === 0 ? '-' : `$${_el?.balance}k`} */}
                                                        {_el?.youtube}k
                                                    </Typography>

                                                </Box>


                                            </TableCell>

                                            <TableCell sx={cellStyle}>{_el.telegram}</TableCell>
                                            <TableCell sx={cellStyle}> {_el.instagram}</TableCell>
                                            <TableCell sx={cellStyle}>{_el.twitter}</TableCell>
                                            <TableCell sx={cellStyle}>{_el.reddit}</TableCell>
                                            {/* <TableCell sx={cellStyle}>
                                            <Stack sx={{ height: '20px', width: '100px' }}>
                                                <Chart />
                                            </Stack>
                                        </TableCell> */}


                                        </TableRow>
                                    );
                                })
                        }

                    </TableBody>
                </MuiTable >
            </TableContainer >
        </>
    );
}
export default Table;
import React from "react";
import { Stack, Typography } from '@mui/material';
import { colors } from 'src/colors';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components';
import { LeftArrowIcon } from 'src/assets/icons';
import { useFormik, } from 'formik';
import { initialValues, validationSchema } from 'src/formValidations/verificationCode';
import { REGISTER_CREATE_PASSWORD_URL } from 'src/config/urls';
import { useSendVerificationEmailMutation, useVerificationEmailMutation } from "src/api/auth";
import { toast } from "react-toastify";
import { setUserRegisterInfo } from "src/slices/mainSlice";
import { useAppDispatch, useAppSelector } from "src/hooks/ReduxHooks";
import ResendCountDown from "./ResendCountDown";
import ReactCodeInput from 'react-verification-code-input';
const VerificationCode: React.FC<{}> = () => {
    const [verificationEmail] = useVerificationEmailMutation();
    const [sendVerificationEmail] = useSendVerificationEmailMutation();
    const Dispatch = useAppDispatch();
    const user = useAppSelector(s => s.mainSlice.user);

    const navigate = useNavigate();


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: (values: any) => {
            console.log(values);
            const body = {
                email: user.email,
                verificationCode: values.code//1 + values.code2 + values.code3 + values.code4 + values.code5
            }
            verificationEmail(body)
                .unwrap()
                .then((res: any) => {
                    Dispatch(setUserRegisterInfo(
                        {
                            ...user,
                            emailVerified: res.data.verified,
                            setPasswordToken: res.data.setPasswordToken
                        }
                    ));
                    navigate(REGISTER_CREATE_PASSWORD_URL);
                    toast.success(res.message);
                })
                .catch((err: any) => {
                    toast.error(err.message);
                })
        }
    });

    const { handleSubmit } = formik;

    const handleResend = () => {

        sendVerificationEmail(
            {
                email: user.email,
                userId: user?._id ?? ''
            }
        )
    }
    console.log('render');
    return (
        <form onSubmit={handleSubmit} >
            <Stack sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: colors.green0E2,
                justifyContent: 'flex-start',
                padding: '8px 24px 24px 24px',
                gap: '16px',
                height: '780px',
                marginY: '25px',
                marginRight: '22px'
            }}>
                <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    gap={'12px'}
                    marginLeft={'13px'}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => navigate(-1)}
                >
                    <LeftArrowIcon />
                    <Typography
                        fontSize={16}
                        fontWeight={600}
                        lineHeight={'60px'}
                        color={colors.white}
                    >
                        Back
                    </Typography>
                </Stack>
                <Stack gap={'.5px'}>
                    <Typography
                        fontSize={14}
                        fontWeight={400}
                        color={colors.grayB1}
                        lineHeight={'21px'}
                    >
                        Verification code
                    </Typography>
                    <Typography
                        fontSize={18}
                        fontWeight={700}
                        color={colors.white}
                        lineHeight={'27px'}
                    >
                        Enter the code to verify {user.email}
                    </Typography>
                </Stack>
                <ReactCodeInput
                    fields={5}
                    className="otp"
                    onChange={(value) =>
                        formik.setValues((prev: any) => { return { ...prev, code: value } })
                    } />
                {/* <Stack flexDirection={'row'} gap={'16px'}>
                    <TextField
                        value={values.code1}
                        type={'number'}
                        fullWidth
                        variant='outlined'
                        name='code1'
                        id='code1'
                        inputRef={ref}
                        autoComplete='code1'
                        onChange={handleChange}
                    />
                    <TextField
                        value={values.code2}
                        type={'number'}
                        fullWidth
                        variant='outlined'
                        name='code2'
                        id='code2'
                        autoComplete='code2'
                        onChange={handleChange}
                    />
                    <TextField
                        value={values.code3}
                        type={'number'}
                        fullWidth
                        variant='outlined'
                        name='code3'
                        id='code3'
                        autoComplete='code3'
                        onChange={handleChange}
                    />
                    <TextField
                        value={values.code4}
                        type={'number'}
                        fullWidth
                        variant='outlined'
                        name='code4'
                        id='code4'
                        autoComplete='code4'
                        onChange={handleChange}
                    />
                    <TextField
                        value={values.code5}
                        type={'number'}
                        fullWidth
                        variant='outlined'
                        name='code5'
                        id='code5'
                        autoComplete='code5'
                        onChange={handleChange}
                    />
                </Stack> */}
                <Stack flexDirection={'row'} gap={'5px'} marginY={'5px'} >
                    <Typography
                        fontSize={16}
                        fontWeight={400}
                        lineHeight={'16px'}
                        color={colors.white}
                        sx={{
                            opacity: '80%'
                        }}
                    >
                        Didn't received code ?
                    </Typography>
                    <Typography
                        fontSize={16}
                        fontWeight={400}
                        lineHeight={'16px'}
                        color={colors.green}
                        sx={{
                            opacity: '80%',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleResend()}
                    >
                        Resend
                    </Typography>

                </Stack>
                <Stack display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
                    <Button
                        variant={"outlined"}
                        LabelColor={colors.black}
                        borderColor={colors.green22}
                        backgroundColor={colors.green}
                        bgHover={colors.green}
                        sx={{ height: 52, width: '265px' }}
                        type={'submit'}
                        disabled={!formik.isValid}
                    >
                        <Typography
                            fontSize={16}
                            fontWeight={700}
                            lineHeight={'16px'}
                            color={colors.white}
                        >
                            Next
                        </Typography>
                    </Button>
                </Stack>
            </Stack>
        </form>
    );
};

export default VerificationCode;
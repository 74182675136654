import { ReactElement } from "react";
import { Button, Stack, Typography, Box } from "@mui/material";
import './styles.scss';
import { Container } from "src/components";
import { MyTable } from "src/features/topInfluencer/RightSide/table";
import Card from "./Card";
import { useNavigate } from "react-router-dom";
import Categories from "./Categories";
import { colors } from 'src/colors';

export default function MoreVerification(): ReactElement {
  const navigate = useNavigate();
  return (
    <Stack id='more-verification' sx={{ paddingY: '80px' }} >
      <Container>
        <Stack className='more-verification-box' width={'60%'}>
          <Typography className='more-verification-title' fontSize={44} fontWeight={700} color={colors.white}>
            Far more to explore
          </Typography>
          <Typography className='more-verification-content' fontSize={17} fontWeight={400} color={colors.white} pt={'20px'}>
            Peerless, actionable, multichain data and coverage on the widest selection of dapps, NFT collections, DeFi
            projects and more. View top collections, trending dapps, trader volume and maybe, just maybe, uncover the
            next blockchain unicorn.
          </Typography>
        </Stack>
        <Stack className='more-items-box' direction={'row'} spacing={'16px'} py={' 20px'}>
          {data.map((item) => <Card title={item.title} value={item.value} />)}
        </Stack>
        <Stack className='categories-box' pt={'16px'} pb={'20px'} spacing={'20px'} >
          <Categories />
          <Box sx={{ backgroundColor: '#0E2331', borderRadius: '16px' }}>
            <MyTable isBlockChain={false} />
            {/* <Stack
            direction="row-reverse"
            alignItems={"center"}
            sx={{ padding: "16px 9px" }}
          >
            <Pagination params={maidData?.pagination} setParams={setParams} />

            <Pagination count={10} shape="rounded" />
            <ButtonMenu />
          </Stack> */}
          </Box>
        </Stack>
        <Stack direction={'row'}>
          <Button
            className='more-btn-explore'
            onClick={() => { navigate('/top-influencers') }}
            variant="contained" sx={{ borderRadius: "0", backgroundColor: "#14C2A3", color: colors.white }}>
            Explore 14786 dapps
          </Button>
        </Stack>

      </Container >
    </Stack >
  );

}
const data = [
  { title: 'KOLS', value: 14786 },
  { title: 'Wallets tracked', value: 32915 },
  { title: 'Chains', value: 53 },
];
import { ReactElement } from 'react';

import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Avatar, Table as MuiTable, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import TableHeader from './TableHeader';
import { colors } from 'src/colors';
import { DownloadIcon } from 'src/assets/icons';

export function Table({ data }: { data: any}): ReactElement {

    const cellStyle = {
        textAlign: "right",
        borderBottom: 'inherit',
        color: 'white',
    }

    return (
        <>
            <TableContainer sx={{ width: '100%' }}>
                <Stack marginTop={'25px'}>
                    <Typography
                        fontSize={16}
                        fontWeight={500}
                        lineHeight={'24px'}
                        color={colors.grayCC}
                        marginBottom={'10px'}
                    >
                        October 19, 2023
                    </Typography>
                    <MuiTable>
                        <TableHeader />
                        <TableBody>
                            {
                                data.slice(0, 6).map((_el: any, index: number) => {
                                    return (
                                        <TableRow
                                            key={_el.id}
                                            hover
                                            sx={{ 
                                                // borderBottom: _el.id !== 10 ? `1px solid ${colors.default} !important` : undefined,
                                            '& td': {
                                                padding: '6px 16px'
                                            } }}
                                        >
                                            <TableCell sx={{ ...cellStyle, textAlign: 'left', width: "296px"  }}>
                                                <Box
                                                    display={'flex'}
                                                    flexDirection={'row'}
                                                    alignItems={'center'}
                                                    gap={'10px'}
                                                >
                                                    <Avatar src={''}
                                                        variant='square'
                                                        sx={{ width: "32px", height: "32px", borderRadius: "50%" }}>
                                                    </Avatar>
                                                    <Typography 
                                                        fontSize={16}
                                                        fontWeight={500}
                                                        lineHeight={'24px'}
                                                        color={colors.white}
                                                        sx={{ whiteSpace: 'nowrap' }}
                                                    >
                                                        {_el.type ?? ''}
                                                    </Typography>
                                                </Box>
                                            </TableCell>

                                            <TableCell sx={{ ...cellStyle, textAlign: 'left', width: "500px"  }}>
                                                <Box
                                                    display={'flex'}
                                                    alignItems={'center'}
                                                    flexDirection={'row'}
                                                    gap={'8px'}
                                                >
                                                    <Avatar src={''}
                                                        variant='square'
                                                        sx={{ width: "32px", height: "32px", borderRadius: "50%" }}>
                                                    </Avatar>
                                                    <Stack  
                                                        flexDirection={'column'}
                                                        alignItems={'flex-start'}
                                                        borderRadius={'8px'}
                                                        gap={'8px'} 
                                                        sx={{
                                                            padding: '5px 3.06px 5px 4px'
                                                        }}
                                                    >
                                                        <Typography 
                                                            fontSize={18}
                                                            fontWeight={500} 
                                                            lineHeight={'26px'}
                                                            color={_el.id === 1 ? colors.green6C : colors.white}
                                                        > 
                                                            {`${_el?.amount} ETH`}
                                                        </Typography>
                                                        <Typography 
                                                            fontSize={14}
                                                            fontWeight={400} 
                                                            lineHeight={'20px'}
                                                            color={colors.grayCC}
                                                        > 
                                                            {`£${_el?.price}`}
                                                        </Typography>
                                                    </Stack>
                                                    
                                                </Box>
                                            </TableCell>

                                            <TableCell sx={{ ...cellStyle, textAlign: 'center' }}>
                                                <Box display={'flex'} >
                                                    <Stack  
                                                        flexDirection={'row'}
                                                        alignItems={'center'} 
                                                        borderRadius={'8px'}
                                                        gap={'8px'} 
                                                        sx={{
                                                            padding: '5px 3.06px 5px 4px'
                                                        }}
                                                    >
                                                    <Avatar src={''}
                                                        variant='square'
                                                        sx={{ width: "24px", height: "24px", borderRadius: "50%" }}>
                                                    </Avatar>
                                                    <Typography 
                                                        fontSize={14}
                                                        fontWeight={500} 
                                                        lineHeight={'20px'}
                                                        color={colors.white}
                                                    > 
                                                        {_el.portfolio}
                                                    </Typography>
                                                    </Stack>
                                                </Box>
                                                
                                            </TableCell>
                                            
                                            <TableCell sx={{ ...cellStyle, textAlign: 'right' }}>
                                                <DownloadIcon />
                                                
                                            </TableCell>
                                            
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </MuiTable >
                </Stack>

                <Stack marginTop={'25px'}>
                    <Typography
                        fontSize={16}
                        fontWeight={500}
                        lineHeight={'24px'}
                        color={colors.grayCC}
                        marginBottom={'10px'}
                    >
                        October 18, 2023
                    </Typography>
                    <MuiTable>
                        <TableHeader />
                        <TableBody>
                            <TableRow
                                
                                            hover
                                            sx={{ 
                                            '& td': {
                                                padding: '6px 16px'
                                            } }}
                                        >
                                            <TableCell sx={{ ...cellStyle, textAlign: 'left', width: "296px"  }}>
                                                <Box
                                                    display={'flex'}
                                                    flexDirection={'row'}
                                                    alignItems={'center'}
                                                    gap={'10px'}
                                                >
                                                    <Avatar src={''}
                                                        variant='square'
                                                        sx={{ width: "32px", height: "32px", borderRadius: "50%" }}>
                                                    </Avatar>
                                                    <Typography 
                                                        fontSize={16}
                                                        fontWeight={500}
                                                        lineHeight={'24px'}
                                                        color={colors.white}
                                                        sx={{ whiteSpace: 'nowrap' }}
                                                    >
                                                        {'Received'}
                                                    </Typography>
                                                </Box>
                                            </TableCell>

                                            <TableCell sx={{ ...cellStyle, textAlign: 'left', width: "500px"  }}>
                                                <Box
                                                    display={'flex'}
                                                    alignItems={'center'}
                                                    flexDirection={'row'}
                                                    gap={'8px'}
                                                >
                                                    <Avatar src={''}
                                                        variant='square'
                                                        sx={{ width: "32px", height: "32px", borderRadius: "50%" }}>
                                                    </Avatar>
                                                    <Stack  
                                                        flexDirection={'column'}
                                                        alignItems={'flex-start'}
                                                        borderRadius={'8px'}
                                                        gap={'8px'} 
                                                        sx={{
                                                            padding: '5px 3.06px 5px 4px'
                                                        }}
                                                    >
                                                        <Typography 
                                                            fontSize={18}
                                                            fontWeight={500} 
                                                            lineHeight={'26px'}
                                                            color={colors.white}
                                                        > 
                                                            0 ETH
                                                        </Typography>
                                                        <Typography 
                                                            fontSize={14}
                                                            fontWeight={400} 
                                                            lineHeight={'20px'}
                                                            color={colors.grayCC}
                                                        > 
                                                            £0
                                                        </Typography>
                                                    </Stack>
                                                    
                                                </Box>
                                            </TableCell>

                                            <TableCell sx={{ ...cellStyle, textAlign: 'center' }}>
                                                <Box display={'flex'} >
                                                    <Stack  
                                                        flexDirection={'row'}
                                                        alignItems={'center'} 
                                                        borderRadius={'8px'}
                                                        gap={'8px'} 
                                                        sx={{
                                                            padding: '5px 3.06px 5px 4px'
                                                        }}
                                                    >
                                                    <Avatar src={''}
                                                        variant='square'
                                                        sx={{ width: "24px", height: "24px", borderRadius: "50%" }}>
                                                    </Avatar>
                                                    <Typography 
                                                        fontSize={14}
                                                        fontWeight={500} 
                                                        lineHeight={'20px'}
                                                        color={colors.white}
                                                    > 
                                                        Ethereum
                                                    </Typography>
                                                    </Stack>
                                                </Box>
                                                
                                            </TableCell>
                                            
                                            <TableCell sx={{ ...cellStyle, textAlign: 'right' }}>
                                                <DownloadIcon />
                                                
                                            </TableCell>
                                            
                                        </TableRow>
                        </TableBody>
                    </MuiTable >
                </Stack>

                <Stack marginTop={'25px'}>
                    <Typography
                        fontSize={16}
                        fontWeight={500}
                        lineHeight={'24px'}
                        color={colors.grayCC}
                        marginBottom={'10px'}
                    >
                        October 9, 2023
                    </Typography>
                    <MuiTable>
                        <TableHeader />
                        <TableBody>
                            {
                                data.slice(7, 10).map((_el: any, index: number) => {
                                    return (
                                        <TableRow
                                            key={_el.id}
                                            hover
                                            sx={{ 
                                            '& td': {
                                                padding: '6px 16px'
                                            } }}
                                        >
                                            <TableCell sx={{ ...cellStyle, textAlign: 'left', width: "296px"  }}>
                                                <Box
                                                    display={'flex'}
                                                    flexDirection={'row'}
                                                    alignItems={'center'}
                                                    gap={'10px'}
                                                >
                                                    <Avatar src={''}
                                                        variant='square'
                                                        sx={{ width: "32px", height: "32px", borderRadius: "50%" }}>
                                                    </Avatar>
                                                    <Typography 
                                                        fontSize={16}
                                                        fontWeight={500}
                                                        lineHeight={'24px'}
                                                        color={colors.white}
                                                        sx={{ whiteSpace: 'nowrap' }}
                                                    >
                                                        {_el.type ?? ''}
                                                    </Typography>
                                                </Box>
                                            </TableCell>

                                            <TableCell sx={{ ...cellStyle, textAlign: 'left', width: "500px"  }}>
                                                <Box
                                                    display={'flex'}
                                                    alignItems={'center'}
                                                    flexDirection={'row'}
                                                    gap={'8px'}
                                                >
                                                    <Avatar src={''}
                                                        variant='square'
                                                        sx={{ width: "32px", height: "32px", borderRadius: "50%" }}>
                                                    </Avatar>
                                                    <Stack  
                                                        flexDirection={'column'}
                                                        alignItems={'flex-start'}
                                                        borderRadius={'8px'}
                                                        gap={'8px'} 
                                                        sx={{
                                                            padding: '5px 3.06px 5px 4px'
                                                        }}
                                                    >
                                                        <Typography 
                                                            fontSize={18}
                                                            fontWeight={500} 
                                                            lineHeight={'26px'}
                                                            color={_el.id === 1 ? colors.green6C : colors.white}
                                                        > 
                                                            {`${_el?.amount} ETH`}
                                                        </Typography>
                                                        <Typography 
                                                            fontSize={14}
                                                            fontWeight={400} 
                                                            lineHeight={'20px'}
                                                            color={colors.grayCC}
                                                        > 
                                                            {`£${_el?.price}`}
                                                        </Typography>
                                                    </Stack>
                                                    
                                                </Box>
                                            </TableCell>

                                            <TableCell sx={{ ...cellStyle, textAlign: 'center' }}>
                                                <Box display={'flex'} >
                                                    <Stack  
                                                        flexDirection={'row'}
                                                        alignItems={'center'} 
                                                        borderRadius={'8px'}
                                                        gap={'8px'} 
                                                        sx={{
                                                            padding: '5px 3.06px 5px 4px'
                                                        }}
                                                    >
                                                    <Avatar src={''}
                                                        variant='square'
                                                        sx={{ width: "24px", height: "24px", borderRadius: "50%" }}>
                                                    </Avatar>
                                                    <Typography 
                                                        fontSize={14}
                                                        fontWeight={500} 
                                                        lineHeight={'20px'}
                                                        color={colors.white}
                                                    > 
                                                        {_el.portfolio}
                                                    </Typography>
                                                    </Stack>
                                                </Box>
                                                
                                            </TableCell>
                                            
                                            <TableCell sx={{ ...cellStyle, textAlign: 'right' }}>
                                                <DownloadIcon />
                                                
                                            </TableCell>
                                            
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </MuiTable >
                </Stack>
            </TableContainer >
        </>
    );
}
export default Table;
import { Box, Stack, Typography } from '@mui/material';
import dataTopHeader from '../../assets/fake-data/data-top-header';
import { colors } from '../../colors';
import { animated, useSpring } from '@react-spring/web'

interface Props {
    className?: string;
}

export const TopHeader = (props: Props) => {
    const { className = '' } = props;


    const styles = useSpring({
        from: { transform: "translateX(100%)" },
        to: { transform: "translateX(-100%)" },
        config: { duration: 26000 },
        loop: true,
    })

    return (
        <Stack className={className} sx={{
            display: 'flex',
            flexDirection: 'row',
            padding: '20px 0 0 20px',
            gap: '33px',
            whiteSpace: 'nowrap',
            height: '75px'
        }}>
            <Typography
                fontSize={14}
                fontWeight={600}
                color={colors.white}
                lineHeight={'16px'}
            >
                HOT KOLS
            </Typography>
            <Stack position={'relative'} overflow={'hidden'} width={'100%'}>
                <animated.div style={styles}>
                    <Stack sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '33px'
                    }}>
                        {
                            dataTopHeader.map((item: any) => (
                                <Stack key={item.id} sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: '6px'
                                }}>
                                    <Box sx={{ borderRadius: '50%' }} component='img' src={item.img} />
                                    <Typography
                                        fontSize={14}
                                        fontWeight={600}
                                        color={colors.white}
                                        lineHeight={'16px'}

                                    >
                                        {item.text}
                                    </Typography>
                                </Stack>
                            ))
                        }
                    </Stack>
                </animated.div>
            </Stack>
        </Stack>
    );
}

export default TopHeader;
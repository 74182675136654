import React, { useState } from "react";
import { Stack, Typography, Divider, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { colors } from 'src/colors';
import { Button } from 'src/components';
import { TOP_BLOCK_CHAIN_URL, SUBMIT_URL, SUBMIT_KOL_URL } from 'src/config/urls';
import { useFormik } from 'formik';
import { validationSchema, initialValuesProps } from 'src/formValidations/choosePicture';
import { ImageUpload } from 'src/components/fileUpload/ImageUpload';
import { useUpdateProfileMutation } from "src/api/user";
import { useAppDispatch, useAppSelector } from "src/hooks/ReduxHooks";
import { setUpdateUserInfo } from "src/slices/mainSlice";
import { toast } from "react-toastify";

const ChoosePicture: React.FC<{}> = () => {

    const navigate = useNavigate();
    const [updateProfile] = useUpdateProfileMutation();
    const Dispatch = useAppDispatch();
    const user = useAppSelector(store => store.mainSlice.user);

    const formik = useFormik({
        initialValues: {
            avatar: ''
        } as initialValuesProps,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            updateProfile({ data: values, token: user.accessToken ?? '' })
                .unwrap()
                .then((res) => {
                    Dispatch(setUpdateUserInfo(values));
                    toast.success('Update Profile Success');
                    navigate(SUBMIT_KOL_URL)
                })

        },
    });

    const { errors, touched, values, handleSubmit, setFieldValue } = formik;

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: colors.green0E2,
                justifyContent: 'flex-start',
                padding: '28px 24px 24px 24px',
                gap: '60px',
                marginY: '25px',
                marginRight: '22px',
                width: '796px'
            }}>
                <Stack gap={'.5px'}>
                    <Typography
                        fontSize={14}
                        fontWeight={400}
                        color={colors.grayB1}
                        lineHeight={'21px'}
                    >
                        Pick a profile picture
                    </Typography>
                    <Typography
                        fontSize={18}
                        fontWeight={700}
                        color={colors.white}
                        lineHeight={'27px'}
                    >
                        Have a favorite selfie? Upload it now.
                    </Typography>
                </Stack>

                <Stack display={'flex'} flexDirection={'row'} justifyContent={'center'} >
                    <Stack
                        width={'200px'}
                        height={'200px'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        borderRadius={'100%'}
                        sx={{ overflow: 'hidden', cursor: 'pointer' }}
                    >
                        {values.avatar ? <Box
                            width={'100%'}
                            height={'100%'}
                            borderRadius={'100%'}
                            component={'img'}
                            border={`1px solid ${colors.green1B}`}
                            sx={{
                                backgroundImage: `url(${values.avatar ? values.avatar : ''})`,
                                backgroundPosition: '50% 50%',
                                backgroundSize: 'cover'
                            }}
                        /> : <Stack position={'relative'}>
                            <ImageUpload
                                onFileUrl={(url) => setFieldValue('avatar', url)}
                                onError={(txt: string) => console.log(txt)}
                            />
                        </Stack>
                        }
                    </Stack>
                </Stack>

                <Divider
                    orientation={"vertical"}
                    sx={{
                        height: "1px",
                        borderWidth: "1px",
                        borderColor: colors.green16
                    }}
                />
                {values.avatar ? (
                    <Stack display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} gap={'16px'}>
                        <Button
                            variant={"outlined"}
                            LabelColor={colors.black}
                            borderColor={colors.green}
                            bgHover={colors.green}
                            sx={{ height: 52, width: '199px' }}
                            onClick={() => navigate(TOP_BLOCK_CHAIN_URL)}
                        >
                            <Typography
                                fontSize={16}
                                fontWeight={700}
                                lineHeight={'16px'}
                                color={colors.white}
                            >
                                Register
                            </Typography>
                        </Button>
                        <Button
                            variant={"outlined"}
                            LabelColor={colors.black}
                            borderColor={colors.green22}
                            backgroundColor={colors.green}
                            bgHover={colors.green}
                            sx={{ height: 52, }}
                            type='submit'
                        >
                            <Typography
                                fontSize={16}
                                fontWeight={700}
                                lineHeight={'16px'}
                                color={colors.white}
                            >
                                REGISTER & SUBMIT A KOL APPLICATION
                            </Typography>
                        </Button>
                    </Stack>
                ) :
                    <Stack display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} >
                        <Button
                            variant={"outlined"}
                            LabelColor={colors.black}
                            borderColor={'transparent'}
                            backgroundColor={colors.green}
                            bgHover={colors.green}
                            sx={{ height: 52, width: '265px' }}
                            onClick={() => navigate(SUBMIT_URL)}
                        >
                            <Typography
                                fontSize={16}
                                fontWeight={700}
                                lineHeight={'16px'}
                                color={colors.white}
                            >
                                Skip for now
                            </Typography>
                        </Button>
                    </Stack>
                }
            </Stack>
        </form>
    );
};

export default ChoosePicture;
import { Stack } from "@mui/material";
import { ReactElement } from "react";
import TopInfluencer from "src/features/topInfluencer";

export default function TopInfluencerPage(): ReactElement {
  return (
    <Stack height={'100%'}>
      <TopInfluencer />
    </Stack>
  );
}
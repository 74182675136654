import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import WorkItem from './work-item';

Work.propTypes = {
    data: PropTypes.array,
};

function Work(props) {

    const { data } = props;

    const [dataBlock] = useState({
        subtitle: 'Staking Pools',
        title: 'Our Ecosystem of Pools',
        desc: `In staking pools, users have the opportunity to anchor their tokens alongside a KOL they have faith in. This is both a declaration of trust and a genuine investment in that KOL's decisions and insights. The amount of tokens staked within a pool directly impacts the profit potential of that KOL. Consequently, users have the potential to earn profits influenced by the investment decisions and successes of the associated KOL.`
    })
    return (
        <section id='staking-pools' className="tf-section how-we-work">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tf-title st2 mb-60" data-aos="fade-up" data-aos-duration="800">
                            <p className="h8 sub-title">{dataBlock.subtitle}</p>
                            <h4 className="title m-b17">{dataBlock.title}</h4>
                            <p className="p-r40">{dataBlock.desc} </p>
                        </div>

                    </div>

                    {
                        data.map(item => (
                            <div key={item.id} className="col-xl-4 col-lg-6 col-md-6 col-12" data-aos="fade-up" data-aos-delay="100" data-aos-duration="800">
                                <WorkItem item={item} />
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );
}

export default Work;
import { Stack, Typography, Tabs, Tab } from "@mui/material";
import "./styles.scss";
import { ReactElement, useState } from "react";
import { 
  UserLogo,
  UserInfoImg,
  YellowStar,
  GrayTopArrow,
  Tick,
  WhiteMore
} from 'src/assets/icons';
import { colors } from '../../../colors';
import { Button, Tag } from '../../../components';
import Overview from './pages/Overview';
import Feed from './pages/Feed';
import dataRankings from '../../../assets/fake-data/data-rankings';

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

export default function RankingDetail(props: any): ReactElement {
  const { id } = props;
  const [value, setValue] = useState<number>(0);


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
  };

  const renderTabs: { [key: number]: any } = {
    0:  <Overview id={id} data={dataRankings}  />,
    1:  <Feed id={id} data={dataRankings} />
  };

  return (

    <Stack id='ranking-detail' width={"61%"} gap={'20px'} padding={'0 42px 0 79px'}>
      {dataRankings.map((info: any) => {
        if (info.id === id) { 
         return (<>
          <Stack className='ranking-section' display={'flex'} flexDirection={'row'} >
            <Stack flexDirection={'row'} alignItems={'center'} gap={'35px'}>
              <Stack flexDirection={'row'} alignItems={'center'} gap={'3px'}>
                <Typography 
                    fontSize={14}
                    fontWeight={400}
                    color={colors.grayB1}
                    lineHeight={'17px'}
                >
                    Rankings
                </Typography>
                <GrayTopArrow />
              </Stack>
              <Typography 
                  fontSize={14}
                  fontWeight={400}
                  color={colors.white}
                  lineHeight={'17px'}
              >
                  KOLWATCH
              </Typography>
            </Stack>
            <Stack className="btn-more-box">
              <Button
                    variant={"outlined"}
                    className='btn-more'
                    LabelColor={colors.white}
                    borderColor={colors.green}
                    sx={{  width: 140 }}
                    startIcon={<WhiteMore />}
                >
                    <Typography 
                        fontSize={16}
                        fontWeight={400}
                        color={colors.white}
                        lineHeight={'24px'}
                    >
                        More
                    </Typography>
                </Button>
            </Stack>
          </Stack>

          <Stack className='about-section' sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              backgroundColor: colors.green0E2,
              padding: '24px',
          }}>
            <Stack className='about-section-details' sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '25px'
            }}>
              <img src={info.img} alt={`img-${info.id}`} width={100} height={100} />
              <Stack sx={{
                display: 'flex',
                flexDirection: 'column'
              }}>
                <Stack className='name-box' sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '18px',
                  paddingBottom: '10px'
                }}>
                  <Typography 
                    fontSize={32}
                    fontWeight={600}
                    color={colors.white}
                    lineHeight={'48px'}
                    className="fullname"
                  >
                      {info.fullname}
                  </Typography>
                  <Tick width={24} height={22.83} />
                </Stack>
                
                {/* <Stack sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '6px'
                }}> */}
                  {/* <UserInfoImg /> */}
                  
                  {/* <Typography 
                    fontSize={16}
                    fontWeight={400}
                    color={colors.white}
                    lineHeight={'24px'}
                  >
                      Near
                  </Typography> */}
                  <Typography 
                      fontSize={16}
                      fontWeight={400}
                      color={colors.white}
                      lineHeight={'24px'}
                      sx={{ opacity: '80%' }}
                      className="ranking-info"
                  >
                      Favourite chain: Lorem Ipsum
                  </Typography>
                  <Typography 
                      fontSize={16}
                      fontWeight={400}
                      color={colors.white}
                      lineHeight={'24px'}
                      sx={{ opacity: '80%' }}
                      className="ranking-info"
                  >
                      Favourite coin: Lorem Ipsum
                  </Typography>
                {/* </Stack> */}
              </Stack>
              
            </Stack>

            <Stack sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                alignItems: 'flex-end'
            }}>
                <Button
                    variant={"contained"}
                    className='btn-master'
                    LabelColor={colors.white}
                    backgroundColor={colors.green}
                    sx={{  width: 140 }}
                    startIcon={<YellowStar />}
                    onClick={() => console.log("") }
                >
                    <Typography 
                        fontSize={16}
                        fontWeight={400}
                        color={colors.white}
                        lineHeight={'24px'}
                    >
                        MasterMind
                    </Typography>
                </Button>
                <Typography 
                  fontSize={16}
                  fontWeight={400}
                  color={colors.white}
                  lineHeight={'24px'}
                >
                  Score: 100
                </Typography>
            </Stack>

          </Stack>

          <Stack sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '15px'
          }}>
              <Tabs
                  className="ranking-detail-tabs"
                  sx={{
                      minHeight: 0,
                      '& .MuiTab-root': {
                        backgroundColor: colors.gray49,
                        minHeight: 0,
                        minWidth: 0,
                        fontWeight: 700,
                        color: colors.grayB1,
                        padding: '8px 16px',
                        fontSize: '16px',
                        lineHeight: '20px',
                      },
                      '& .MuiTabs-flexContainer': {
                        gap: '13px'
                      },
                      '& .MuiTab-root.Mui-selected': {
                        color: colors.white,
                        backgroundColor: colors.green12,
                        border:`1px solid ${colors.green}`
                      },
                      '& .MuiTabs-indicator': {
                        height: 0
                      }
                  }}
                  value={value} onChange={handleChange} aria-label='tabs'
              >
                  <Tab label='Overview' {...a11yProps(0)} />
                  <Tab label='Feed' {...a11yProps(1)} />
              </Tabs>
          </Stack>
          <>{renderTabs[value]} </>
      </>)
      }
    })}
    </Stack>
  );
}

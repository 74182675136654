import React, { Dispatch, SetStateAction } from "react";
import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { colors } from 'src/colors';
import { Input, Button } from 'src/components';
import { LeftArrowIcon } from 'src/assets/icons';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from "src/hooks/ReduxHooks";
import { setUpdateUserInfo } from "src/slices/mainSlice";
import { useUpdateProfileMutation } from "src/api/user";
import { toast } from "react-toastify";

export const initialValues: { favoriteChain: string, favoriteCoin: string } = {
  favoriteChain: '',
  favoriteCoin: ''
}
const CreateYourProfile: React.FC<{ setStep: Dispatch<SetStateAction<number>> }> = ({ setStep }) => {

  const navigate = useNavigate();
  const Dispatch = useAppDispatch();
  const user = useAppSelector(store => store.mainSlice.user);

  const [updateProfile] = useUpdateProfileMutation();
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {

      updateProfile({ data: values, token: user.accessToken ?? '' })
        .unwrap()
        .then((res) => {
          Dispatch(setUpdateUserInfo(values));
          toast.success('Update Profile Success');
          setStep((prev: number) => prev + 1)
        })
    }
  });
  const { getFieldProps } = formik;
  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors.green0E2,
        justifyContent: 'flex-start',
        padding: '8px 24px 24px 24px',
        gap: '20px',
        height: '780px',
        marginY: '25px',
        marginRight: '22px'
      }}>
        <Stack
          flexDirection={'row'}
          alignItems={'center'}
          gap={'12px'}
          marginLeft={'13px'}
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate(-1)}
        >
          <LeftArrowIcon />
          <Typography
            fontSize={16}
            fontWeight={600}
            lineHeight={'60px'}
            color={colors.white}
          >
            Back
          </Typography>
        </Stack>
        <Stack gap={'.5px'}>
          <Typography
            fontSize={14}
            fontWeight={400}
            color={colors.grayB1}
            lineHeight={'21px'}
          >
            Personal details
          </Typography>
          <Typography
            fontSize={18}
            fontWeight={700}
            color={colors.white}
            lineHeight={'27px'}
            whiteSpace={'pre-wrap'}
          >
            Let’s Create your profile
          </Typography>
        </Stack>

        <Input
          label="Favorite chain:"
          id="favoriteChain"
          {...getFieldProps('favoriteChain')}
        />
        <Input
          label="Favorite coin:"
          id="favoriteCoin"
          {...getFieldProps('favoriteCoin')}

        />
        <Stack gap={'.5px'}>
          <Typography
            fontSize={14}
            fontWeight={400}
            color={colors.grayB1}
            lineHeight={'21px'}
          >
            KYC (Optional)
          </Typography>
          <Typography
            fontSize={18}
            fontWeight={700}
            color={colors.white}
            lineHeight={'27px'}
            whiteSpace={'pre-wrap'}
          >
            We suggest you to complete the KYC
          </Typography>
          <Stack direction='row' spacing='5px'>
            <Typography
              fontSize={18}
              fontWeight={700}
              color={colors.green}
              lineHeight={'27px'}
              whiteSpace={'pre-wrap'}
              sx={{ textDecoration: 'underline' }}
            >
              KYC Click here:

            </Typography>
            <Typography sx={{
              color: colors.white,
              fontFamily: "Chakra Petch",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "27px"
            }}>
              Not Completed Yet!
            </Typography>
          </Stack>
        </Stack>
        <Stack display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
          <Button
            variant={"outlined"}
            LabelColor={colors.black}
            borderColor={colors.green22}
            backgroundColor={colors.green}
            bgHover={colors.green}
            sx={{ height: 52, width: '265px' }}
            type="submit"
            disabled={!formik.isValid}
          >
            <Typography
              fontSize={16}
              fontWeight={700}
              lineHeight={'16px'}
              color={colors.white}
            >
              Next
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default CreateYourProfile;
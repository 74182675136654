import img1 from '../images/common/img1.jpg'
import img2 from '../images/common/img2.jpg'
import img3 from '../images/common/img3.jpg'
import img4 from '../images/common/img4.jpg'
import img5 from '../images/common/img5.jpg'
import img6 from '../images/common/img6.jpg'
import img7 from '../images/common/img7.jpg'
import img9 from '../images/common/img9.jpg'
import img10 from '../images/common/img10.jpg'
import img11 from '../images/common/img11.jpg'
import img12 from '../images/common/img12.jpg'
import img13 from '../images/common/img13.jpg'
import img14 from '../images/common/img14.jpg'


const dataProject = [
    {
        id: 1,
        percent: '25%',
        subTitle: 'Presale',
        title: '25 Million KWATCH tokens',
        img: img1,
        background: '#FFF',
        description: `Locked for 365 days, \nthen released at a \nrate of 1% per week`
    },
    {
        id: 2,
        percent: '15%',
        subTitle: `Listings and \nLiquidity`,
        title: '15 Million KWATCH tokens',
        img: img2,
        background: '#FE0096',
        description: `Locked for 365 days, \nthen released at a \nrate of 1% per week`
    },
    {
        id: 3,
        percent: '10%',
        subTitle: `Team \nMembers`,
        title: '10M KWATCH tokens',
        img: img3,
        background: '#F28514',
        description: `Locked for 365 days, \nthen released at a \nrate of 1% per week`
    },
    {
        id: 4,
        percent: '15%',
        subTitle: `Loaning, Betting, \nStaking Rewards, & \nReferral`,
        title: '15 Million KWATCH tokens',
        img: img4,
        background: '#E84349',
        description: `Locked for 365 days, \nthen released at a \nrate of 1% per week`
    },
    {
        id: 5,
        percent: '15%',
        subTitle: `Partnership \nDevelopments & \nAdvisors`,
        title: '15 Million KWATCH tokens',
        img: img5,
        background: '#EA58E9',
        description: `Locked for 365 days, \nthen released at a \nrate of 1% per week`
    },
    {
        id: 6,
        percent: '10%',
        subTitle: `Research & \nDevelopment, \nOperations, Backers`,
        title: '10 Million KWATCH tokens',
        img: img6,
        background: '#88FAC7',
        description: `Locked for 365 days, \nthen released at a \nrate of 1% per week`
    },
    {
        id: 7,
        percent: '10%',
        subTitle: `Ecosystem \nFund`,
        title: '10 Million KWATCH tokens',
        img: img7,
        background: '#1EBFFF',
        description: `Locked for 365 days, \nthen released at a \nrate of 1% per week`
    },


]

export default dataProject;
const dataWallet = [
    {
        id: 1,
        title : 'Reese Witherspoon',
        price: '$6.28k',
        percent: '-4.28%'
    },
    {
        id: 2,
        title : 'Timbaland',
        price: '$660.86',
        percent: '-1.03%'
    },
    {
        id: 3,
        title : 'Post Malone',
        price: '$326.97k',
        percent: '-10.11%'
    },
    {
        id: 4,
        title : 'Jay Z',
        price: '$107.72k',
        percent: '+1.45%'
    },
    {
        id: 5,
        title : 'Stephen Curry',
        price: '$130.69k',
        percent: '-1.94%'
    }
]

export default dataWallet;
import { Stack } from "@mui/material";
import { ReactElement } from "react";
import Register from "src/features/authentication/pages/register";
import Header from "src/components/header";
import { GoogleOAuthProvider} from '@react-oauth/google';

export default function RegisterPage(): ReactElement {
  return (<>
        <Header />
        <Stack height={'100%'} alignItems={'center'}>
            <GoogleOAuthProvider clientId="248586544888-drp7p4u0q017irbdbgr1avgrspf4actu.apps.googleusercontent.com">
                <Register />
            </GoogleOAuthProvider>
        </Stack>
    </>);
}
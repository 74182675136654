import { ReactElement, useState } from 'react';
import "./styles.scss";
import { Stack, Typography, Grid, Box } from '@mui/material';
import { Swiper as SwiperProps, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import { useNavigate } from 'react-router-dom'
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import { colors } from 'src/colors';
import anthonyImg from 'src/assets/images/fake/anthonyImg.png';
import benImg from 'src/assets/images/fake/benImg.png';
import chrisImg from 'src/assets/images/fake/chrisImg.png';
import georgeImg from 'src/assets/images/fake/georgeImg.png';
import layahImg from 'src/assets/images/fake/layahImg.png';
import natalieImg from 'src/assets/images/fake/natalieImg.png';

export default function Similar(props: any): ReactElement {
    const { item } = props;
    const [swiper, setSwiper] = useState<SwiperProps>();
    const [isEnd, setIsEnd] = useState<boolean | undefined>(undefined);
    const navigate = useNavigate();
    return (
        <Stack id='similar' sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: colors.green0E2,
            padding: '24px',
            gap: '40px'
        }}>
            <Stack gap={'4px'}>
              <Typography 
                  fontSize={14}
                  fontWeight={400}
                  color={colors.grayB1}
                  lineHeight={'18px'}
              >
                  {item.nickname}
              </Typography>
              <Typography 
                  fontSize={18}
                  fontWeight={700}
                  color={colors.white}
                  lineHeight={'27px'}
                  className='similar-subtitle'
              >
                  Similar KOL’s
              </Typography>
            </Stack>

            <Grid container gap={'80px'} className='ds-similar-swiper'>
                <Swiper
                    modules={[Scrollbar, A11y]}
                    spaceBetween={25}
                    scrollbar={{ draggable: true }}
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                        },
                        340: {
                            slidesPerView: 2.4,
                        },
                        360: {
                            slidesPerView: 2.4,
                        },
                        480: {
                            slidesPerView: 2.8,
                        },
                        520: {
                            slidesPerView: 3.5,
                        },
                        767: {
                            slidesPerView: 3,
                        },
                        991: {
                            slidesPerView: 6,
                        },
                    }}
                    onSwiper={(swiper: SwiperProps) => setSwiper(swiper)}
                    onReachEnd={(e: any) => {
                        if (!e.isBeginning) {
                            setIsEnd(true);
                        } else {
                            setIsEnd(false);
                        }
                    }}
                    onReachBeginning={() => { setIsEnd(false); }}
                    style={{ position: 'unset' }}
                >
                    {
                        similar.slice(0, 6).map((item: any) => (
                            <SwiperSlide style={{ justifyContent: 'center', display: 'flex' }} key={item.id}>
                                <Stack sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '15px',
                                    cursor: 'pointer',

                                }}
                                    onClick={() => {
                                        navigate(`/top-influencers/${item.id}`);
                                    }}
                                >
                                    <img src={item.img} alt={`img-${item.id}`} width={88} height={88} style={{
                                        borderRadius: '12px',
                                        border: `1px solid ${colors.green}`
                                    }} />
                                    <Stack sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '8px'
                                    }}>
                                        <Typography
                                            fontSize={12}
                                            fontWeight={600}
                                            color={colors.white}
                                            lineHeight={'18px'}
                                        >
                                            {item.name}
                                        </Typography>
                                        <Typography
                                            fontSize={12}
                                            fontWeight={500}
                                            color={colors.grayB1}
                                            lineHeight={'16px'}
                                        >
                                            #1 in YouTube
                                        </Typography>
                                    </Stack>
                                    {/* <Stack sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            gap: '7px'
                                        }}>
                                            {item.icon1}
                                            {item.icon2}
                                            {item.icon3}
                                            <Typography 
                                                fontSize={12}
                                                fontWeight={400}
                                                color={colors.white}
                                                lineHeight={'18px'}
                                            >
                                                {item.info}
                                            </Typography>
                                        </Stack> */}
                                </Stack>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </Grid>
            <Grid container gap={'80px'} className='mb-similar-swiper'>
                    {
                        similar.slice(0, 6).map((item: any) => (
                            <Stack sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '15px',
                                    cursor: 'pointer',

                                }}
                                    onClick={() => {
                                        navigate(`/top-influencers/${item.id}`);
                                    }}
                                >
                                    <img src={item.img} alt={`img-${item.id}`} width={88} height={88} style={{
                                        borderRadius: '12px',
                                        border: `1px solid ${colors.green}`
                                    }} />
                                    <Stack sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '8px'
                                    }}>
                                        <Typography
                                            fontSize={12}
                                            fontWeight={600}
                                            color={colors.white}
                                            lineHeight={'18px'}
                                        >
                                            {item.name}
                                        </Typography>
                                        <Typography
                                            fontSize={12}
                                            fontWeight={500}
                                            color={colors.grayB1}
                                            lineHeight={'16px'}
                                        >
                                            #1 in YouTube
                                        </Typography>
                                    </Stack>
                                    {/* <Stack sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            gap: '7px'
                                        }}>
                                            {item.icon1}
                                            {item.icon2}
                                            {item.icon3}
                                            <Typography 
                                                fontSize={12}
                                                fontWeight={400}
                                                color={colors.white}
                                                lineHeight={'18px'}
                                            >
                                                {item.info}
                                            </Typography>
                                        </Stack> */}
                            </Stack>
                        ))
                    }

            </Grid>
        </Stack>

    );
}

const similar = [
    {
        id: 1,
        name: 'Anthony',
        img: anthonyImg
    },
    {
        id: 2,
        name: 'Layah',
        img: layahImg
    },
    {
        id: 3,
        name: 'Chris',
        img: chrisImg
    },
    {
        id: 4,
        name: 'George',
        img: georgeImg
    },
    {
        id: 5,
        name: 'Natalie',
        img: natalieImg
    },
    {
        id: 6,
        name: 'Ben',
        img: benImg
    }
];
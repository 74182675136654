import { Paper } from "@mui/material";
import { useState } from 'react';
import Table from './Table';
import dataRankings from '../../../../assets/fake-data/data-rankings';
import { useSearchParams } from "react-router-dom";
export interface Filters {
  page: number;
  limit: number;
  search: string;
  status: string;
}
interface IProps {
  isBlockChain?: boolean;
  selectedMenu?: string;
}

export const MyTable: React.FC<IProps> = ({ isBlockChain, selectedMenu }) => {
  const [searchKey, setSearchKey] = useState<string>("");

  const [params, setParams] = useState<any>({
    page: 1,
    limit: 10,
    search: "",
    sort: "",
  });

  return (
    <Paper sx={{ borderRadius: "0px", backgroundColor: "transparent", paddingRight: '16px' }}>
      <Table data={dataRankings} isBlockChain={isBlockChain} selectedMenu={selectedMenu ?? ''} />
    </Paper>
  );
};

import { Stack, Typography } from '@mui/material';
import './styles.scss';
import { ReactElement } from 'react';
interface Props {
  title: string;
  value: number;
}
export default function Card(props: Props): ReactElement {
  return (
    <Stack className='more-item' sx={{
      minWidth: '200px',
      padding: '12px 20px',
      justifyContent: 'start',
      borderRadius: '10px',
      background: '#0E2331'
    }
    }>
      <Typography fontSize={35} fontWeight={700} color='white'>
        {props.value.toLocaleString('en')}
      </Typography>
      <Typography fontSize={15} fontWeight={400} color='white'>
        {props.title}
      </Typography>
    </Stack>
  )
}

import img1 from '../images/logo/img-partner-08.png'
import img2 from '../images/logo/img-partner-09.png'
import img3 from '../images/logo/img-partner-10.png'
import img4 from '../images/logo/img-partner-11.png'
import img5 from '../images/logo/img-partner-12.png'
import img6 from '../images/logo/img-partner-16.png'
import img7 from '../images/logo/img-partner-17.png'
import img8 from '../images/logo/img-partner-19.png'
import img9 from '../images/logo/img-partner-20.png'
import img10 from '../images/logo/img-partner-21.png'

const dataPartner = [
    {
        img: img1,
    },
    {
        img: img2,
    },
    {
        img: img3,
    },
    {
        img: img4,
    },
    {
        img: img5,
    },
    {
        img: img6,
    },
    {
        img: img7,
    },
    {
        img: img8,
    },
    {
        img: img9,
    },
    {
        img: img10,
    },

]

export default dataPartner;
import {
    TableCell, TableHead, TableRow,
    Stack, Typography
} from '@mui/material';
import './styles.scss';
import Sort from '../Sort';
import { colors } from '../../../../colors';

const TableHeader = () => {

    const cellStyle = {
        color: '#B1BBCE',
        fontSize: 14,
        fontWeight: 600,
        alignItems: 'center',
        whiteSpace: 'nowrap',
        justifyContent: 'right'
    };

    return (
        <TableHead>

            <TableRow
                sx={{
                    '& th': {
                        borderBottom: '1px solid #0E3841'
                    }
                }}
            >

                <TableCell
                    className='digit-column-header'
                    sx={{ ...cellStyle, textAlign: 'left', width: "60px" }}
                >
                    <Stack direction='row' alignItems='center' justifyContent={'center'}>
                        <Typography fontSize={12} fontWeight={600} lineHeight={'16px'}
                            color={colors.grayB1} >#</Typography>

                    </Stack>
                </TableCell>

                <TableCell
                    className='name-column-header'
                    align={'center'}
                    sx={{ ...cellStyle, width: "60px" }}
                >
                </TableCell>

                <TableCell
                    align={'left'}
                    sx={cellStyle}
                    width={'551.8px'}
                >

                    <Typography fontSize={12} fontWeight={600} lineHeight={'16px'}
                        color={colors.grayB1} >Name</Typography>

                </TableCell>

                <TableCell align={'center'}>
                    <Stack direction='row'
                        sx={cellStyle}>
                        <Typography fontSize={12} fontWeight={600} lineHeight={'16px'}
                            color={colors.grayB1} >YouTube</Typography> <Sort />
                    </Stack>
                </TableCell>

                <TableCell align={'right'}>
                    <Stack direction='row'
                        sx={cellStyle}>
                        <Typography fontSize={12} fontWeight={600} lineHeight={'16px'}
                            color={colors.white} >Telegram</Typography><Sort />
                    </Stack>
                </TableCell>

                <TableCell align={'right'}>
                    <Stack direction='row'
                        sx={cellStyle}>
                        <Typography fontSize={12} fontWeight={600} lineHeight={'16px'}
                            color={colors.grayB1} >Instagram</Typography><Sort />
                    </Stack>
                </TableCell>

                <TableCell align={'right'}>
                    <Stack direction='row'
                        sx={cellStyle}>
                        <Typography fontSize={12} fontWeight={600} lineHeight={'16px'}
                            color={colors.grayB1} >Twitter</Typography><Sort />
                    </Stack>
                </TableCell>

                <TableCell align={'right'}>
                    <Stack direction='row'
                        sx={cellStyle}>
                        <Typography fontSize={12} fontWeight={600} lineHeight={'16px'}
                            color={colors.grayB1} >Reddit</Typography><Sort />
                    </Stack>
                </TableCell>
                {/* <TableCell
                    align={'right'}
                    sx={{ ...cellStyle}}
                    
                >
                    <Typography fontSize={12} fontWeight={600} lineHeight={'16px'}
                         color={colors.grayB1} >24h UAW</Typography>
                    
                </TableCell> */}
            </TableRow>

        </TableHead >
    );
};

export default TableHeader;
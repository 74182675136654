export function TelegramIcon({
    color,
    width,
    height
}: { color?: string, width?: string, height?: string }) {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg"
            width={width ?? "24"} 
            height={height ?? "24"} 
            viewBox="0 0 24 24"
            fill="none"
        >
            <path 
                fill-rule="evenodd" 
                clip-rule="evenodd"
                d="M21.84 6.05593C21.8836 5.79125 21.8555 5.51971 21.7585 5.26961C21.6615 5.01951 21.4992 4.8 21.2885 4.63394C21.0778 4.46789 20.8264 4.36137 20.5606 4.32549C20.2948 4.28961 20.0242 4.32567 19.777 4.42993L2.677 11.6299C1.485 12.1319 1.424 13.8559 2.677 14.3759C3.91771 14.8934 5.17652 15.3663 6.451 15.7939C7.619 16.1799 8.893 16.5369 9.914 16.6379C10.193 16.9719 10.544 17.2929 10.902 17.5879C11.449 18.0379 12.107 18.5009 12.787 18.9449C14.149 19.8349 15.66 20.6859 16.678 21.2399C17.895 21.8999 19.352 21.1399 19.57 19.8129L21.84 6.05593ZM4.594 12.9919L19.718 6.62493L17.6 19.4649C16.601 18.9219 15.162 18.1089 13.88 17.2709C13.2891 16.8916 12.7187 16.4813 12.171 16.0419C12.0246 15.9224 11.8825 15.7976 11.745 15.6679L15.706 11.7079C15.8936 11.5204 15.9991 11.2661 15.9992 11.0008C15.9993 10.7355 15.894 10.4811 15.7065 10.2934C15.519 10.1058 15.2646 10.0003 14.9994 10.0002C14.7341 10.0001 14.4796 10.1054 14.292 10.2929L9.955 14.6299C9.221 14.5359 8.199 14.2639 7.077 13.8939C6.24169 13.6157 5.41306 13.3149 4.594 12.9919Z"
                fill={color ?? "#B1BBCE"}
            />
        </svg>
    );
}

import { ReactElement } from 'react';
import { Stack, Typography, Box } from '@mui/material';
import { colors } from 'src/colors';
import { CommentUserLogo, CircleIcon } from 'src/assets/icons';

export default function Comment(props: any): ReactElement {
    const { comment } = props;

    return (
       <Stack 
            sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
        }}>
            <Stack alignItems={'center'}>
                <img src={comment.img} width={'44px'} height={'44px'} style={{ borderRadius: '50%' }} alt={`img-${comment.id}`}  />
            </Stack>
            <Stack sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px'
            }}>
                <Box display={'flex'} flexDirection={'row'} gap={'10px'} alignItems={'center'}>
                    <Typography 
                        fontSize={14}
                        fontWeight={700}
                        color={colors.white}
                        lineHeight={'24px'}
                    >
                        {comment.title}
                    </Typography>
                    <Typography 
                        fontSize={12}
                        fontWeight={400}
                        color={colors.grayB1}
                        lineHeight={'18px'}
                    >
                        {comment.date}
                    </Typography>
                    <CircleIcon width={'6px'} height={'6px'} />
                    <Typography 
                        fontSize={12}
                        fontWeight={400}
                        color={colors.grayB1}
                        lineHeight={'18px'}
                    >
                        {comment.time}
                    </Typography>
                </Box>
                <Typography 
                    fontSize={15}
                    fontWeight={400}
                    color={colors.grayB1}
                    lineHeight={'18px'}
                    whiteSpace={'pre-wrap'}
                >
                    {comment.text}
                </Typography>
            </Stack>
    
       </Stack>
    )
}

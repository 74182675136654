import { Stack, Card, CardContent, Typography, Grid } from '@mui/material';
import { colors } from 'src/colors';
import {  CircleIcon } from 'src/assets/icons';

export const Youtube = (props: any) => {
 const { item, className= '' } = props;

  return (<>
        {item.map((_item: any) => ( 
          <Grid className={className} item xs={2} sm={4} md={4}>
            <Card 
                sx={{ 
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    borderRadius: 0,
                }}
            >
                    <Stack sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '7px'
                    }}>
                        <img 
                            src={_item.img} 
                            alt={`img-${_item.id}`}
                        />
                        <Typography
                            fontSize={13}
                            fontWeight={500}
                            color={colors.white}
                            lineHeight={'20px'}
                        >
                            {_item.content}
                        </Typography>
                        <Stack sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap:'10px'
                        }}>
                            <Typography 
                                fontSize={11}
                                fontWeight={300}
                                color={colors.white}
                                lineHeight={'20px'}
                            >
                                {_item.view} Views
                            </Typography>
                            <CircleIcon width={'5px'} height={'5px'} />
                            <Typography 
                                fontSize={11}
                                fontWeight={300}
                                color={colors.white}
                                lineHeight={'20px'}
                                sx={{
                                    opacity: '70%'
                                }}
                            >
                                {_item.lastSeen} ago
                            </Typography>
                        </Stack>
                    </Stack>
                </Card>
            </Grid>
        ))}
        
    </>);
}

export default Youtube;
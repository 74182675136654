import React, { useState, useEffect } from "react";
import menus from "../../pages/menu";
import "./styles.scss";
import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Box,
  Stack,
  Typography,
  Dialog,
  Slide,
  IconButton,
  Divider,
  styled
} from "@mui/material";
import { Container, Button } from "..";
import { colors } from "../../colors";
import SearchField from "../../components/Search/Search";
import { MenuButton } from "../../components/button/MenuButton";
import { LoginMenuButton } from "../../components/button/LoginMenuButton";
import dataMenu from "../../assets/fake-data/data-menu";
import TopHeader from '../../features/topheader';
import Timer from './TimerModal';
import { isLogin, removeToken } from 'src/helpers/utils';
import {
  HeaderLogo, GroupIcon, WhiteSearchIcon, MbLogo, 
  HamburgerIcon, WhiteClose, WhiteArrowDownIcon
} from 'src/assets/icons';
import { TransitionProps } from '@mui/material/transitions';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
interface IProps {
  restricted?: boolean;
}

const Transition = React.forwardRef((
  props: TransitionProps & {
      children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) => {
  return <Slide direction='left' ref={ref} {...props} />
});

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:not(:last-child)': {
    border: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => {
  const {id} = props;
  return <MuiAccordionSummary
    {...props}
    expandIcon={id !== 'Challenges' && <WhiteArrowDownIcon style={{ fontSize: '0.9rem' }} />}
  />
})(({ theme }) => ({
  backgroundColor: '#0c1226',
  flexDirection: 'row',
  padding: 0,
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    margin: '7px 0'
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: '#0c1226',
  padding: '0 0 0 5px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px'
}));

const Header: React.FC<IProps> = ({ restricted }) => {
  const navigate = useNavigate();
  const [scroll, setScroll] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 300);
    });
    return () => {
      setScroll(false);
    };
  }, []);

  const [menuActive, setMenuActive] = useState<boolean>(false);
  const [expanded, setExpanded] = React.useState<string | false>('');
  const [activeIndexItem, setActiveIndexItem] = useState(null);

  const handleMenuActive = () => {
    setMenuActive(!menuActive);
  };

  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickLogin = (event: React.MouseEvent<HTMLElement>) => {
    navigate('/auth'); 
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
  };

  return (
        <>
          <TopHeader className='top-header' />
          <AppBar
            position="sticky"
            sx={{
              backgroundColor: colors.green00,
              boxShadow: "none",
              height: '72px'
            }}
            id='ds-menu-header'
          >
            <Container>
              <Toolbar style={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingTop: '15px'
                }}
              >
                <Box
                  gap={"35px"}
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: 'center'
                  }}
                >
                  <Link className='header-section-left' to="/">
                    <HeaderLogo />
                  </Link>

                  <Stack gap={"15px"} className='header-section-right' sx={{
                    display: "flex",
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}>
                    <GroupIcon />
                    <SearchField onSearch={() => console.log("")} />
                  </Stack>

                </Box>

                <Box
                    gap={"5px"}
                    sx={{
                      display: "flex",
                      flexGrow: 1,
                    }}
                    className='header-tabs-menu'
                >
                    {dataMenu.slice(0, 5).map((item) => (
                      <MenuButton items={item} key={item.id} />
                    ))}
                </Box>

                <Box
                  gap={"16px"}
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: 'center'
                  }}
                  className='btn-items'
                >
                  <LoginMenuButton />
                  {/* <Button
                    variant={"outlined"}
                    LabelColor={colors.white}
                    borderColor={colors.white}
                    borderWidth="1px"
                    sx={{ width: 117 }}
                    onClick={() => {
                      if(isLogin()) {
                        removeToken();
                        navigate('/auth');
                      } else {
                        navigate('/auth');
                      }
                      
                    }}
                  >
                    <Typography
                      fontSize={16}
                      fontWeight={600}
                      color={colors.white}
                      lineHeight={"24px"}
                      textTransform={'uppercase'}
                    >
                      {isLogin() ? 'Account' : 'Login'}
                    </Typography>
                  </Button> */}
                  <Button
                    variant={"contained"}
                    LabelColor={colors.white}
                    borderColor={colors.white}
                    backgroundColor={colors.white}
                    bgHover={colors.white}
                    sx={{ width: 117 }}
                    onClick={() => console.log("")}
                  >
                    <Typography
                      fontSize={16}
                      fontWeight={600}
                      color={colors.green00}
                      lineHeight={"24px"}
                      textTransform={'uppercase'}
                    >
                      Connect
                    </Typography>
                  </Button>
                </Box>

              </Toolbar>
            </Container>
          </AppBar>

          <AppBar
            position="sticky"
            sx={{
              backgroundColor: colors.green00,
              boxShadow: "none",
              height: '50px'
            }}
            id='mb-menu-header'
          >
            <Container>
              <Toolbar style={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  height: '50px',
                  minHeight: '50px'
                }}
              >
                <Box
                  gap={"35px"}
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: 'space-between'
                  }}
                >
                  <Link className='header-section-left' to="/">
                    <MbLogo className="logo" />
                  </Link>

                  <Stack gap={"15px"} className='header-section-right' sx={{
                    display: "flex",
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}>
                    <WhiteSearchIcon />
                    <HamburgerIcon onClick={(e: any) => handleClick(e)} />
                  </Stack>

                </Box>

              </Toolbar>
            </Container>
            <Dialog id={id} open={open} fullScreen fullWidth TransitionComponent={Transition} >
              <AppBar position='static' sx={{background: colors.green, color: colors.white, padding: '10px 0 10px 0'}}>
                <Toolbar sx={{ gap: '10px', minHeight: 0 }}>
                    <IconButton 
                        onClick={handleClose}
                    >
                        <WhiteClose />           
                    </IconButton> 
                    
                    <Typography
                        fontSize={14}
                        fontWeight={600}
                        lineHeight={'24px'}
                        color={colors.white}
                        textTransform={'uppercase'}
                    >
                        Menu
                    </Typography>             
                </Toolbar>
              </AppBar>
              <Box 
                  width='100%'
                  height='100%'
                  p={'24px'}
                  bgcolor={colors.default}
                  flexDirection='column'
                  display='flex'
              >
                <Stack display='flex' flexDirection='column' gap='15px'>
                    <Button
                    variant={"outlined"}
                    LabelColor={colors.white}
                    borderColor={colors.white}
                    borderWidth="1px"
                    sx={{
                        cursor: 'pointer',
                        width: '100%' 
                    }}
                    onClick={handleClickLogin}
                >
                    <Typography
                        fontSize={16}
                        fontWeight={600}
                        color={colors.white}
                        lineHeight={"24px"}
                        textTransform={'uppercase'}
                    >
                        {isLogin() ? 'Account' : 'Login'}
                    </Typography>
                    </Button>
                    <Button
                      variant={"contained"}
                      LabelColor={colors.white}
                      borderColor={colors.white}
                      backgroundColor={colors.white}
                      bgHover={colors.white}
                      sx={{ width: '100%'}}
                      onClick={() => console.log("")}
                    >
                      <Typography
                        fontSize={16}
                        fontWeight={600}
                        color={colors.green00}
                        lineHeight={"24px"}
                        textTransform={'uppercase'}
                      >
                        Connect
                      </Typography>
                    </Button>
                </Stack>
                <Divider 
                    orientation={"vertical"}
                    sx={{
                        height: "1px",
                        borderWidth: "1px",
                        borderColor: colors.white,
                        marginTop: '20px',
                        marginBottom: '5px'
                    }} 
                />
                <Stack sx={{ overflowY: 'auto' }}>
                    <Stack className="dialog-menu-container">
                      {dataMenu.map((item: any, idx: any) => (
                        <Accordion expanded={expanded === String(item.id)} onChange={handleChange(String(item.id))}>
                          <AccordionSummary aria-controls="kolwatch" id={item.title} key={item.id} onClick={() => setActiveIndex(idx)}>
                              <Typography
                                  color={activeIndex === idx ? colors.green : colors.white }
                                  sx={{ cursor: 'pointer' }}
                                  fontSize={18}
                                  fontWeight={600}
                                  lineHeight={'24px'}
                                  textTransform={'uppercase'}
                                  className="title" 
                              >
                                {item.title}
                              </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                          {item.list.map((_item: any, index: any) => {
                            const _url = String(_item.url);
                              return (
                                <a href={_url !== 'dialog' ? `${_item.url}` : undefined}>
                                  <Stack display={'flex'} flexDirection={'column'} gap={'10px'}>
                                      <Typography 
                                        fontSize={16}
                                        fontWeight={500}
                                        lineHeight={'24px'} 
                                        color={activeIndexItem === index ? colors.green : colors.grayB1 }
                                        onClick={() => setActiveIndexItem(index)}
                                      >
                                        {_item.text}
                                      </Typography>
                                  </Stack>
                                </a>
                              )
                          })}
                          </AccordionDetails>
                        </Accordion>
                    ))}
                    </Stack>
                </Stack>
              </Box>
            </Dialog>
          </AppBar>
        </>
  );
};

export default Header;

import { Stack, Typography } from '@mui/material';
import '../styles.scss';
import { colors } from 'src/colors';
import Card from './Card';
import { ChatIcon, GreenLikeIcon } from 'src/assets/icons';

export const Feed = () => {

  return (
    <Stack className='feed-box' sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors.green0E2,
        justifyContent: 'center',
        padding: '24px',
        gap: '25px'
    }}>
        <Card />
        <Stack flexDirection={'row'} gap={'35px'}>
            <Stack flexDirection={'row'} alignItems={'center'} gap={'11px'}>
                <ChatIcon />
                <Typography 
                    fontSize={14}
                    fontWeight={400}
                    color={colors.grayB1}
                    lineHeight={'18px'}
                >
                    0
                </Typography>
            </Stack>
            <Stack flexDirection={'row'} alignItems={'center'} gap={'11px'}>
                <GreenLikeIcon />
                <Typography 
                    fontSize={14}
                    fontWeight={400}
                    color={colors.grayB1}
                    lineHeight={'18px'}
                >
                    0
                </Typography>
            </Stack>
        </Stack>
    </Stack>
  );
}

export default Feed;
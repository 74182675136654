
// import {
//     Launchpads,
//     ColorInstagram,
//     ColorTelegram,
//     TelegramChannel,
//     ColorfullTwitterIcon,
//     ColorfullRedditIcon,
//     FakeGurusList,
//     HighRisk,
//     RedFlag,
//     TopAdvertisers,
//     YoutuberImg,
//     InstagramImg
// } from 'src/assets/icons';
import youtuberImg from 'src/assets/images/common/youtuberImg.png';
import colorInstagram from 'src/assets/images/common/colorInstagram.png';
import colorTelegram from 'src/assets/images/common/colorTelegram.png';
import telegramChannel from 'src/assets/images/common/telegramChannel.png';
import colorfullTwitterIcon from 'src/assets/images/common/colorfullTwitter.png';
import colorfullRedditIcon from 'src/assets/images/common/colorfullReddit.png';
import launchpads from 'src/assets/images/common/Launchpads.png';
import topAdvertisers from 'src/assets/images/common/topAdvertisers.png';
import redFlag from 'src/assets/images/common/redFlag.png';
import fakeGurusList from 'src/assets/images/common/fakeGurusList.png';
import highRisk from 'src/assets/images/common/highRisk.png';

export const dataInfluence = [
    {
        id: 1,
        title: 'Youtubers',
        img: youtuberImg,
        text: '',
        url: '/top-influencers?sort=youtube',
    },
    {
        id: 2,
        title: 'Instagram',
        img: colorInstagram,
        text: '',
        url: '/top-influencers?sort=instagram',
    },
    {
        id: 3,
        title: 'Telegram',
        img: colorTelegram,
        text: '',
        url: '/top-influencers?sort=telegram',
    },
    {
        id: 4,
        title: 'Telegram channels',
        img: telegramChannel,
        text: '',
        url: '/top-influencers',
    },
    {
        id: 5,
        title: 'Twitter',
        img: colorfullTwitterIcon,
        text: '',
        url: '/top-influencers?sort=twitter',
    },
    {
        id: 6,
        title: 'Reddit',
        img: colorfullRedditIcon,
        text: '',
        url: '/top-influencers?sort=twitch',
    },
    {
        id: 7,
        title: 'Launchpads',
        img: launchpads,
        text: 'Live',
        url: 'https://t.me/+_sLkb5Ej0WpmZmJk',
    },
    {
        id: 8,
        title: 'Top advertisers',
        img: topAdvertisers,
        text: '',
        url: 'https://t.me/+_sLkb5Ej0WpmZmJk',
    },
    {
        id: 9,
        title: 'Red flag individuals',
        img: redFlag,
        text: '',
        url: 'https://t.me/+_sLkb5Ej0WpmZmJk',
    },
    {
        id: 10,
        title: 'Fake gurus list',
        img: fakeGurusList,
        text: 'Hot',
        url: 'https://t.me/+_sLkb5Ej0WpmZmJk',
    },
    {
        id: 11,
        title: 'High risk',
        img: highRisk,
        text: '',
        url: 'https://t.me/+_sLkb5Ej0WpmZmJk',
    }

]
import { REGISTER_URL } from 'src/config/urls';

const dataMenu = [
    {
        id: 1,
        title: 'Rankings',
        list: [
            { text: 'Explorer', url: '/top-influencers' },
            { text: 'All rankings', url: '/top-influencers?sort=All rankings' },
            { text: 'Youtubers', url: '/top-influencers?sort=youtube' },
            { text: 'Instagram', url: '/top-influencers?sort=instagram' },
            { text: 'Telegram', url: '/top-influencers?sort=telegram' },
            { text: 'Telegram channels', url: '/top-influencers' },
            { text: 'Launchpads', url: 'dialog' },
            { text: 'Twitter', url: '/top-influencers?sort=twitter' },
            { text: 'Reddit', url: '/top-influencers?sort=twitch' },
            { text: 'Top advertisers', url: 'dialog' },
            { text: 'Fake Gurus List', url: 'dialog' },
            { text: 'RED FLAG Individuals', url: 'dialog' },
            { text: 'High Risk', url: 'dialog' }
        ]
    },
    {
        id: 2,
        title: 'Contribute',
        list: [
            { text: 'Submit a KOL application', url: `${REGISTER_URL}` }
        ]
    },
    {
        id: 3,
        title: 'Services',
        list: [
            { text: 'Referral program', url: 'https://t.me/+_sLkb5Ej0WpmZmJk' },
            { text: 'Advertising', url: 'https://t.me/+_sLkb5Ej0WpmZmJk' }
        ]
    },
    {
        id: 4,
        title: 'KW',
        list: [
            { text: 'Pro membership', url: 'https://t.me/+_sLkb5Ej0WpmZmJk' },
            { text: 'Whitepaper', url: 'https://kolwatch.io/watch-paper' },
            { text: 'Telegram community', url: 'https://t.me/+_sLkb5Ej0WpmZmJk' },
            { text: 'Contact', url: 'https://t.me/+_sLkb5Ej0WpmZmJk' }
        ]
    },
    {
        id: 5,
        title: 'Challenges',
        url: '',
        list: []
    }
]

export default dataMenu;
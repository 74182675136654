import React from "react";
import { Stack, Typography } from '@mui/material';
import { colors } from 'src/colors';
import { Input, Button } from 'src/components';
import { LeftArrowIcon, ForgotPasswordIcon } from 'src/assets/icons';
import { useNavigate } from 'react-router-dom';
import { LOGIN_VERIFICATION_CODE_URL, _RESET_TOKEN } from 'src/config/urls';
import { useFormik } from "formik";
import { initialValues, validateSchema } from "src/formValidations/forgotPassword";
import { useForgetPasswordMutation } from "src/api/auth";
import { ILogin } from "src/models/authApi";
import { toast } from "react-toastify";
import { setForgotUser } from "src/slices/mainSlice";
import { useAppDispatch } from "src/hooks/ReduxHooks";

const ForgotPassword: React.FC<{}> = () => {
    const [forgotPassword] = useForgetPasswordMutation();
    const navigate = useNavigate();
    const Dispatch = useAppDispatch();
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validateSchema,
        onSubmit: (values) => {
            console.log(values);
            forgotPassword(values)
                .unwrap()
                .then((res: ILogin.ForgotPassword) => {
                    toast.success(res.message);
                    // localStorage.setItem(_RESET_TOKEN, res.data._token)
                    Dispatch(setForgotUser({ email: values.email }));

                    navigate(LOGIN_VERIFICATION_CODE_URL)
                })
                .catch((err) => {
                    console.log('err:', err);
                    toast.error(err.message);
                });
        }
    });
    const { errors, getFieldProps } = formik;
    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: colors.green0E2,
                justifyContent: 'flex-start',
                padding: '8px 24px 24px 24px',
                gap: '16px',
                height: '780px',
                marginY: '25px',
                marginRight: '22px'
            }}>
                <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    gap={'12px'}
                    marginLeft={'13px'}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => navigate(-1)}
                >
                    <LeftArrowIcon />
                    <Typography
                        fontSize={16}
                        fontWeight={600}
                        lineHeight={'60px'}
                        color={colors.white}
                    >
                        Back
                    </Typography>
                </Stack>
                {/* <ForgotPasswordIcon /> */}
                <Stack gap={'.5px'}>
                    <Typography
                        fontSize={14}
                        fontWeight={400}
                        color={colors.grayB1}
                        lineHeight={'21px'}
                    >
                        Forgot password
                    </Typography>
                    <Typography
                        fontSize={18}
                        fontWeight={700}
                        color={colors.white}
                        lineHeight={'27px'}
                        whiteSpace={'pre-wrap'}
                    >
                        {`Enter your email address or phone umber associated with your\naccount to change your password`}
                    </Typography>
                </Stack>
                <Input
                    label="Email Address"
                    id="email"


                    placeholder=""
                    style={{
                        marginBottom: '5px'
                    }}
                    {...getFieldProps('email')}

                />
                <Stack display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
                    <Button
                        variant={"outlined"}
                        LabelColor={colors.black}
                        borderColor={colors.green22}
                        backgroundColor={colors.green}
                        bgHover={colors.green}
                        sx={{ height: 52, width: '265px' }}
                        type='submit'
                        disabled={!formik.isValid}
                    >
                        <Typography
                            fontSize={16}
                            fontWeight={700}
                            lineHeight={'16px'}
                            color={colors.white}
                        >
                            Next
                        </Typography>
                    </Button>
                </Stack>
            </Stack>
        </form>
    );
};

export default ForgotPassword;
const dataTag = [
    {
        id: 1,
        text: 'DeFi'
    },
    {
        id: 2,
        text: 'Telegram Channels'
    },
    {
        id: 3,
        text: 'Youtube'
    },
    {
        id: 4,
        text: 'Instagram'
    },
    {
        id: 5,
        text: 'Twitter'
    },
    {
        id: 6,
        text: 'Reddit'
    }
]

export default dataTag;
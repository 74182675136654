const dataWork = [
    {
        id: 1,
        numb: '01',
        title: 'Staking Pools',
        text: `In staking pools, users can lock up their tokens in a pool associated with a specific KOL. This represents a vote of confidence in the KOL's calls and investment choices. The more tokens staked in a particular pool, the greater the impact on that KOL's profits. Users have the potential to earn profits based on the performance of the KOL's investment decisions.`,
    },
    {
        id: 2,
        numb: '02',
        title: `Betting Pools`,
        text: `Betting pools enable users to wager on the reputation and actions of KOLs. For instance, users can place bets on whether a KOL will promote a potentially fraudulent project in a given month. If the KOL indeed promotes the project, users who placed bets in favor of this outcome will receive profits based on the odds set by the pool. Conversely, those who bet against the KOL's actions will lose the tokens they staked.`
    },
    {
        id: 3,
        numb: '03',
        title: `Performance Pools`,
        text: `Performance pools allow users to invest in the success of a KOL's overall performance. These pools are designed to track and reward KOLs based on their reputation, content quality, and overall influence within the ecosystem. Users can stake their tokens in these pools and earn profits based on the KOL's performance metrics.`
    },

]
export default dataWork;
import * as React from 'react';
import "./styles.scss";
import Tab, { TabProps } from '@mui/material/Tab';
import { Stack, styled, TabsProps, Typography, Tabs } from '@mui/material';
import {
  AllRandIcon,
  InstagramIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  YouTubeIcon,
  TableBottomArrow, Calendar
} from 'src/assets/icons';

import { useParams, useSearchParams } from "react-router-dom";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { colors } from '../../colors';

const StyledTabs = styled((props: TabsProps) => (
  <Tabs
    orientation='horizontal'
    {...props}
    TabIndicatorProps={{ style: { display: 'none' } }}
  />
))({
  // width: '220px',
  paddingRight: "16px",
  fontFamily: "Chakra Petch",
  fontSize: '16px',
  fontWeight: 700,
  '& .Mui-selected': {
    color: 'white',
    backgroundColor: '#123142',
  },
});

const StyledTab = styled((props: TabProps) => (
  <Tab
    disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  justifyContent: 'center',
  padding: '7px 12px',
  color: '#B1BBCE',
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '20px',
  '&.Mui-selected': {
    color: '#fff',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));

export default function Categories(): React.ReactElement {
  const [searchParams, setSearchParams] = useSearchParams();

  const [value, setValue] = React.useState<string>(searchParams.get('sort') ?? "");
  const { id } = useParams();


  React.useEffect(() => {
    setValue(searchParams.get('sort') ?? "");
  }, [searchParams.get('sort')])

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSearchParams({ sort: newValue });
    setValue(newValue);
  };

  return (<Stack id='categories' direction='row' justifyContent={'space-between'} paddingY={'16px'} width={'100%'} >
    <Stack direction='row' width={'100%'}>
      <StyledTabs
        value={value}
        onChange={handleChange}
        aria-label="styled tabs example"
        className='tabs-categories'
      >
        <StyledTab className='btn-category' label="All categories" value={'All rankings'} />
        <StyledTab className='btn-category' label="YouTube" value={'youtube'} />
        <StyledTab className='btn-category' label="Telegram" value={'telegram'} />
        <StyledTab className='btn-category' label="Instagram" value={'instagram'} />
        <StyledTab className='btn-category' label="Twitter" value={'twitter'} />
        <StyledTab className='btn-category' label="Reddit" value={'twitch'} />
      </StyledTabs>
    </Stack>
    <Stack>

      <Button
        id="basic-button"
        className='btn-date'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant='contained'
        startIcon={<Calendar />}
        endIcon={<TableBottomArrow width={10} height={6} />}
        sx={{ width: '101.7px', background: "#123142" }}
      >
        <Typography
          fontSize={14}
          fontWeight={700}
          lineHeight={'20px'}
          color={colors.grayB1}
        >
          24h
        </Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          '& .MuiPaper-root': {
            background: "#123142",
            borderRadius: 0,
          }
        }}
      >
        <MenuItem sx={{ color: 'white' }} onClick={handleClose}>
          <Typography
            fontSize={14}
            fontWeight={700}
            lineHeight={'20px'}
            color={colors.white}
          >
            12h
          </Typography>
        </MenuItem>
        <MenuItem sx={{ color: 'white' }} onClick={handleClose}>
          <Typography
            fontSize={14}
            fontWeight={700}
            lineHeight={'20px'}
            color={colors.grayB1}
          >
            2h
          </Typography>
        </MenuItem>
        <MenuItem sx={{ color: 'white' }} onClick={handleClose}>
          <Typography
            fontSize={14}
            fontWeight={700}
            lineHeight={'20px'}
            color={colors.grayB1}
          >
            8h
          </Typography>
        </MenuItem>
      </Menu>
    </Stack>
  </Stack>
  )
}

import { Paper } from "@mui/material";
import Table from './Table';
import { colors } from 'src/colors';

export const AssetsTable = () => {

  return (
    <Paper sx={{ borderRadius: "0px", backgroundColor: "transparent", paddingRight: '16px', boxShadow: 'none', borderTop: `1px solid ${colors.default}` }}>
      <Table data={data} />
    </Paper>
  );
};

const data = [
  {
    id: 1,
    avatar: "",
    name: "PsyFi",
    extraName: "PSY",
    amount: 3652164862410,
    change: 0.18,
    Price: 0.002293,
    total: 8373257490.1,
    time: 86502581.11,
    timePercent: -1.02
  },
  // {
  //   id: 2,
  //   avatar: "",
  //   name: "Ethereum",
  //   extraName: "ETH",
  //   amount: 150000,
  //   change: 1.52,
  //   Price: 1342.94,
  //   total: 201441425.45,
  //   time: 187442223.85,
  //   timePercent: 1338.95
  // },
  // {
  //   id: 3,
  //   avatar: "",
  //   name: "iNFTspace",
  //   extraName: "INS",
  //   amount: 500000,
  //   change: 3.99,
  //   Price: 0.00005477,
  //   total: 27.38,
  //   time: 3.31,
  //   timePercent: 13.76
  // },
  // {
  //   id: 4,
  //   avatar: "",
  //   name: "HEX (PulseChain)",
  //   extraName: "HEX",
  //   amount: 1000,
  //   change: 11.49,
  //   Price: 0.00893,
  //   total: 8.93,
  //   time: 2.24,
  //   timePercent: 33.49
  // },
  // {
  //   id: 5,
  //   avatar: "",
  //   name: "PulseChain",
  //   extraName: "PLS",
  //   amount: 150000,
  //   change: 7.69,
  //   Price: 0.00004399,
  //   total: 6.6,
  //   time: 1.06,
  //   timePercent: 19.15
  // },
  // {
  //   id: 6,
  //   avatar: "",
  //   name: "HEX",
  //   extraName: "HEX",
  //   amount: 1000,
  //   change: 34.48,
  //   Price: 0.006427,
  //   total: 6.43,
  //   time: 6.06,
  //   timePercent: 1630.12
  // },
  // {
  //   id: 7,
  //   avatar: "",
  //   name: "Hillstone Finance",
  //   extraName: "HSF",
  //   amount: 1,
  //   change: 8.73,
  //   Price: 0.41619,
  //   total: 0.41619,
  //   time: 2.51,
  //   timePercent: -85.79
  // },
  // {
  //   id: 8,
  //   avatar: "",
  //   name: "Geojam",
  //   extraName: "JAM",
  //   amount: 1111,
  //   change: 0.99,
  //   Price: 0.0003098,
  //   total: 0.344135,
  //   time: 0.189138,
  //   timePercent: -35.47
  // },
  // {
  //   id: 9,
  //   avatar: "",
  //   name: "Alchemy Pay",
  //   extraName: "ACH",
  //   amount: 15,
  //   change: 2.66,
  //   Price: 0.011213,
  //   total: 0.168197,
  //   time: 0.501209,
  //   timePercent: -74.87
  // },
  // {
  //   id: 10,
  //   avatar: "",
  //   name: "WATER",
  //   extraName: "WATER",
  //   amount: 10,
  //   change: 0.82,
  //   Price: 0.005544,
  //   total: 0.055439,
  //   time: 0.002834,
  //   timePercent: 5.39
  // },

];

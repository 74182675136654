import HomeOne from "./HomeOne";
import HomeTwo from "./HomeTwo";
import HomeThree from "./HomeThree";
import AboutOne from "./AboutOne";
import ComingSoon from "./ComingSoon";
import Community from "./Community";
import FAQ from "./FAQ";
import Page404 from "./404";
import RoadMapOne from "./RoadMapOne";
import RoadMapTwo from "./RoadMapTwo";
import NftItem from "./NftItem";
import NftItemDetails from "./NftItemDetails";
import Blog from "./Blog";
import BlogDetails from "./BlogDetails";
import Contact from "./Contact";
import TeamDetail from "./TeamDetail";
import WatchPaper from "./WatchPaper";
import PrivacyPage from "./Privacy";
import TermsAndCondition from "./TermsAndCondition";
import Cookies from "./Cookies";
import TopInfluencer from "./TopInfluencer";
import Home from "./Home";
import Portfolio from "./Portfolio";
import Login from "./login";
import Register from "./register";
import Submit from "./submit/Submit";
import ForgotPassword from "./login/ForgotPassword";
import VerificationCode from "./login/VerificationCode";
import ChangePassword from "./login/ChangePassword";
import CreatePassword from "./login/CreatePassword";
import CreateAccount from "./register/CreateAccount";
import RegisterVerificationCode from "./register/VerificationCode";
import RegisterCreatePassword from "./register/CreatePassword";
import RegisterChoosePicture from "./register/ChoosePicture";
import Subscribe from "./submit/Subscribe";
import SubmitKol from './submit/SubmitKol'
import LatestNewsDetails from './LatestNewsDetails';
const routes = [
  { path: "/", component: <Home /> },
  { path: "/home-v2", component: <HomeTwo /> },
  { path: "/home-v3", component: <HomeThree /> },
  { path: "/about-v1", component: <AboutOne /> },
  // { path: '/about-v2', component: <AboutTwo /> },
  { path: "/coming-soon", component: <ComingSoon /> },
  { path: "/community", component: <Community /> },
  { path: "/faq", component: <FAQ /> },
  { path: "/404", component: <Page404 /> },
  { path: "/road-map-v1", component: <RoadMapOne /> },
  { path: "/road-map-v2", component: <RoadMapTwo /> },
  { path: "/nft-item", component: <NftItem /> },
  { path: "/nft-item-details", component: <NftItemDetails /> },
  { path: "/blog", component: <Blog /> },
  { path: "/blog-details", component: <BlogDetails /> },
  { path: "/contact", component: <Contact /> },
  { path: "/team-details", component: <TeamDetail /> },
  { path: "/watch-paper", component: <WatchPaper /> },
  { path: "/condition", component: <TermsAndCondition /> },
  { path: "/privacy", component: <PrivacyPage /> },
  { path: "/cookies", component: <Cookies /> },
  { path: "/top-influencers", component: <TopInfluencer /> },
  { path: "/top-influencers/:id", component: <TopInfluencer /> },
  { path: "/portfolio", component: <Portfolio /> },
  { path: "/auth", component: <Login /> },
  { path: "/auth/create-password", component: <CreatePassword /> },
  { path: "/auth/register", component: <Register /> },
  { path: "/auth/submit", component: <Submit /> },
  { path: "/auth/forgot-password", component: <ForgotPassword /> },
  { path: "/auth/verification-code", component: <VerificationCode /> },
  { path: "/auth/confirm-password", component: <ChangePassword /> },
  { path: "/auth/register/create-account", component: <CreateAccount /> },
  { path: "/auth/register/verification-code", component: <RegisterVerificationCode /> },
  { path: "/auth/register/create-password", component: <RegisterCreatePassword /> },
  { path: "/auth/register/choose-picture", component: <RegisterChoosePicture /> },
  { path: "/auth/submit/subscribe", component: <Subscribe /> },
  { path: "/auth/submit/kol", component: <SubmitKol /> },
  { path: 'latest-news', component: <LatestNewsDetails /> }

];


export default routes;

import { useState, FC } from 'react';
import { Stack, Box, InputBase } from '@mui/material';
import { colors } from '../../colors';
import { SearchIcon } from 'src/assets/icons';

const SearchField: FC<SearchFieldProps> = ({ onSearch }) => {

    const [value, setValue] = useState('');

    const handleSubmit = (event: any) => {

        event.preventDefault();
        onSearch(value);
    };

    return (
        <Box
            width={320}
            height={45}
            display={'flex'}
            alignItems={'center'}
            component={'form'}
            onSubmit={handleSubmit}
            paddingX={'15px'}
            sx={{
                border: `2px solid ${colors.green}`,
                '& .MuiInputBase-root' : {
                    height: 45
                },
            }}

        >
            <Stack sx={{ width: '5%' }}>
                <SearchIcon />
            </Stack>
            <InputBase
                value={value}
                onChange={(e) => setValue(e.target.value)}
                className='home-search'
                style={{
                    color: 'white',
                    
                }}
                placeholder='Search...'
                sx={{
                    width: '95%',
                    '& input': {
                        fontSize: 14,   
                        fontWeight: 400, 
                        color: '#fff !important',               
                        '&::placeholder': {
                            color: '#fff !important',
                            opacity: 1,
                            fontSize: 14,  
                            lineHeight: '16.41px', 
                            fontWeight: 400,  
                        }
                    },
                    '& .MuiInputBase-input' : {
                        padding:'0 15px 0 13px',
                        border: 'none',
                        height: 'auto'
                    }
                }}
            />
        </Box>
    );
};

export interface SearchFieldProps {
    onSearch: (val: string) => void;
}

export default SearchField;
import { News1, News2, News3, News4 } from 'src/assets/icons';

const dataLatestNews = [
    {
        id: 1,
        img : <News1 width={172.39} height={99} />,
        lastSeen: 'yesterday',
        content: 'Web3 Dates for NFT Mints, Game Tests and DeFi Upgrades',
    },
    {
        id: 2,
        img :  <News2 width={172.39} height={99} />,
        lastSeen: '2 days ago',
        content: 'DappRadar Introduces Custom Alerts',
    },
    {
        id: 3,
        img :  <News3 width={172.39} height={99} />,
        lastSeen: 'last week',
        content: 'DappRadar Now Tracks Dapps on zkSync Era',
    },
    {
        id: 4,
        img :  <News4 width={172.39} height={99} />,
        lastSeen: '2 weeks ago',
        content: 'Top 10 Best Upcoming Crypto Airdrops',
    },
    // {
    //     id: 5,
    //     img : news1,
    //     lastSeen: 'yesterday',
    //     content: 'Web3 Dates for NFT Mints, Game Tests and DeFi Upgrades',
    // }
]

export default dataLatestNews;
import * as React from 'react';
import './styles.scss';
import Tabs from '@mui/material/Tabs';
import Tab, { TabProps } from '@mui/material/Tab';
import { Stack, styled, TabsProps } from '@mui/material';

import { useParams } from "react-router-dom";


const StyledTabs = styled((props: TabsProps) => (
  <Tabs
    orientation='horizontal'
    {...props}
    TabIndicatorProps={{ style: { display: 'none' } }}
  />
))({
  // width: '220px',
  paddingRight: "16px",
  fontFamily: "Chakra Petch",
  fontSize: '16px',
  fontWeight: 700,
  '& .Mui-selected': {
    color: 'white',
    backgroundColor: 'transparent'

  },
});

const StyledTab = styled((props: TabProps) => (
  <Tab
    disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  justifyContent: 'center',
  padding: '7px 12px',
  color: '#8294B0',
  fontSize: 18,
  fontWeight: 700,
  '&.Mui-selected': {
    color: '#fff',
    backgroundColor: 'transparent'
  },
  '&.Mui-focusVisible': {

    backgroundColor: 'transparent'
  },
}));

export default function Categories(): React.ReactElement {
  const [value, setValue] = React.useState(0);
  const { id } = useParams();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Stack id='categories' direction='row' paddingY={'16px'} width={'100%'}>

      <StyledTabs
        value={value}
        onChange={handleChange}
        aria-label="styled tabs example"
        sx={{
          '& .MuiTabs-flexContainer': {
            gap: '18px !important'
          }
        }}
      >
        <StyledTab label="Top Dapps" />
        {/* <StyledTab label="Top NFT Collections" />
        <StyledTab label="Top DeFi Projects" />
        <StyledTab label="Top Tokens" /> */}

      </StyledTabs>
    </Stack>
  )
}

import {
    TableCell, TableHead, TableRow,
    Stack, Typography
} from '@mui/material';
import { colors } from 'src/colors';

const TableHeader = () => {
    
    const cellStyle = {
        color: '#B1BBCE',
        fontSize: 14,
        fontWeight: 600,
        alignItems: 'center',
        whiteSpace: 'nowrap',
        justifyContent: 'right'
    };

    return (
        <TableHead>
            <TableRow
                sx={{
                    '& th': {
                        borderBottom: 'transparent',
                        padding: '16px 16px 20px 16px'
                    }
                }}
            >
                <TableCell
                    align={'left'}
                    sx={cellStyle}
                    width={'296px'}
                >
                    <Typography fontSize={14} fontWeight={400} lineHeight={'20px'} color={colors.green1B}>Type</Typography>
                </TableCell>

                <TableCell sx={cellStyle} align={'left'} width={'500px'}>
                    <Stack direction='row'>
                         <Typography fontSize={14} fontWeight={400} lineHeight={'20px'} color={colors.green1B} >Asset(s)</Typography>
                    </Stack>
                </TableCell>

                <TableCell sx={cellStyle} align={'left'}>
                    <Stack direction='row' paddingLeft={'26px'}>
                        <Typography fontSize={14} fontWeight={400} lineHeight={'20px'} color={colors.green1B} >Portfolio</Typography>
                    </Stack>
                </TableCell>
                <TableCell align={'right'}></TableCell>
            </TableRow>
        </TableHead >
    );
};

export default TableHeader;
import { ReactElement, useState } from 'react';
import { Stack, Typography, Box } from '@mui/material';
import { colors } from 'src/colors';
import { UserLogo, CircleIcon, MoreIcon } from 'src/assets/icons';
import { Button } from 'src/components';
import { StyledMoreMenu } from './style';
import { createMarkup } from 'src/helpers/utils';

export default function Card(props: any): ReactElement {
    const { item, status } = props;
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'more-popover' : undefined;

    return (<>
        <Stack sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '25px'
            }}>
                <Stack sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Stack flexDirection={'row'} alignItems={'center'} gap={'15px'}>
                        <Stack flexDirection={'row'} alignItems={'center'} gap={'4px'}>
                            {/* <Stack 
                                width={'44px'}
                                height={'44px'}
                                borderRadius={'50%'}
                            >
                                {item.img}
                            </Stack> */}
                            <img 
                                src={item.img} 
                                alt={`img-${item.id}`}
                                width={44}
                                height={44}
                                style={{ borderRadius: '50%' }}
                            />
                            <Typography 
                                fontSize={18}
                                fontWeight={700}
                                color={colors.white}
                                lineHeight={'27px'}
                            >
                               {item.fullname}
                            </Typography>
                        </Stack>
                        
                        <CircleIcon width={'6px'} height={'6px'} />
                        <Typography 
                            fontSize={14}
                            fontWeight={400}
                            color={colors.grayB1}
                            lineHeight={'18px'}
                        >
                            {item.commentDate}
                        </Typography>
                        <CircleIcon width={'6px'} height={'6px'} />
                        <Typography 
                            fontSize={14}
                            fontWeight={400}
                            color={colors.grayB1}
                            lineHeight={'18px'}
                        >
                            {item.commentTime}
                        </Typography>
                    </Stack>
                    <Button
                        variant={"text"}
                        onClick={handleClick}
                        bgHover='transparent'
                        sx={{ justifyContent: 'flex-end', minWidth: '6px', paddingLeft: '5px'}}
                    >
                        <MoreIcon width={6} height={22}  />
                    </Button>
                    
                </Stack>
                <Typography 
                    fontSize={14}
                    fontWeight={400}
                    color={colors.grayB1}
                    lineHeight={'18px'}
                    whiteSpace={'pre-wrap'}
                >
                    {status === 'feed' && <span dangerouslySetInnerHTML={createMarkup(item.feedText!)}></span>}
                    {status === 'comment' && <span dangerouslySetInnerHTML={createMarkup(item.commentText!)}></span>}
                    
                </Typography>
        </Stack> 
        <StyledMoreMenu
            id={id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            MenuListProps={{ 'aria-labelledby': 'more-button' }}
            sx={{ '.MuiMenu-paper': { width: '235px' } }}
            >
                <Box 
                    p={'12px 20px 12px 20px'}
                >
                    <Typography
                            fontSize={16}
                            fontWeight={600}
                            lineHeight={'34px'}
                            color={colors.white}
                            sx={{ cursor: 'pointer' }}
                        >
                        Delete this post     
                    </Typography>
                </Box>
        </StyledMoreMenu>
        </>
    );
}
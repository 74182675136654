import React, { ReactElement } from "react";
import { Tabs, Tab } from "@mui/material";
import { colors } from "src/colors";

function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

export default function MyTab(): ReactElement {

    const [value, setValue] = React.useState(1);
    

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Tabs
            sx={{
                '& .MuiTab-root': {
                    maxWidth: '41.',
                    height: '36px',
                    minHeight: 0,
                    minWidth: 0,
                    fontWeight: 700,
                    color: colors.grayB1,
                    padding: '7.5px 8px 8.5px 8px'            
                },
                '& .MuiTab-root.Mui-selected': {
                    color: colors.white,
                    fontSize: '14px',
                    fontWeight: 700,
                    backgroundColor: colors.gray44,
                    borderRadius: '8px',
                    padding: '7.5px 8px 8.5px 8px'
                },
                '& .MuiTabs-flexContainer': {
                    gap: '7px'
                },
                '& .MuiTabs-indicator': {
                    height: 0
                }
                }}
                value={value} onChange={handleChange} aria-label='basic tabs example'>
                    <Tab label='24h' {...a11yProps(0)} />
                    <Tab label='7d' {...a11yProps(1)} />
                    <Tab label='30d' {...a11yProps(2)} />
                    <Tab label='6m' {...a11yProps(3)} />
        </Tabs>
    );
 
}

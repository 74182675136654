import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab, { TabProps } from '@mui/material/Tab';
import { Stack, styled, TabsProps } from '@mui/material';
import {
  AllRandIcon,
  InstagramIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  YouTubeIcon
} from 'src/assets/icons';

import { Container } from '../../components';
import Header from "../../components/header";
import PortfolioContent from './sections';

const StyledTabs = styled((props: TabsProps) => (
  <Tabs
    orientation='vertical'
    {...props}
    TabIndicatorProps={{ style: { display: 'none' } }}
  />
))({
  width: '220px',
  paddingRight: "16px",
  fontFamily: "Chakra Petch",
  fontSize: '16px',
  fontWeight: 700,
  '& .Mui-selected': {
    color: 'white',
    backgroundColor: '#14C2A3',
  },
});

const StyledTab = styled((props: TabProps) => (
  <Tab
    iconPosition="start"
    disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  justifyContent: 'left',
  marginBottom: "12px",

  padding: '8px 0px 8px 16px',
  minHeight: '40px',
  color: '#B1BBCE',
  width: '100%',
  '&.Mui-selected': {
    color: '#fff',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));

export default function PortfolioDetails(): React.ReactElement {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (<>
    <Header />
    <Stack sx={{ width: '100%', marginTop: '45px' }}>
      <Container>
        <Stack direction='row' paddingBottom={'50px'} gap={'75px'} justifyContent='center'>

          {/* <StyledTabs
            sx={{
              width: '234px !important',
              paddingRight: 0,
              alignItems: 'center',
              '& .MuiTabs-scroller': {
                width: '218px !important'
              },
              '& .MuiTab-labelIcon': {
                fontSize: 16,
                fontWeight: 700,
                lineHeight: '20px'
              }
            }}
            value={value}
            onChange={handleChange}
            aria-label="styled tabs example"
          >
            <StyledTab icon={<AllRandIcon />} label="All rankings" />
            <StyledTab icon={<YouTubeIcon />} label="YouTube" />
            <StyledTab icon={< TelegramIcon />} label="Telegram" />
            <StyledTab icon={< InstagramIcon />} label="Instagram" />
            <StyledTab icon={<TwitterIcon />} label="Twitter" />
            <StyledTab icon={< RedditIcon />} label="Reddit" />
          </StyledTabs> */}

          <PortfolioContent />

        </Stack>
      </Container>
    </Stack>
  </>)
}
import { ReactElement, useState } from "react";
import { Divider, Stack } from "@mui/material";
import "./styles.scss";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { BoostIcon, ClockIcon, FilterIcon } from "src/assets/icons";

export default function FilterRow(): ReactElement {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return (<>
    <Stack 
      direction='row'
      sx={{ padding: '16px 0', alignItems: 'center' }} 
      spacing={'16px'}
      id='filter'
    >
      <Stack 
        direction='row' 
        sx={{ padding: '16px 0' }} 
        spacing={'16px'} 
        className='filter-box'
      >
          <Stack>
            <Button
              id="basic-button"
              className='btn-box'
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              variant='contained'
              endIcon={<KeyboardArrowDownIcon />}
              sx={{width: '200px', background: "#123142"}}
            >
              All KOLs
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              sx={{
                '& .MuiPaper-root': {
                  background: "#123142",
                  borderRadius: 0,
                }
              }}
            >
              <MenuItem sx={{ color: 'white' }} onClick={handleClose}>25 rows</MenuItem>
              <MenuItem sx={{ color: 'white' }} onClick={handleClose}>50 rows</MenuItem>
              <MenuItem sx={{ color: 'white' }} onClick={handleClose}>100 rows</MenuItem>
            </Menu>
          </Stack>
          <Divider orientation="vertical" variant="middle" flexItem={true} className="filter-divider" sx={{
            height: '39px !important',
            width: '4px',
            color: '#123142',
            opacity: 1
            }} />
          <Button
            variant='contained'
            endIcon={<ClockIcon />}
            className='btn-box'
          >
            Newly listed
          </Button>

          <Button
            variant='contained'
            endIcon={<BoostIcon />}
            className='btn-box'
          >
            Boosted
          </Button>
          <Divider orientation="vertical" variant="middle" flexItem={true} className="filter-boosted-divider"
            sx={{
              height: '39px !important',
              width: '1.5px',
              color: '#123142',
              opacity: 1
            }}
          />
      </Stack>
      <Stack id="ds-filter-item">
          <Button
            variant='text'
            endIcon={<FilterIcon />}
            sx={{ color: "#14C2A3" }}

          >
            Filters
          </Button>
      </Stack>
    </Stack>
    <Stack id="mb-filter-item">
        <Button
          variant='contained'
          endIcon={<FilterIcon />}
          sx={{ color: "#14C2A3" }}
          className="btn-filter"
        >
          Filters
        </Button>
    </Stack>
  </>)
}
import { 
    RedditIcon,
    AllRankingIcon,
    TwitterIcon,
    InstagramIcon,
    TelegramIcon,
    YouTubeIcon
 } from 'src/assets';
import { colors } from 'src/colors';

export const dataTab = [
    {
        id: 1,
        label: 'All rankings',
        img: <AllRankingIcon />,
        targetImg: <AllRankingIcon color={colors.white} />,
        value: 'All rankings',
    },
    {
        id: 2,
        label: 'YouTube',
        img: <YouTubeIcon />,
        targetImg: <YouTubeIcon color={colors.white} />,
        value: 'youtube',
    },
    {
        id: 3,
        label: 'Telegram',
        img: <InstagramIcon />,
        targetImg: <InstagramIcon color={colors.white} />,
        value: 'telegram',
    },
    {
        id: 4,
        label: 'Instagram',
        img: <TelegramIcon />,
        targetImg: <TelegramIcon color={colors.white} />,
        value: 'instagram',
    },
    {
        id: 5,
        label: 'Twitter',
        img: <TwitterIcon />,
        targetImg: <TwitterIcon color={colors.white} />,
        value: 'twitter',
    },
    {
        id: 6,
        label: 'Reddit',
        img: <RedditIcon />,
        targetImg: <RedditIcon color={colors.white} />,
        value: 'twitch',
    }
]

export default dataTab;
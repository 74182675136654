import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles.scss";

Privacy.propTypes = {
  data: PropTypes.array,
};

function Privacy(props) {
  const { data } = props;

  const [dataBlock] = useState({
    subtitle: "Privacy Policy",
    title: "KOLWatch",
  });

  return (
    <section className="tf-section tf-privacy">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="content-privacy mobie-40"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <div className="tf-title">
                <p className="h8 sub-title">{dataBlock.subtitle}</p>
                <h4 className="title">{dataBlock.title}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="container">
          <div className="row">
            <div data-aos="fade-up" data-aos-duration="800">
              <p style={{ whiteSpace: "pre-wrap" }}> {data.trim()}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Privacy;

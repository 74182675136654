import React, { ChangeEventHandler, ElementType, ReactNode } from "react";
import { Stack, Typography } from '@mui/material';
import { colors } from 'src/colors';

export type Size = "sm" | "md" | "xl" | "lg";
export type Status = "default" | "error" | "warning" | "success";
export type Align = "left" | "center" | "right";

interface IProps {
    size?: Size;
    status?: Status;
    value?: string;
    id?: string;
    label?: string;
    width?: string;
    hint?: string;
    backgroundColor?: string;
    ref?: any;
    autoComplete?: string;
    hintColor?: string;
    align?: Align;
    className?: string;
    icon?: ElementType | ReactNode;
}



type HTMLInputProps = {
    onChange?: ChangeEventHandler<HTMLInputElement>;
};

type MouseEventProps = {
    onClick?: React.MouseEventHandler<HTMLParagraphElement>;
}

type FormInputProps = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
>;

export type InputProps = Omit<FormInputProps, "size"> &
    HTMLInputProps &
    IProps;

export const TextArea: React.FC<InputProps & MouseEventProps> = (props) => {
    const {
        disabled = false,
        label,
        onChange = () => { },
        value,
        id = "",
        placeholder,
        width,
        hint,
        backgroundColor,
        hintColor,
        align = 'left',
        className = '',
        onClick = () => { },
    } = props;

    return (<Stack flexDirection={'row'} gap={'8px'}>
        <Stack className={className} sx={{
            border: `1px solid ${colors.green}`,
            backgroundColor: backgroundColor ? backgroundColor : colors.green12,
            padding: "11px 11px 11px 30px",
        }}
            alignItems={'center'}
            width={width ? width : '748px'}
            height={'182px'}
            flexDirection={'column'}
        >
            {label && <label style={{
                width: '100%'
            }}>
                <Typography
                    fontSize={12}
                    fontWeight={600}
                    color={colors.green}
                    lineHeight={'20px'}
                // sx={{ textWrap: 'nowrap' }}
                >
                    {label}:
                </Typography>
            </label>}
            <input
                id={id}
                value={value}
                type="text"
                onChange={onChange}
                placeholder={placeholder}
                disabled={disabled}
                className="custom-input"
                autoComplete="false"
                style={{
                    width: "100%",
                    height: '100%',
                    color: disabled ? 'rgba(255, 255, 255, .5)' : colors.white,
                    border: 0,
                    fontSize: '18px',
                    fontWeight: 700,
                    lineHeight: '24px',
                    padding: '25px 25px 25px 20px'
                }}
            />
            <Typography sx={{
                color: "#FFF",
                fontFamily: "Chakra Petch",
                fontSize: "12px",
                width: '100%',
                fontWeight: 400,
                lineHeight: "20px",
                textAlign: 'right'
            }} >
                100 characters
            </Typography>
        </Stack>
        {hint &&
            <Typography
                fontSize={16}
                fontWeight={400}
                lineHeight={'21px'}
                textAlign={align}
                id={id}
                color={hintColor ? hintColor : colors.grayB1}
                onClick={onClick}
                style={{ cursor: 'pointer' }}
            >
                {hint}
            </Typography>
        }
    </Stack>)
};

export default TextArea;
import { ReactElement, useState } from 'react';
import "./styles.scss";
import { Stack, Typography } from '@mui/material';
import { Tag, Button } from 'src/components';
import { colors } from 'src/colors';
import Grade from './Grade';
import About from './About';
import CryptoProjects from './CryptoProjects';
import Stats from './Stats';
import Bet from './Bet';
import Similar from './Similar';
import Information from './Information';
import LatestActivities from './LatestActivities';
import Comment from './Comment';
import { ExitingDialog } from 'src/components/dialog/exitingdialog/ExcitingDialog';
import { createMarkup } from 'src/helpers/utils';

export default function Overview({ id, data }: { id: any, data?: any }): ReactElement {
    const [openDialog1, setOpenDialog1] = useState(false);

    return (<>
        {data.map((info: any) => {
            if (info.id === id) {
                return (<>
                    {(info.id === '1' || info.id === '2') ? <Grade item={info} /> : null}

                    <About item={info} />

                    <Stack id='community-overview' sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: colors.green0E2,
                        padding: '24px',
                        gap: '13px'
                    }}>
                        <Stack className='community-box' gap={'4px'}>
                            <Typography
                                fontSize={14}
                                fontWeight={400}
                                color={colors.grayB1}
                                lineHeight={'18px'}
                            >
                                {info.nickname}
                            </Typography>
                            <Typography
                                fontSize={18}
                                fontWeight={700}
                                color={colors.white}
                                lineHeight={'27px'}
                                className='subtitle'
                            >
                                Relevant Calls and Projects and community service
                            </Typography>
                        </Stack>

                        <Stack gap={'30px'} className='community-content-box'>
                            <Typography
                                fontSize={14}
                                fontWeight={400}
                                color={colors.grayB1}
                                lineHeight={'18px'}
                                whiteSpace={'pre-wrap'}
                            >
                                <span dangerouslySetInnerHTML={createMarkup(info.relevantText!)}></span>
                            </Typography>
                            <Stack display={'flex'} flexDirection={"row"}>
                                <Tag
                                    title={'#1 in Instagram'}
                                    mode={'circle'}
                                    border={`1px solid ${colors.green0E}`}
                                    bg={colors.green12}
                                    sx={{
                                        padding: '4px 12px'
                                    }}
                                    textSize={12}
                                />
                            </Stack>
                            <Stack className='btn-community-box' display={'flex'} flexDirection={"row"} justifyContent={'flex-end'} gap={'10px'}>
                                <Button
                                    variant={"outlined"}
                                    LabelColor={colors.white}
                                    borderColor={colors.green}
                                    sx={{ width: 180 }}
                                    className='btn-community'
                                    onClick={() => { setOpenDialog1(true); }}
                                >
                                    <Typography
                                        fontSize={16}
                                        fontWeight={700}
                                        color={colors.white}
                                        lineHeight={'16px'}
                                    >
                                        TRACK WALLET
                                    </Typography>
                                </Button>
                                <Button
                                    variant={"outlined"}
                                    LabelColor={colors.white}
                                    borderColor={colors.green}
                                    sx={{ width: 180 }}
                                    backgroundColor={colors.green}
                                    bgHover={colors.green}
                                    className='btn-community'
                                >
                                    <Typography
                                        fontSize={16}
                                        fontWeight={700}
                                        color={colors.white}
                                        lineHeight={'16px'}
                                    >
                                        VOTE THIS KOL
                                    </Typography>
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>

                    <CryptoProjects item={info} />

                    <Stats item={info} />

                    <Bet />

                    <Similar item={info} />

                    <Information item={info} />

                    <LatestActivities item={info} />

                    <Comment item={info} />

                </>)
            }
        })}
        {openDialog1 && <ExitingDialog
            open={openDialog1}
            onClose={() => setOpenDialog1(false)}

        />}
    </>);
}
import React, { useState } from "react";
import { Stack, Typography, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { colors } from 'src/colors';
import { Input, Button } from 'src/components';
import { REGISTER_VERIFICATION_CODE_URL } from 'src/config/urls';
import { useFormik } from "formik";
import { validateSchema } from "src/formValidations/register";
import { useRegisterMutation } from "src/api/auth";
import { setUserRegisterInfo } from "src/slices/mainSlice";
import { useAppDispatch, useAppSelector } from "src/hooks/ReduxHooks";
import { toast } from "react-toastify";
import { format } from 'date-fns'

const CreateAccount: React.FC<{}> = () => {

    const navigate = useNavigate();
    const [register] = useRegisterMutation();
    const [value] = useState<string>('Email');
    const Dispatch = useAppDispatch();
    const user = useAppSelector(store => store.mainSlice.user);

    const formik = useFormik({
        initialValues: { ...user, birthDate: user?.birthDate ? format(new Date(user.birthDate), 'dd/MM/yyyy') : '' },
        validationSchema: validateSchema,
        onSubmit: (values: any) => {

            register({
                ...values, birthDate: new Date(values.birthDate)
            })
                .unwrap()
                .then((res) => {
                    Dispatch(setUserRegisterInfo(res.data.user));
                    navigate(REGISTER_VERIFICATION_CODE_URL);
                }
                )
                .catch((err: any) => {
                    toast.error(err.message);
                    Dispatch(setUserRegisterInfo(values));
                    navigate(REGISTER_VERIFICATION_CODE_URL);

                });
        }
    })
    const { getFieldProps } = formik;
    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: colors.green0E2,
                justifyContent: 'flex-start',
                padding: '28px 24px 24px 24px',
                gap: '20px',
                marginY: '25px',
                marginRight: '22px'
            }}>
                <Stack gap={'.5px'}>
                    <Typography
                        fontSize={14}
                        fontWeight={400}
                        color={colors.grayB1}
                        lineHeight={'21px'}
                    >
                        Register / Submit a KOL application
                    </Typography>
                    <Typography
                        fontSize={18}
                        fontWeight={700}
                        color={colors.white}
                        lineHeight={'27px'}
                    >
                        Create your account
                    </Typography>
                </Stack>

                <Input
                    label="Name"
                    id="fullName"
                    type="text"
                    // placeholder="Name"
                    {...getFieldProps('fullName')}
                />
                <Input
                    label={value}
                    id={'email'}
                    type={value === 'Email' ? 'email' : 'tel'}
                    align="right"
                    // hint={`Use ${value === 'Email' ? 'Phone' : 'Email'} instead`}
                    hintColor={colors.green}
                    // placeholder={value === 'Email' ? "Enter Email" : "Enter Phone number"}
                    // onClick={() => setValue((prev) => prev === 'Email' ? 'Phone' : 'Email')}
                    {...getFieldProps('email')}

                />
                <Input
                    label="Date of birth"
                    id="birthDate"
                    type="text"
                    placeholder="01/01/1998"
                    {...getFieldProps('birthDate')}

                />
                <Divider
                    orientation={"vertical"}
                    sx={{
                        height: "1px",
                        borderWidth: "1px",
                        borderColor: colors.green16,
                        marginY: '17px'
                    }}
                />
                <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                    <Button
                        variant={"outlined"}
                        LabelColor={colors.black}
                        borderColor={'transparent'}
                        bgHover={colors.green}
                        sx={{ height: 52, width: '265px' }}
                        onClick={() => navigate(-1)}
                    >
                        <Typography
                            fontSize={16}
                            fontWeight={700}
                            lineHeight={'16px'}
                            color={colors.white}
                        >
                            Back
                        </Typography>
                    </Button>
                    <Button
                        variant={"outlined"}
                        LabelColor={colors.black}
                        borderColor={colors.green22}
                        backgroundColor={colors.green}
                        bgHover={colors.green}
                        sx={{ height: 52, width: '265px' }}
                        type='submit'
                        disabled={!formik.isValid}
                    >
                        <Typography
                            fontSize={16}
                            fontWeight={700}
                            lineHeight={'16px'}
                            color={colors.white}
                        >
                            Next
                        </Typography>
                    </Button>
                </Stack>
            </Stack>
        </form>
    );
};

export default CreateAccount;
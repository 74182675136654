import React, { Dispatch, SetStateAction, useState } from "react";
import { Stack, Typography } from '@mui/material';
import { colors } from 'src/colors';
import { Input, Button } from 'src/components';
import { LeftArrowIcon } from 'src/assets/icons';
import LogoUpload from "src/components/logoUpload/LogoUpload";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "src/hooks/ReduxHooks";
import { setUpdateUserInfo } from "src/slices/mainSlice";
import { useUpdateProfileMutation } from "src/api/user";
import { toast } from "react-toastify";

interface Props {
  cryptoProjects: cryptoProject[]
}
interface cryptoProject {
  id: number, avatar: string, link: string, title: string
}
const initialValue: Props = { cryptoProjects: [{ id: 1, avatar: '', link: '', title: '' }] }
const IntroduceYourCryptoProjects: React.FC<{ setStep: Dispatch<SetStateAction<number>> }> = ({ setStep }) => {
  const [updateProfile] = useUpdateProfileMutation();
  const Dispatch = useAppDispatch();
  const user = useAppSelector(store => store.mainSlice.user);

  const formik = useFormik({
    initialValues: initialValue,
    enableReinitialize: true,
    onSubmit: (values) => {
      const body = values.cryptoProjects.map((value: cryptoProject) => {
        return { avatar: value.avatar, title: value.title, link: value.link }
      });
      updateProfile({ data: { cryptoProjects: body }, token: user.accessToken ?? '' })
        .unwrap()
        .then((res) => {
          Dispatch(setUpdateUserInfo(values));
          toast.success('Update Profile Success');
          setStep((prev: number) => prev + 1);
        })

    },
  });

  const { handleSubmit } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <Stack sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors.green0E2,
        justifyContent: 'flex-start',
        padding: '8px 24px 60px 24px',
        gap: '20px',
        // height: '780px',
        marginY: '25px',
        marginRight: '22px'
      }}>
        <Stack
          flexDirection={'row'}
          alignItems={'center'}
          gap={'12px'}
          marginLeft={'13px'}
          sx={{ cursor: 'pointer' }}
          onClick={() => setStep((prev: number) => prev - 1)}
        >
          <LeftArrowIcon />
          <Typography
            fontSize={16}
            fontWeight={600}
            lineHeight={'60px'}
            color={colors.white}
          >
            Back
          </Typography>
        </Stack>
        {formik.values?.cryptoProjects?.map((item, index) => <Stack sx={{ gap: '20px' }}>
          <Stack gap={'.5px'}>
            <Typography
              fontSize={14}
              fontWeight={400}
              color={colors.grayB1}
              lineHeight={'21px'}
            >
              #{index + 1} Crypto Project
            </Typography>
            <Typography
              fontSize={18}
              fontWeight={700}
              color={colors.white}
              lineHeight={'27px'}
              whiteSpace={'pre-wrap'}
            >
              Let’s introduce your crypto projects
            </Typography>
          </Stack>
          <Stack>
            <LogoUpload
              onFileUrl={(url) => {

                formik.setValues((prev: any) => {
                  const temp = prev.cryptoProjects.map((p: any) => {
                    if (p.id === item.id)
                      return { ...p, avatar: url }
                    return p;
                  });
                  return { ...prev, cryptoProjects: temp }
                }

                )
              }}
              onError={(txt: string) => console.log(txt)}
            />
          </Stack>

          <Input
            label="Title:"
            id="title"
            value={item.title}
            onChange={(e) => {
              formik.setValues((prev: any) => {
                const temp = prev.cryptoProjects.map((p: any) => {
                  if (p.id === item.id)
                    return { ...p, title: e.target.value }
                  return p;
                });
                return { ...prev, cryptoProjects: temp }
              })
            }}
          />

          <Input
            label="Link:"
            id="link"
            value={formik.values.cryptoProjects[index].link}
            onChange={(e) => {
              formik.setValues((prev: any) => {
                const temp = prev.cryptoProjects.map((p: any) => {
                  if (p.id === item.id)
                    return { ...p, link: e.target.value }
                  return p;
                });
                return { ...prev, cryptoProjects: temp }
              })
            }}

          />

        </Stack>
        )}
        <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
          <Stack
            direction='row'
            spacing='20px'
          >
            <Typography sx={{
              color: colors.green,
              fontFamily: "Chakra Petch",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "27px",
              textDecorationLine: "underline",
              cursor: 'pointer'
            }}
              onClick={() => formik.setValues(
                (prev: Props) => {
                  return {
                    ...prev, cryptoProjects: [...prev.cryptoProjects,
                    { id: prev.cryptoProjects.length + 1, avatar: '', link: '', title: '' }]
                  };

                }
              )}
            >
              + ADD MORE
            </Typography>
            {formik.values?.cryptoProjects?.length > 1 &&
              <Typography sx={{
                color: colors.orangeFF6,
                fontFamily: "Chakra Petch",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "27px",
                textDecorationLine: "underline",
                cursor: 'pointer'
              }}
                onClick={() => formik.setValues((prev: any) => {
                  prev.cryptoProjects.pop();
                  return prev
                }
                )}

              >
                REMOVE
              </Typography>
            }</Stack>
          <Button
            variant={"outlined"}
            LabelColor={colors.black}
            borderColor={colors.green22}
            backgroundColor={colors.green}
            bgHover={colors.green}
            sx={{ height: 52, width: '265px' }}
            type="submit"          >
            <Typography
              fontSize={16}
              fontWeight={700}
              lineHeight={'16px'}
              color={colors.white}
            >
              Next
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </form >
  );
};

export default IntroduceYourCryptoProjects;
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import ProjectItem from './project-item';

Project.propTypes = {
    data: PropTypes.array,
};

function Project(props) {
    const { data } = props;
    const [dataBlock] = useState(
        {
            subtitle: 'tokenomics',
            title: 'KOLWatch Token Dynamics',
        }
    )

    return (
        <section id='tokenomics' className="tf-section tf-project">
            <div className="container">
                <div className="col-md-12">
                    <div className="tf-title" data-aos="fade-up" data-aos-duration="800">
                        <p className="h8 sub-title">{dataBlock.subtitle}</p>
                        <h4 className="title">{dataBlock.title}</h4>
                    </div>
                </div>
                <div className="row mb-40">
                    {
                        data.slice(0, 4).map(item => (
                            <div className="col-md-3">
                                <ProjectItem item={item} />
                            </div>
                        ))
                    }
                </div>
                <div className="row" style={{ justifyContent: "center" }}>
                    {
                        data.slice(4, 8).map(item => (
                            <div className="col-md-3" >
                                <ProjectItem item={item} />
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );
}

export default Project;
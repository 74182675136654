import { ReactElement, useState } from 'react';
import "./styles.scss";
import { Stack, Typography, Box, Grid } from '@mui/material';
import { Swiper as SwiperProps, Scrollbar, A11y, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import { Tag } from 'src/components';
import { colors } from 'src/colors';
import { GrayArrowRight, GrayArrowLeft } from 'src/assets/icons';

// const text = 'Shop with KAI-CHING (KAIC) to save time and money. How? Pay less if you pay with KAIC in hundreds of shops & earn extra KAIC while playing & shopping on KAIKAI Shop with KAI-CHING (KAIC) to save time and money. How? Pay less if you pay with KAIC in hundreds of shops & earn extra KAIC while playing & shopping on KAIKAI'

export default function About(props: any): ReactElement {
    const { item } = props;
    const [isReadMore, setIsReadMore] = useState(true);
    const [swiper, setSwiper] = useState<SwiperProps>();
    const [isEnd, setIsEnd] = useState<boolean | undefined>(undefined);

    const handleClick = () => { 
        setIsReadMore(!isReadMore); 
    }; 
    
    return (
        <Stack id='about-overview' sx={{
            backgroundColor: colors.green0E2,
            padding: '24px',
            gap: '30px'
        }}>
            <Stack className='title-section' sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '20px'
            }}>
                <Typography 
                    fontSize={18}
                    fontWeight={700}
                    color={colors.white}
                    lineHeight={'27px'}
                >
                    About {item.nickname}
                </Typography>
                <Stack display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    <Typography 
                        fontSize={16}
                        fontWeight={400}
                        color={colors.grayB1}
                        lineHeight={'24px'}
                        sx={{ opacity: '48%' }}
                    >
                        KYC STATUS: 
                    </Typography>
                    <Typography 
                        fontSize={16}
                        fontWeight={400}
                        color={colors.green}
                        lineHeight={'24px'}
                    >
                        &nbsp;APPROVED
                    </Typography>
                </Stack>
            </Stack>
            <Stack className='about-section-left' sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                gap: '10px'
            }}>
                    <Stack sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '15px',
                    // width: '59%'
                }}>
                        <Typography 
                            fontSize={14}
                            fontWeight={400}
                            color={colors.grayB1}
                            lineHeight={'18px'}
                            display={'inline'}
                            whiteSpace={'pre-wrap'}
                        >
                        {/* {isReadMore ? data.aboutText.slice(0, 160) : data.aboutText} */}
                        {item.aboutText}
                        {/* {data.aboutText.length > 160 &&
                            <Box
                                sx={{
                                    fontSize: 14,
                                    fontWeight: 700,
                                    color: colors.green,
                                    cursor: 'pointer'
                                }}
                                onClick={handleClick}
                                component="span"
                                display="inline"
                                alignItems="center"
                            >
                                
                                {isReadMore ? "Read more" : " Show less"}
                            </Box>} */}
                        </Typography>
                        <Stack 
                            className='extra-box'
                            display={"flex"}
                            flexDirection={"row"}
                            alignItems={"center"}
                            gap={"12px"}>
                                <Typography
                                    fontSize={16}
                                    fontWeight={400}
                                    color={colors.white}
                                >
                                    Rank
                                </Typography>
                                <Tag 
                                    title={'#1 in Instagram'}
                                    mode={'circle'}
                                    border={`1px solid ${colors.green0E}`}
                                    bg={colors.green12}
                                    sx={{
                                        padding: '4px 12px'
                                    }}
                                    textSize={12}
                                />
                        </Stack>
                        <Stack 
                            className='extra-box'
                            display={"flex"}
                            flexDirection={"row"}
                            alignItems={"center"}
                            gap={"8px"}
                        >
                                <Typography
                                    fontSize={16}
                                    fontWeight={400}
                                    color={colors.white}
                                >
                                    Tags
                                </Typography>
                                <Stack className='tags-box' display={'flex'} flexDirection={'row'} gap={'8px'}>
                                    {tags.slice(0, 7).map((item: any) => {
                                        return <Tag 
                                            title={item.text}
                                            mode={'circle'}
                                            border={`1px solid ${colors.green0E}`}
                                            bg={colors.green12}
                                            sx={{
                                            padding: '4px 12px'
                                            }}
                                            textSize={12}
                                        />
                                    })}
                                </Stack>
                        </Stack>
                    </Stack> 
                    <Stack 
                        position={'relative'} 
                        paddingTop={'15px'}
                        className='about-slider-section'
                        // width={'41%'}
                    >
                        <Grid className='slider-grid-box' display={'flex'} gap={'10px'} alignItems={'center'}>
                            <Box
                                className='about-arrow-right'
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                width={30}
                                height={30}
                                bgcolor={colors.gray2B}
                                borderRadius={'50px'}
                                zIndex={11}
                                sx={{
                                    cursor: 'pointer',
                                    opacity: !isEnd ? .5 : 1
                                }}
                                    onClick={() => swiper?.slidePrev()}
                                >
                                   <GrayArrowLeft />
                            </Box>  
                            <Swiper
                                modules={[ Pagination, Scrollbar, A11y ]}
                                spaceBetween={6}
                                scrollbar={{ draggable: true }}
                                pagination={true}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                    },
                                    300: {
                                        slidesPerView: 2.2,
                                    },
                                    320: {
                                        slidesPerView: 2.4,
                                    },
                                    350: {
                                        slidesPerView: 2.7,
                                    },
                                    400: {
                                        slidesPerView: 2.85,
                                    },
                                    480: {
                                        slidesPerView: 3,
                                    },
                                    767: {
                                        slidesPerView: 2,
                                    },
                                    991: {
                                        slidesPerView: 2,
                                    },
                                }}
                                onSwiper={(swiper: SwiperProps) => setSwiper(swiper)}
                                onReachEnd={(e: any) => { 
                                    if(!e.isBeginning){
                                        setIsEnd(true);
                                    } else {
                                        setIsEnd(false);
                                    } 
                                }}
                                onReachBeginning={() => { setIsEnd(false); }}
                                style={{ position: 'unset', width: '212px',  }}
                                className='about-swiper'
                            >
                                {
                                    item.about.map((_item: any) => (
                                        <SwiperSlide key={_item.id} style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Stack display={'flex'} flexDirection={'row'} >
                                                <img 
                                                    src={_item.img} 
                                                    alt={`img-${_item.id}`}
                                                    width={'97.5px'}
                                                    height={'106px'}
                                                />
                                            </Stack>
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                            <Box
                                className='about-arrow-left'
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                width={30}
                                height={30}
                                bgcolor={colors.gray2B}
                                borderRadius={'50px'}
                                zIndex={11}
                                sx={{
                                    cursor: 'pointer',
                                    opacity: isEnd ? .5 : 1
                                }}
                                onClick={() => swiper?.slideNext()}
                            >
                                <GrayArrowRight />
                            </Box>                          
                        </Grid>
                    </Stack>
            </Stack>
        </Stack>
        
    );
}

const tags = [
    {
        id: 1,
        text: "Cryptocurrency"
    },
    {
        id: 2,
        text: "Content Creation"
    },
    {
        id: 3,
        text: "Social"
    }
];
import { ReactElement } from 'react';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Avatar, Table as MuiTable, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import TableHeader from './TableHeader';
import { colors } from 'src/colors';
import { convertNumberToFloat } from 'src/helpers/utils';
import { GreenArrow, RedArrow } from 'src/assets/icons';

export function Table({ data }: { data: any}): ReactElement {

    const cellStyle = {
        textAlign: "right",
        borderBottom: 'inherit',
        color: 'white',
    }

    return (
        <>
            <TableContainer sx={{ width: '100%' }}>
                <MuiTable>
                    <TableHeader />
                    <TableBody>
                        {
                            data.map((_el: any, index: number) => {
                                return (
                                    <TableRow
                                        key={_el.id}
                                        hover
                                        sx={{ borderBottom: _el.id !== 10 ? `1px solid ${colors.default} !important` : undefined,
                                        '& td': {
                                            padding: '6px 16px'
                                        } }}
                                    >
                                        <TableCell sx={{ ...cellStyle, textAlign: 'left', width: "276.04px"  }}>
                                            <Box
                                                display={'flex'}
                                                flexDirection={'row'}
                                                alignItems={'center'}
                                                gap={'10px'}
                                            >
                                                <Avatar src={''}
                                                    variant='square'
                                                    sx={{ width: "28px", height: "28px", borderRadius: "50%" }}>
                                                </Avatar>
                                                <Typography 
                                                    fontSize={18}
                                                    fontWeight={500}
                                                    lineHeight={'26px'}
                                                    color={colors.white}
                                                    sx={{ whiteSpace: 'nowrap' }}
                                                >
                                                    {_el.name ?? ''}
                                                </Typography>
                                                <Typography
                                                    fontSize={18}
                                                    fontWeight={400}
                                                    lineHeight={'26px'}
                                                    color={colors.green1B}
                                                >
                                                    •
                                                </Typography>
                                                <Typography
                                                    fontSize={18}
                                                    fontWeight={400}
                                                    lineHeight={'26px'}
                                                    color={colors.green1B}
                                                >
                                                    {_el.extraName ?? ''}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={cellStyle}>
                                            <Box
                                                display={'flex'}
                                                alignItems={'center'}
                                                justifyContent={'right'}
                                                gap={'8px'}
                                            >
                                                <Typography 
                                                    fontSize={16}
                                                    fontWeight={400} 
                                                    lineHeight={'26px'}
                                                    color={colors.grayBF}
                                                > 
                                                    {convertNumberToFloat(_el?.amount)}
                                                </Typography>
                                            </Box>
                                        </TableCell>

                                        <TableCell sx={{ ...cellStyle, textAlign: 'right' }}>
                                            <Box display={'flex'} justifyContent={'right'}>
                                                <Stack  
                                                    flexDirection={'row'}
                                                    alignItems={'center'} 
                                                    borderRadius={'8px'}
                                                    gap={'8px'} 
                                                    sx={{
                                                        backgroundColor: _el.change < 1 ? 'rgba(240, 41, 52, 0.1)' : 'rgba(52, 179, 73, 0.1)',
                                                        padding: '5px 3.06px 5px 4px'
                                                    }}
                                                >
                                                {_el.change < 1 ? <RedArrow /> : <GreenArrow />}
                                                <Typography 
                                                    fontSize={14}
                                                    fontWeight={500} 
                                                    lineHeight={'20px'}
                                                    color={_el.change < 1 ? colors.redFF : colors.green6C}
                                                > 
                                                    {`${_el.change}%`}
                                                </Typography>
                                                </Stack>
                                            </Box>
                                            
                                        </TableCell>

                                        <TableCell sx={cellStyle}>
                                            <Typography 
                                                    fontSize={16}
                                                    fontWeight={400} 
                                                    lineHeight={'24px'}
                                                    color={colors.grayBF}
                                                > 
                                                    {`£${convertNumberToFloat(_el.Price)}`}
                                            </Typography>
                                        </TableCell>

                                        <TableCell sx={cellStyle}>
                                            <Typography 
                                                fontSize={16}
                                                fontWeight={500} 
                                                lineHeight={'24px'}
                                                color={colors.grayBF}
                                            > 
                                                {`£${convertNumberToFloat(_el.total)}`}
                                            </Typography>
                                        </TableCell>

                                        <TableCell sx={cellStyle}> 
                                            <Stack display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
                                                <Typography 
                                                    fontSize={16}
                                                    fontWeight={500} 
                                                    lineHeight={'24px'}
                                                    color={_el.change < 1? colors.redFF : colors.green6C}
                                                > 
                                                    {`-£${convertNumberToFloat(_el.time)}`}
                                                </Typography>
                                                <Stack flexDirection={'row'} gap={'5px'}>
                                                    {_el.change < 1 ? <RedArrow /> : <GreenArrow />}
                                                    <Typography 
                                                        fontSize={16}
                                                        fontWeight={500} 
                                                        lineHeight={'24px'}
                                                        color={_el.change < 1? colors.redFF : colors.green6C}
                                                    > 
                                                        {`-£${convertNumberToFloat(_el.timePercent)}`}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </TableCell>
                                      
                                    </TableRow>
                                );
                            })
                        }

                    </TableBody>
                </MuiTable >
            </TableContainer >
        </>
    );
}
export default Table;
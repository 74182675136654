import React from "react";
import { Stack, Typography, Divider } from '@mui/material';
import { colors } from 'src/colors';
import { Input, Button } from 'src/components';
import { useNavigate } from 'react-router-dom';
import { SUBSCRIBE_URL } from 'src/config/urls';

const Submit: React.FC<{}> = () => {
  
  const navigate = useNavigate();

  return (
    <Stack sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors.green0E2,
        justifyContent: 'center',
        padding: '34px 24px 24px 24px',
        gap: '16px',
        marginY: '25px',
        marginRight: '22px'
    }}>
        <Stack gap={'.5px'}>
            <Typography
                fontSize={14}
                fontWeight={400}
                color={colors.grayB1}
                lineHeight={'21px'}
            >
                Submit a KOL application
            </Typography>
            <Typography
                fontSize={18}
                fontWeight={700}
                color={colors.white}
                lineHeight={'27px'}
            >
                More About you
            </Typography>
        </Stack>
        {
            inputs.slice(0,6).map(item => (
                <Input
                    label={item.lable}
                    id={item.text}
                    backgroundColor="transparent"
                />         
            ))
        }
        <Divider 
            orientation={"vertical"}
            sx={{
                height: "1px",
                borderWidth: "1px",
                borderColor: colors.green16
            }} 
        />
        {
            inputs.slice(6,11).map(item => (
                <Input
                    label={item.lable}
                    id={item.text}
                    backgroundColor="transparent"
                />         
            ))
        }
        <Divider 
            orientation={"vertical"}
            sx={{
                height: "1px",
                borderWidth: "1px",
                borderColor: colors.green16
            }} 
        />
        <Stack flexDirection={'row'} gap={'16px'} justifyContent={'flex-end'}>
            <Button
                variant={"text"}
                backgroundColor={colors.green}
                LabelColor={colors.black}
                bgHover={colors.green}
                sx={{ width: '265px', height: '52px' }}
                onClick={() => navigate(SUBSCRIBE_URL)}
            >
                <Typography
                    fontSize={16}
                    fontWeight={700}
                    lineHeight={'16px'}
                    color={colors.white}
                    textTransform={'uppercase'}
                >
                    Next
                </Typography>
            </Button>
        </Stack>
        <Divider 
            orientation={"vertical"}
            sx={{
                height: "1px",
                borderWidth: "1px",
                borderColor: colors.green16
            }} 
        />
    </Stack>
  );
};

const inputs = [
    {
        id: 1,
        lable: "YouTube",
        text: "youtube"
    },
    {
        id: 2,
        lable: "Telegram",
        text: "telegram"
    },
    {
        id: 3,
        lable: "Instagram",
        text: "instagram"
    },
    {
        id: 4,
        lable: "Twitter",
        text: "twitter"
    },
    {
        id: 5,
        lable: "Reddit",
        text: "reddit"
    },
    {
        id: 6,
        lable: "Twitch",
        text: "twitch"
    },
    {
        id: 7,
        lable: "ETH WALLET",
        text: "eth"
    },
    {
        id: 8,
        lable: "BNB WALLET",
        text: "bnb"
    },
    {
        id: 9,
        lable: "TRC WALLET",
        text: "trc"
    },
    {
        id: 10,
        lable: "BITCOIN WALLET",
        text: "bitcoin"
    },
    {
        id: 11,
        lable: "FTM WALLET",
        text: "ftm"
    },
];

export default Submit;
import React, { useState } from "react";
import { Stack, Typography, Divider } from '@mui/material';
import { colors } from 'src/colors';
import { Input, Button } from 'src/components';
import { useFormik, Form, FormikProvider } from 'formik';
import { initialValues, initialValuesProps, validationSchema, validationSchemaFirst } from 'src/formValidations/login';
import { GoogleIcon } from 'src/assets/icons';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { LOGIN_ADD_PASSWORD_URL, LOGIN_FORGOT_PASSWORD_URL, REGISTER_URL } from 'src/config/urls';
import { getGoogleAccountInfo } from 'src/helpers/utils';
import { isLogin } from 'src/helpers/utils';

const Login: React.FC<{}> = () => {
    const [value, setValue] = useState('');
    const [profile, setProfile] = useState<any>();
    const navigate = useNavigate();
    const [showUserPass, setShowUserPass] = useState<boolean>(false);
    const login = useGoogleLogin({
        onSuccess: async (response: any) => {
            getGoogleAccountInfo(response, setProfile);
            navigate('/');
        }
    });

    const onChangeInput = (event: any) => {
        setValue(event.target.value);
    };

    const formik = useFormik({
        initialValues: { email: '' },
        validationSchema: validationSchemaFirst,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: (values: any) => {

        },
    });

    const { errors, touched, handleSubmit, getFieldProps } = formik;

    return (

        <Stack sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: colors.green0E2,
            justifyContent: 'center',
            padding: '85px 24px 24px 24px',
            gap: '16px',
            marginY: '25px',
            marginRight: '22px'
        }}>
            <Typography
                fontSize={18}
                fontWeight={700}
                color={colors.white}
                lineHeight={'27px'}
                paddingBottom={'20px'}
            >
                Log in
            </Typography>
            <Button
                variant={"outlined"}
                LabelColor={colors.black}
                borderColor={colors.green}
                backgroundColor={colors.green12}
                sx={{ height: 75 }}
                disabled={isLogin() ? true : false}
                startIcon={<GoogleIcon />}
                onClick={() => login()}
            >
                <Typography
                    fontSize={18}
                    fontWeight={700}
                    lineHeight={'24px'}
                    color={colors.white}
                >
                    Log in with Google
                </Typography>
            </Button>
            <Button
                variant={"outlined"}
                LabelColor={colors.black}
                borderColor={colors.green}
                backgroundColor={colors.green12}
                sx={{ height: 75 }}
            // disabled={isLogin() ? true : false}
            // onClick={() => login()}
            >
                <Typography
                    fontSize={18}
                    fontWeight={700}
                    lineHeight={'24px'}
                    color={colors.white}
                >
                    Log in with Wallet
                </Typography>
            </Button>
            <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} paddingY={'20px'}>
                <Divider
                    orientation={"vertical"}
                    sx={{
                        height: "1px",
                        width: '46%',
                        borderWidth: "1px",
                        borderColor: colors.green2D
                    }}
                />
                <Typography
                    fontSize={14}
                    fontWeight={400}
                    color={colors.white}
                    lineHeight={'18.2px'}
                    sx={{ opacity: '50%' }}
                >
                    or
                </Typography>
                <Divider
                    orientation={"vertical"}
                    sx={{
                        height: "1px",
                        width: '46%',
                        borderWidth: "1px",
                        borderColor: colors.green2D
                    }}
                />
            </Stack>
            <form onSubmit={formik.handleSubmit}>
                <Stack sx={{

                    backgroundColor: colors.green0E2,
                    justifyContent: 'center',

                    gap: '16px',

                }}>
                    <Input
                        id='email'
                        label="Email Address"
                        type="email"
                        {...getFieldProps('email')}
                    />
                    <Divider
                        orientation={"vertical"}
                        sx={{
                            height: "1px",
                            borderWidth: "1px",
                            borderColor: colors.green16
                        }}
                    />
                    <Stack flexDirection={'row'} gap={'16px'} justifyContent={'flex-end'}>
                        <Button
                            variant={"text"}
                            sx={{ width: '213px', height: '52px' }}
                            bgHover={colors.green}
                            onClick={() => navigate(LOGIN_FORGOT_PASSWORD_URL)}
                        >
                            <Typography
                                fontSize={16}
                                fontWeight={400}
                                lineHeight={'16px'}
                                color={colors.white}
                            >
                                Forgot Password
                            </Typography>
                        </Button>
                        <Button
                            variant={"text"}
                            backgroundColor={colors.green}
                            LabelColor={colors.black}
                            bgHover={colors.green}
                            disabled={isLogin() ? true : !formik.isValid}
                            sx={{ width: '213px', height: '52px' }}
                            onClick={() => navigate(LOGIN_ADD_PASSWORD_URL + '?email=' + formik.values['email'])}
                        >
                            <Typography
                                fontSize={16}
                                fontWeight={700}
                                lineHeight={'16px'}
                                color={colors.white}
                                textTransform={'uppercase'}
                            >
                                Login
                            </Typography>
                        </Button>
                    </Stack>
                </Stack>
            </form>
            <Divider
                orientation={"vertical"}
                sx={{
                    height: "1px",
                    borderWidth: "1px",
                    borderColor: colors.green16
                }}
            />
            <Stack gap={'16px'} paddingTop={'30px'} paddingBottom={'135px'}>
                <Typography
                    fontSize={18}
                    fontWeight={700}
                    lineHeight={'27px'}
                    color={colors.white}
                    textAlign={'center'}
                >
                    Do not have an account?
                </Typography>
                <Button
                    variant={"outlined"}
                    LabelColor={colors.black}
                    borderColor={colors.green22}
                    sx={{ height: 64 }}
                    onClick={() => navigate(REGISTER_URL)}
                >
                    <Typography
                        fontSize={16}
                        fontWeight={600}
                        lineHeight={'60px'}
                        color={colors.white}
                        textTransform={'uppercase'}
                    >
                        Register
                    </Typography>
                </Button>
            </Stack>
        </Stack>

    );
};

export default Login;
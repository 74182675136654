import React from "react";
import { Stack, Typography, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { colors } from 'src/colors';
import { Button } from 'src/components';
import { BnbIcon, QrCodeIcon } from 'src/assets/icons';

const Subscribe: React.FC<{}> = () => {

  return (
    <Stack sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors.green0E2,
        justifyContent: 'flex-start',
        padding: '34px 24px 24px 24px',
        gap: '20px',
        height: '780px',
        marginY: '25px',
        marginRight: '22px'
    }}>
        <Stack gap={'.5px'}>
            <Typography
                fontSize={14}
                fontWeight={400}
                color={colors.grayB1}
                lineHeight={'21px'}
            >
                Submit a KOL application
            </Typography>
            <Typography
                fontSize={18}
                fontWeight={700}
                color={colors.white}
                lineHeight={'27px'}
                whiteSpace={'pre-wrap'}
            >
                Subscription fee
            </Typography>
        </Stack>

        <Typography
            fontSize={16}
            fontWeight={500}
            color={colors.white}
            lineHeight={'27px'}
            whiteSpace={'pre-wrap'}
        >
            {`Please leave this page open and After completing your\npayment it will take 15 minute for the status to get updated`}
        </Typography>
        
        <Stack 
            display={'flex'}
            border={`1px solid ${colors.yellowF2}`}
            width={'748px'}
            flexDirection={'column'}
            justifyContent={'center'}
            gap={'40px'}
            padding= '30px 40px 20px 40px'
        >
            <Stack alignItems={'center'} flexDirection={'column'} gap={'25px'}>
                <Stack display={'flex'} flexDirection={'row'} gap={'5px'} alignItems={'center'}>
                    <BnbIcon width={'24.94px'} height={'24.94px'} />
                    <Typography
                        fontSize={16}
                        fontWeight={600}
                        color={colors.white}
                        lineHeight={'20px'}
                        textTransform={'uppercase'}
                    >
                        BNB
                    </Typography>
                </Stack>
                <QrCodeIcon width={'219px'} height={'222px'}  />
            </Stack>
            <Stack display={'flex'} flexDirection={'column'} gap={'8px'} alignItems={'flex-start'}>
                <Typography
                    fontSize={16}
                    fontWeight={500}
                    color={colors.white}
                    lineHeight={'27px'}
                >
                    Amount: 12 BNB
                </Typography>
                <Typography
                    fontSize={16}
                    fontWeight={500}
                    color={colors.white}
                    lineHeight={'27px'}
                >
                    Address: kj123okoi3unmb123p3hv1g23l5984735nmqywterfc1787398u
                </Typography>
            </Stack>
        </Stack>
        <Divider 
            orientation={"vertical"}
            sx={{
                height: "1px",
                borderWidth: "1px",
                borderColor: colors.green16
            }} 
        />

        <Stack display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} gap={'35px'}>
            <Stack flexDirection={'row'} alignItems={'center'} gap={'16px'}>
                <Typography
                    fontSize={16}
                    fontWeight={500}
                    color={colors.white}
                    lineHeight={'27px'}
                >
                    Status:
                </Typography>
                <Stack flexDirection={'row'} alignItems={'center'} gap={'7px'}>
                    <Stack width={12} height={12} sx={{ backgroundColor: colors.yellowF2, borderRadius: '50%' }} />
                    <Typography
                        fontSize={16}
                        fontWeight={500}
                        color={colors.yellowF2}
                        lineHeight={'27px'}
                    >
                        Pending
                    </Typography>
                </Stack>
            </Stack>
            <Button
                variant={"outlined"}
                LabelColor={colors.black}
                borderColor={colors.green22}
                backgroundColor={colors.green}
                bgHover={colors.green}
                disabled={true}
                sx={{ height: 52, width: '265px', opacity: '45%' }}
            >
                <Typography
                    fontSize={16}
                    fontWeight={700}
                    lineHeight={'16px'}
                    color={colors.white}
                >
                    SUBMIT
                </Typography>
            </Button>
        </Stack>

        <Divider 
            orientation={"vertical"}
            sx={{
                height: "1px",
                borderWidth: "1px",
                borderColor: colors.green16
            }} 
        />

    </Stack>
  );
};

export default Subscribe;
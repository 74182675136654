import { Divider, Stack, Typography } from "@mui/material";
import "./styles.scss";
import { ReactElement, useState } from "react";
import { InputAdornment, Pagination } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { MyTable } from "./table";
import { TextFieldSearch } from "../TextField";
import FilterRow from "./FilterRow";
import ButtonMenu from "./ButtonMenu";
import Categories from "../Categories";
import { colors } from 'src/colors';

export default function RightSide({ selectedMenu }: { selectedMenu: string }): ReactElement {
  const [searchKey, setSearchKey] = useState<string>('');

  const [params, setParams] = useState<any>({
    page: 1,
    limit: 10,
    search: '',
    sort: '',
  });

  return (
    <Stack id='top-influencer' width={'81.5%'} sx={{ backgroundColor: "#0E2331", marginLeft: '15px' }} >
      <Stack
        className='top-influencer-box'
        divider={<Divider sx={{
          borderColor: colors.green16,
          borderWidth: '1.5px'
        }}/>}
        px={'24px'}
      >
        <Typography 
          variant="h3"
          color='white'
          fontFamily={'Poppins'}
          fontSize={24}
          fontWeight={700}
          p='16px 32px'
          className='title'
        >
          Top Influencers
        </Typography>
        <FilterRow />
        <Categories />
        <Stack
          component={'form'}
          onSubmit={(event) => {
            event.preventDefault();
            setParams((prev: any) =>
              ({ ...prev, page: 1, search: searchKey ?? '' }));
          }}
          sx={{ padding: '16px 0' }}
        >
          <TextFieldSearch
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: '#B1BBCE' }} />
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              setSearchKey(event.target.value);
            }}
            placeholder='Search dapps' sx={{ width: '30%' }} className="input-top-influencer" />
        </Stack>
      </Stack>
      <MyTable isBlockChain={true} selectedMenu={selectedMenu} />
      {/* <Stack
        direction="row-reverse"
        alignItems={"center"}
        sx={{ padding: "16px 9px" }}
      >
        <Pagination params={maidData?.pagination} setParams={setParams} />

        <Pagination count={10} shape="rounded" />
        <ButtonMenu />
      </Stack> */}
    </Stack>
  );
}

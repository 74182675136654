const dataAbout = [
    {
        id: 1,
        title: 'KOL VERIFICATION AND REPUTATION SYSTEM',
        desc: 'KOLWatch employs a rigorous verification process to onboard legitimate KOLs and influencers. This process includes verifying their track record, credibility, and historical performance in the crypto space. Each KOL is assigned a reputation score that reflects their reliability and accuracy over time.',
    },
    {
        id: 2,
        title: 'KOL VERIFICATION AND REPUTATION SYSTEM',
        desc: 'KOLWatch employs a rigorous verification process to onboard legitimate KOLs and influencers. This process includes verifying their track record, credibility, and historical performance in the crypto space. Each KOL is assigned a reputation score that reflects their reliability and accuracy over time.',
    },
    {
        id: 3,
        title: 'KOL VERIFICATION AND REPUTATION SYSTEM',
        desc: 'KOLWatch employs a rigorous verification process to onboard legitimate KOLs and influencers. This process includes verifying their track record, credibility, and historical performance in the crypto space. Each KOL is assigned a reputation score that reflects their reliability and accuracy over time.',
    },
    {
        id: 4,
        title: 'KOL VERIFICATION AND REPUTATION SYSTEM',
        desc: 'KOLWatch employs a rigorous verification process to onboard legitimate KOLs and influencers. This process includes verifying their track record, credibility, and historical performance in the crypto space. Each KOL is assigned a reputation score that reflects their reliability and accuracy over time.',
    },
]

export default dataAbout;
export function BottomArrowIcon({
    color,
    width,
    height
}: { color?: string, width?: string, height?: string }) {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg"
            width={width ?? "9.99"} 
            height={height ?? "6"} 
            viewBox="0 0 8 5" 
            fill="none" 
        >
            <path 
                fill-rule="evenodd" 
                clip-rule="evenodd" 
                d="M0.576705 0.661178C0.713089 0.524835 0.89804 0.448242 1.09089 0.448242C1.28373 0.448242 1.46869 0.524835 1.60507 0.661178L3.99998 3.05609L6.39489 0.661178C6.53205 0.528699 6.71576 0.455394 6.90645 0.457051C7.09714 0.458708 7.27955 0.535195 7.41439 0.670037C7.54923 0.804879 7.62572 0.987288 7.62738 1.17798C7.62903 1.36867 7.55573 1.55238 7.42325 1.68954L4.51416 4.59863C4.37778 4.73497 4.19282 4.81157 3.99998 4.81157C3.80713 4.81157 3.62218 4.73497 3.4858 4.59863L0.576705 1.68954C0.440363 1.55316 0.36377 1.36821 0.36377 1.17536C0.36377 0.982513 0.440363 0.797562 0.576705 0.661178Z" 
                fill={color ?? "#B1BBCE"}
            />
        </svg>
    );
}
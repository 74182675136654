import bgImg1 from "../images/background/topbg.jpg";
import img1 from "../images/background/slide_home01.png";

const dataSlider = [
  {
    id: 1,
    title: "Rebuilding Trust in Crypto Influencers",
    desc: `Fostering Transparency, and Uniting the Community.\nUnlock Earnings: Bet and Invest in Telegram, Twitter, and Instagram KOL’s ' Performance with KOLWatch.`,
    bgImg: bgImg1,
    img: img1,
  },
  // {
  //     id: 2,
  //     title: 'CYbox nft collectionS for everyone',
  //     desc : 'Nulla ornare sagittis placerat nunc sit tempus enim. Accumsan pellentesque ipsum felis tristique at proin vel turpis.',
  //     bgImg: bgImg2,
  //     classAction: 'two'
  // },
  // {
  //     id: 3,
  //     title: 'CYbox nft collectionS for everyone',
  //     desc : 'Nulla ornare sagittis placerat nunc sit tempus enim. Accumsan pellentesque ipsum felis tristique at proin vel turpis.',
  //     bgImg: bgImg3,
  //     classAction: 'three'
  // },
  // {
  //     id: 4,
  //     title: 'CYbox nft collectionS for everyone',
  //     desc : 'Nulla ornare sagittis placerat nunc sit tempus enim. Accumsan pellentesque ipsum felis tristique at proin vel turpis.',
  //     bgImg: bgImg1,
  //     img : img1
  // },
  // {
  //     id: 5,
  //     title: 'CYbox nft collectionS for everyone',
  //     desc : 'Nulla ornare sagittis placerat nunc sit tempus enim. Accumsan pellentesque ipsum felis tristique at proin vel turpis.',
  //     bgImg: bgImg2,
  //     classAction: 'two'
  // },
];

export default dataSlider;

import { ReactElement } from "react";
import { Stack } from "@mui/material";
import { InfoIcon, TableBottomArrow, TableTopArrow } from "src/assets/icons";

export default function Sort(): ReactElement {
  return (
    <Stack direction="row" alignItems={'center'} spacing={'6px'} pl={'6px'}>
      <Stack direction="column" gap={'1px'}>
        <TableTopArrow />
        <TableBottomArrow />
      </Stack>
      <InfoIcon />
    </Stack >
  )
}
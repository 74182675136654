
import t1 from 'src/assets/images/top/t1.png';
import t2 from 'src/assets/images/top/t2.png';
import t3 from 'src/assets/images/top/t3.png';
import t4 from 'src/assets/images/top/t4.png';
import t5 from 'src/assets/images/top/t5.png';
import t6 from 'src/assets/images/top/t6.png';
import t7 from 'src/assets/images/top/t7.png';
import t8 from 'src/assets/images/top/t8.png';
import t9 from 'src/assets/images/top/t9.png';
import t10 from 'src/assets/images/top/t10.png';
import t11 from 'src/assets/images/top/t11.png';
import t12 from 'src/assets/images/top/t12.png';
import t13 from 'src/assets/images/top/t13.png';
import t14 from 'src/assets/images/top/t14.png';
import t15 from 'src/assets/images/top/t15.png';

const dataTopHeader = [
    {
        id: 1,
        img: t1,
        text: '#1 Chris'
    },
    {
        id: 2,
        img: t2,
        text: '#2 Anthony'
    },
    {
        id: 3,
        img: t3,
        text: '#3 Layah Heilpern'
    },
    {
        id: 4,
        img: t4,
        text: '#4 George'
    },
    {
        id: 5,
        img: t5,
        text: '#5 Natalie Brunell'
    },
    {
        id: 6,
        img: t6,
        text: '#6 ???'
    },
    {
        id: 7,
        img: t7,
        text: '#7 ???'
    },
    {
        id: 8,
        img: t8,
        text: '#8 ???'
    },
    {
        id: 9,
        img: t9,
        text: '#9 ???'
    },
    {
        id: 10,
        img: t10,
        text: '#10 ???'
    },
    {
        id: 11,
        img: t11,
        text: '#11 ???'
    },
    {
        id: 12,
        img: t12,
        text: '#12 ???'
    },
    {
        id: 13,
        img: t13,
        text: '#13 ???'
    },
    {
        id: 14,
        img: t14,
        text: '#14 ???'
    },
    {
        id: 15,
        img: t15,
        text: '#15 ???'
    }
]

export default dataTopHeader;
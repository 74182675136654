export function YouTubeIcon({
    color,
    width,
    height
}: { color?: string, width?: string, height?: string }) {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg"
            width={width ?? "23"} 
            height={height ?? "23"} 
            viewBox="0 0 23 23"
            fill="none"
        >
            <path 
               d="M18.79 6.7037C18.7172 6.41812 18.5102 6.2025 18.2735 6.13637C17.8537 6.0185 15.8134 5.75016 11.5009 5.75016C7.18845 5.75016 5.14912 6.0185 4.72649 6.13637C4.49266 6.20154 4.28566 6.41716 4.21186 6.7037C4.10741 7.11004 3.83428 8.81204 3.83428 11.5002C3.83428 14.1873 4.10741 15.8893 4.21186 16.2976C4.2847 16.5822 4.4917 16.7978 4.72745 16.863C5.14912 16.9818 7.18749 17.2502 11.5 17.2502C15.8125 17.2502 17.8528 16.9818 18.2744 16.864C18.5083 16.7988 18.7153 16.5832 18.7891 16.2966C18.8945 15.8903 19.1667 14.1835 19.1667 11.5002C19.1667 8.81683 18.8945 7.111 18.79 6.7037ZM20.6463 6.22741C21.0843 7.93516 21.0843 11.5002 21.0843 11.5002C21.0843 11.5002 21.0843 15.0652 20.6463 16.7729C20.4029 17.7169 19.6909 18.4596 18.7891 18.7107C17.1513 19.1668 11.5 19.1668 11.5 19.1668C11.5 19.1668 5.85253 19.1668 4.21186 18.7107C3.30624 18.4557 2.59516 17.714 2.35461 16.7729C1.91666 15.0652 1.91666 11.5002 1.91666 11.5002C1.91666 11.5002 1.91666 7.93516 2.35461 6.22741C2.59803 5.28345 3.31007 4.54075 4.21186 4.28966C5.85349 3.8335 11.5009 3.8335 11.5009 3.8335C11.5009 3.8335 17.1513 3.8335 18.7891 4.28966C19.6937 4.54458 20.4058 5.28633 20.6463 6.22741ZM9.58428 14.8543V8.146L15.3343 11.5002L9.58428 14.8543Z" 
                fill={color ?? "#B1BBCE"}
            />
        </svg>
    );
}

import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { Middleware } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { _TOKEN_NAME } from './urls';

export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        console.log(action.payload);
        if (action?.payload.status === 401) {
            // window.location.replace(LOGIN_URL); 
            // console.log(action.payload?.data?.message);
            toast.error(action.payload?.data?.message);

            localStorage.removeItem(_TOKEN_NAME); return;
        }
        if (action?.payload.status === 'FETCH_ERROR') { toast.error('Server Error'); return; }

        if (action.payload?.status === 409) {
            return next(action);
        }
        if (action.payload?.status === 400 ||
            action.payload?.status === 404 ||

            action.payload?.status === 500) {

            if (Array.isArray(action.payload?.data?.message)) {
                action.payload?.data?.message.forEach((_mssg: any) => {
                    toast.error(_mssg);
                });
            }
            else {
                toast.error(action.payload?.data?.message);
            }
        }
    }

    return next(action);
};
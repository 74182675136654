import React, { useEffect, ChangeEvent, useRef } from "react";
import { Stack, Typography } from '@mui/material';
import { colors } from 'src/colors';
import { useNavigate } from 'react-router-dom';
import { Button, TextField } from 'src/components';
import { VerificationCodeIcon, LeftArrowIcon } from 'src/assets/icons';
import { useFormik, Form, FormikProvider } from 'formik';
import { initialValues, validationSchema } from 'src/formValidations/verificationCode';
import { LOGIN_CONFIRM_PASSWORD_URL } from 'src/config/urls';
import { useVerificationEmailMutation } from "src/api/auth";
import { useAppDispatch, useAppSelector } from "src/hooks/ReduxHooks";
import { toast } from "react-toastify";
import { setForgotUser } from "src/slices/mainSlice";
import ReactCodeInput from "react-verification-code-input";

const VerificationCode: React.FC<{}> = () => {
    const [verification] = useVerificationEmailMutation()
    const navigate = useNavigate();
    const Dispatch = useAppDispatch();
    const ref = useRef<any>();
    const userForgot = useAppSelector(store => store.mainSlice.forgotUser);
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values: any) => {
            console.log(values);
            const body = {
                email: userForgot.email ?? '',
                verificationCode: values.code//1 + values.code2 + values.code3 + values.code4 + values.code5
            }
            verification(body)
                .unwrap()
                .then((res: any) => {
                    Dispatch(setForgotUser(
                        {
                            ...userForgot,
                            setPasswordToken: res.data.setPasswordToken

                        }
                    ));

                    navigate(LOGIN_CONFIRM_PASSWORD_URL);
                    toast.success(res.message);
                })
                .catch((err: any) => {
                    toast.error(err.message);
                });
        }
    });

    const { errors, touched, values, handleSubmit, setFieldValue } = formik;

    useEffect(() => {
        ref?.current?.focus();
    }, []);

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

        if (event.target.value.length === 1) {
            formik.setValues((prev: any) => { return { ...prev, [event.target.name]: event.target.value } });
            const nextIndex = +event.target.name.slice(4, 5);
            if (nextIndex < 6) {
                const nextSibling = document.querySelector<any>(
                    `input[name=code${nextIndex + 1}]`
                );
                if (nextIndex + 1 <= 5) {
                    nextSibling?.focus();
                }
            }
        } else if (event.target.value.length === 0) {
            formik.setValues((prev: any) => { return { ...prev, [event.target.name]: '' } });
        }
    }

    return (
        <FormikProvider value={formik}>
            <Form autoComplete='off' noValidate onSubmit={handleSubmit} >
                <Stack sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: colors.green0E2,
                    justifyContent: 'flex-start',
                    padding: '8px 24px 24px 24px',
                    gap: '16px',
                    height: '780px',
                    marginY: '25px',
                    marginRight: '22px'
                }}>
                    {/* <VerificationCodeIcon /> */}
                    <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                        gap={'12px'}
                        marginLeft={'13px'}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => navigate(-1)}
                    >
                        <LeftArrowIcon />
                        <Typography
                            fontSize={16}
                            fontWeight={600}
                            lineHeight={'60px'}
                            color={colors.white}
                        >
                            Back
                        </Typography>
                    </Stack>
                    <Stack gap={'.5px'}>
                        <Typography
                            fontSize={14}
                            fontWeight={400}
                            color={colors.grayB1}
                            lineHeight={'21px'}
                        >
                            Verification code
                        </Typography>
                        <Typography
                            fontSize={18}
                            fontWeight={700}
                            color={colors.white}
                            lineHeight={'27px'}
                        >
                            Check your email or phone to get your verification code
                        </Typography>
                    </Stack>
                    <ReactCodeInput
                        fields={5}
                        className="otp"
                        onChange={(value) =>
                            formik.setValues((prev: any) => { return { ...prev, code: values } })
                        } />
                    {/* <Stack flexDirection={'row'} gap={'16px'}>
                        <TextField
                            value={values.code1}
                            type={'number'}
                            fullWidth
                            variant='outlined'
                            name='code1'
                            id='code1'
                            inputRef={ref}
                            autoComplete='code1'
                            onChange={handleChange}
                        />
                        <TextField
                            value={values.code2}
                            type={'number'}
                            fullWidth
                            variant='outlined'
                            name='code2'
                            id='code2'
                            autoComplete='code2'
                            onChange={handleChange}
                        />
                        <TextField
                            value={values.code3}
                            type={'number'}
                            fullWidth
                            variant='outlined'
                            name='code3'
                            id='code3'
                            autoComplete='code3'
                            onChange={handleChange}
                        />
                        <TextField
                            value={values.code4}
                            type={'number'}
                            fullWidth
                            variant='outlined'
                            name='code4'
                            id='code4'
                            autoComplete='code4'
                            onChange={handleChange}
                        />
                        <TextField
                            value={values.code5}
                            type={'number'}
                            fullWidth
                            variant='outlined'
                            name='code5'
                            id='code5'
                            autoComplete='code5'
                            onChange={handleChange}
                        />
                    </Stack> */}
                    <Stack flexDirection={'row'} gap={'5px'} marginY={'5px'} >
                        <Typography
                            fontSize={16}
                            fontWeight={400}
                            lineHeight={'16px'}
                            color={colors.white}
                            sx={{
                                opacity: '80%'
                            }}
                        >
                            Didn't received code ?
                        </Typography>
                        <Typography
                            fontSize={16}
                            fontWeight={400}
                            lineHeight={'16px'}
                            color={colors.green}

                            sx={{
                                opacity: '80%',
                                cursor: 'pointer'
                            }}
                        >
                            Resend
                        </Typography>
                    </Stack>
                    <Stack display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
                        <Button
                            variant={"outlined"}
                            LabelColor={colors.black}
                            borderColor={colors.green22}
                            backgroundColor={colors.green}
                            bgHover={colors.green}
                            sx={{ height: 52, width: '265px' }}
                            type="submit"                        >
                            <Typography
                                fontSize={16}
                                fontWeight={700}
                                lineHeight={'16px'}
                                color={colors.white}
                            >
                                Next
                            </Typography>
                        </Button>
                    </Stack>
                </Stack>
            </Form>
        </FormikProvider>
    );
};

export default VerificationCode;
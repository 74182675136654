import { Stack, Card, CardContent, Typography, Grid } from '@mui/material';
import { colors } from 'src/colors';
import {  CircleIcon } from 'src/assets/icons';

export const Instagram = (props: any) => {
  const { item, className= '' } = props;

  return (<>
        {item.map((_item: any) => ( 
          <Grid className={className} item xs={2} sm={4} md={4} >
            <Card 
                sx={{ 
                    backgroundColor: colors.white,
                    boxShadow: 'none',
                    borderRadius: 0,
                    height: '100%'
                }}
            >
                    <Stack sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '7px',
                        padding: '10px',
                        // minHeight: '300px'
                    }}>
                        <Typography
                            fontSize={12}
                            fontWeight={500}
                            color={colors.black}
                            lineHeight={'20px'}
                        >
                            {_item.title}
                        </Typography>
                        <img 
                            src={_item.img} 
                            alt={`img-${_item.id}`}
                        />
                        <Stack>
                            <Typography
                                fontSize={11}
                                fontWeight={400}
                                color={colors.black}
                                lineHeight={'20px'}
                            >
                                {_item.description}
                            </Typography>
                        </Stack>
                    </Stack>
                </Card>
            </Grid>
        ))}
        
    </>);
}

export default Instagram;
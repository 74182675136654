import React, { Dispatch, SetStateAction } from "react";
import { Stack, Typography } from '@mui/material';
import { colors } from 'src/colors';
import { Input, Button } from 'src/components';
import { LeftArrowIcon } from 'src/assets/icons';
import TextArea from "src/components/input/TextArea";
import { useAppDispatch, useAppSelector } from "src/hooks/ReduxHooks";
import { useUpdateProfileMutation } from "src/api/user";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { setUpdateUserInfo } from "src/slices/mainSlice";

export const initialValues: {
  about: string
  favoritePost: string[],
  biography: string,
  relevantCalls: string,
} = {
  about: '',
  favoritePost: ['', '', ''],
  biography: '',
  relevantCalls: ''
}

const CompleteYourProfile: React.FC<{ setStep: Dispatch<SetStateAction<number>> }> = ({ setStep }) => {
  const Dispatch = useAppDispatch();
  const [updateProfile] = useUpdateProfileMutation();
  const user = useAppSelector(store => store.mainSlice.user);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {

      updateProfile({ data: values, token: user.accessToken ?? '' })
        .unwrap()
        .then((res) => {
          Dispatch(setUpdateUserInfo(values));
          toast.success('Update Profile Success');
          setStep((prev: number) => prev + 1)
        })
    }
  });
  const { getFieldProps } = formik;
  return (
    <form onSubmit={formik.handleSubmit}>

      <Stack sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors.green0E2,
        justifyContent: 'flex-start',
        padding: '8px 24px 60px 24px',
        gap: '20px',
        // height: '780px',
        marginY: '25px',
        marginRight: '22px'
      }}>
        <Stack
          flexDirection={'row'}
          alignItems={'center'}
          gap={'12px'}
          marginLeft={'13px'}
          sx={{ cursor: 'pointer' }}
          onClick={() => setStep((prev: number) => prev - 1)}
        >
          <LeftArrowIcon />
          <Typography
            fontSize={16}
            fontWeight={600}
            lineHeight={'60px'}
            color={colors.white}
          >
            Back
          </Typography>
        </Stack>
        <Stack gap={'.5px'}>
          <Typography
            fontSize={14}
            fontWeight={400}
            color={colors.grayB1}
            lineHeight={'21px'}
          >
            About you
          </Typography>
          <Typography
            fontSize={18}
            fontWeight={700}
            color={colors.white}
            lineHeight={'27px'}
            whiteSpace={'pre-wrap'}
          >
            Let’s Complete your profile
          </Typography>
        </Stack>

        <TextArea
          label="About:"
          id="about"
          {...getFieldProps('about')}
        />
        <Input
          label="Favorite Post (url link):"
          id="favoritePost"
          value={formik.values.favoritePost[0]}
          onChange={(value) => {
            formik.setValues((prev: any) => {
              return {
                ...prev,
                favoritePost: [value.target.value, prev.favoritePost[1], prev.favoritePost[2]]
              }
            });
          }}
        // {...getFieldProps('favoritePost')}

        />
        <Input
          label="Favorite Post (url link):"
          id="favoritePost"
          value={formik.values.favoritePost[1]}
          onChange={(value) => {
            formik.setValues((prev: any) => {
              return {
                ...prev,
                favoritePost: [prev.favoritePost[0], value.target.value, prev.favoritePost[2]]
              }
            });
          }}

        />
        <Input
          label="Favorite Post (url link):"
          id="favoritePost"

          value={formik.values.favoritePost[2]}
          onChange={(value) => {
            formik.setValues((prev: any) => {
              return {
                ...prev,
                favoritePost: [prev.favoritePost[0], prev.favoritePost[1], value.target.value]
              }
            });
          }}

        />
        <TextArea
          label="Who are you (Biography):"
          id="biography"
          {...getFieldProps('biography')}

        />
        <TextArea
          label="Relevant Calls and Projects and community service"
          id="relevantCalls"
          {...getFieldProps('relevantCalls')}

        />


        <Stack display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
          <Button
            variant={"outlined"}
            LabelColor={colors.black}
            borderColor={colors.green22}
            backgroundColor={colors.green}
            bgHover={colors.green}
            sx={{ height: 52, width: '265px' }}
            type="submit"          >
            <Typography
              fontSize={16}
              fontWeight={700}
              lineHeight={'16px'}
              color={colors.white}
            >
              Next
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default CompleteYourProfile;
import { ReactElement, useState } from 'react';
import { Stack, Typography, Box } from '@mui/material';
import { colors } from 'src/colors';
import { UserLogo, CircleIcon, MoreIcon } from 'src/assets/icons';
import { Button } from 'src/components';

export default function Card(): ReactElement {
   
    return (<>
        <Stack sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '25px'
            }}>
                <Stack sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Stack flexDirection={'row'} alignItems={'center'} gap={'15px'}>
                        <Stack flexDirection={'row'} alignItems={'center'} gap={'4px'}>
                            <UserLogo style={{ borderRadius: '50%' }} width={'44px'} height={'44px'} />
                            <Typography 
                                fontSize={18}
                                fontWeight={700}
                                color={colors.white}
                                lineHeight={'27px'}
                            >
                                Wesley
                            </Typography>
                        </Stack>
                        
                        <CircleIcon width={'6px'} height={'6px'} />
                        <Typography 
                            fontSize={14}
                            fontWeight={400}
                            color={colors.grayB1}
                            lineHeight={'18px'}
                        >
                            22.10.2023
                        </Typography>
                        <CircleIcon width={'6px'} height={'6px'} />
                        <Typography 
                            fontSize={14}
                            fontWeight={400}
                            color={colors.grayB1}
                            lineHeight={'18px'}
                        >
                            10:34
                        </Typography>
                    </Stack>
                    
                </Stack>
                <Typography 
                    fontSize={14}
                    fontWeight={400}
                    color={colors.grayB1}
                    lineHeight={'18px'}
                >
                    President Joe Biden touted a new agreement reached with the European Union to ease Trump-era tariffs on aluminum and steel as a "major breakthrough" that would serve to both strengthen the US steel industry and combat the global climate crisis.
                </Typography>
        </Stack> 
        </>
    );
}
import { Stack, Card, CardContent, Typography, Grid} from '@mui/material';
import { colors } from '../../colors';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
interface Props {
    width?: string;
    items?: any;
    className?: string;
}

export const MyCard = (props: Props) => {
    const navigate = useNavigate();
    const { width, items, className = '', ...rest } = props;
 
  return (
        <Grid item 
            sx={{ cursor: 'pointer' }}
            xs={6} sm={4} md={3}
            id='card-main' 
            onClick={() => navigate(items.url)}
            {...rest}
        >
        <Card className={className} sx={{ 
                width: width,
                height: '174.21px',
                backgroundColor: 'rgba(20, 194, 163, 0.12)',
                borderRadius: '11px'
            }}>
                <CardContent sx={{
                    gap: '25.34px',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Stack sx={{
                        gap: '25.34px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}>
                        <img src={items.img} width={'39.07px'} height={'39.07px'} alt={`img-${items.img}`}  />
                        {
                            items.text.length > 0 && <Stack sx={{
                                backgroundColor: colors.gray2B,
                                borderRadius: '5.28px',
                                height: '23.22px',
                                padding: '2.11px 6.19px 2.11px 6.33px'
                            }}>
                                <Typography
                                fontSize={12.67}
                                fontWeight={500}
                                color={colors.white}
                                lineHeight={'19px'}
                                >
                                    {items.text}
                                </Typography>
                            </Stack>
                        }
                    </Stack>
                    <Typography
                        fontSize={22}
                        fontWeight={700}
                        color={colors.white}
                        lineHeight={'33.26px'}
                        className='card-title'
                    >
                        {items.title}
                    </Typography>
                </CardContent>
            </Card>
        </Grid >
    // </Grid>
  );
}

export default MyCard;
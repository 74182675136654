import { Stack, Card, CardContent, Typography } from '@mui/material';
import { colors } from 'src/colors';
import { Warning } from 'src/assets/icons';

interface Props {
    width?: string;
    height?: string;
    items?: any;
    className?: string;
}

export const RankingCard = (props: Props) => {

 const { width, items, className = '' } = props;
 
  return (
       <Card className={className} sx={{ 
            width: width ? width : '100%',
            backgroundColor: colors.green12,
            borderRadius: 0
        }}>
            <CardContent sx={{
                gap: '10px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
            }}>
                
                <Stack sx={{
                    gap: '10px',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Typography
                        fontSize={12}
                        fontWeight={600}
                        color={colors.grayB1}
                        lineHeight={'20px'}
                    >
                        {items.text}
                    </Typography>
                    <Stack>
                        <Typography
                            fontSize={18}
                            fontWeight={700}
                            color={colors.white}
                            lineHeight={'24px'}

                        >
                            {items.price}
                        </Typography>
                        {items.pricePercent && <Typography
                            fontSize={12}
                            fontWeight={600}
                            color={colors.red}
                            lineHeight={'18px'}
                        >
                            {items.pricePercent}
                        </Typography>}
                    </Stack>
                </Stack>
                {items.img && <Warning />}
                
            </CardContent>
        </Card>
  );
}

export default RankingCard;
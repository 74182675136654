import anthonyImg from 'src/assets/images/fake/anthonyImg.png';
import anthonyAboutImg1 from 'src/assets/images/fake/anthony/aboutImg1.png';
import anthonyAboutImg2 from 'src/assets/images/fake/anthony/aboutImg2.png';
import anthonyInstagramImg1 from 'src/assets/images/fake/anthony/instagramImg1.png';
import anthonyInstagramImg2 from 'src/assets/images/fake/anthony/instagramImg2.png';
import anthonyInstagramImg3 from 'src/assets/images/fake/anthony/instagramImg3.png';
import anthonyFeedImg1 from 'src/assets/images/fake/anthony/feedImg1.png';
import anthonyFeedImg2 from 'src/assets/images/fake/anthony/feedImg2.png';
import anthonyYoutubeImg1 from 'src/assets/images/fake/anthony/youtubeImg1.png';
import anthonyYoutubeImg2 from 'src/assets/images/fake/anthony/youtubeImg2.png';
import anthonyYoutubeImg3 from 'src/assets/images/fake/anthony/youtubeImg3.png';

import layahImg from 'src/assets/images/fake/layahImg.png';
import layahAboutImg1 from 'src/assets/images/fake/layah/aboutImg1.png';
import layahAboutImg2 from 'src/assets/images/fake/layah/aboutImg2.png';
import layahFeedImg1 from 'src/assets/images/fake/layah/feedImg1.png';
import layahFeedImg2 from 'src/assets/images/fake/layah/feedImg2.png';
import layahInstagramImg1 from 'src/assets/images/fake/layah/instagramImg1.png';
import layahInstagramImg2 from 'src/assets/images/fake/layah/instagramImg2.png';
import layahInstagramImg3 from 'src/assets/images/fake/layah/instagramImg3.png';
import layahYoutubeImg1 from 'src/assets/images/fake/layah/youtubeImg1.png';
import layahYoutubeImg2 from 'src/assets/images/fake/layah/youtubeImg2.png';
import layahYoutubeImg3 from 'src/assets/images/fake/layah/youtubeImg3.png';

import chrisImg from 'src/assets/images/fake/chrisImg.png';
import chrisAboutImg1 from 'src/assets/images/fake/chris/aboutImg1.png';
import chrisAboutImg2 from 'src/assets/images/fake/chris/aboutImg2.png';
import chrisFeedImg1 from 'src/assets/images/fake/chris/feedImg1.png';
import chrisFeedImg2 from 'src/assets/images/fake/chris/feedImg2.png';
import chrisInstagramImg1 from 'src/assets/images/fake/chris/instagramImg1.png';
import chrisInstagramImg2 from 'src/assets/images/fake/chris/instagramImg2.png';
import chrisInstagramImg3 from 'src/assets/images/fake/chris/instagramImg3.png';
import chrisYoutubeImg1 from 'src/assets/images/fake/chris/youtubeImg1.png';
import chrisYoutubeImg2 from 'src/assets/images/fake/chris/youtubeImg2.png';
import chrisYoutubeImg3 from 'src/assets/images/fake/chris/youtubeImg3.png';

import georgeImg from 'src/assets/images/fake/georgeImg.png';
import georgeAboutImg1 from 'src/assets/images/fake/george/aboutImg1.png';
import georgeAboutImg2 from 'src/assets/images/fake/george/aboutImg2.png';
import georgeFeedImg1 from 'src/assets/images/fake/george/feedImg1.png';
import georgeFeedImg2 from 'src/assets/images/fake/george/feedImg2.png';
import georgeInstagramImg1 from 'src/assets/images/fake/george/instagramImg1.png';
import georgeInstagramImg2 from 'src/assets/images/fake/george/instagramImg2.png';
import georgeInstagramImg3 from 'src/assets/images/fake/george/instagramImg3.png';
import georgeYoutubeImg1 from 'src/assets/images/fake/george/youtubeImg1.png';
import georgeYoutubeImg2 from 'src/assets/images/fake/george/youtubeImg2.png';
import georgeYoutubeImg3 from 'src/assets/images/fake/george/youtubeImg3.png';

import natalieImg from 'src/assets/images/fake/natalieImg.png';
import natalieAboutImg1 from 'src/assets/images/fake/natalie/aboutImg1.png';
import natalieAboutImg2 from 'src/assets/images/fake/natalie/aboutImg2.png';
import natalieFeedImg1 from 'src/assets/images/fake/natalie/feedImg1.png';
import natalieFeedImg2 from 'src/assets/images/fake/natalie/feedImg2.png';
import natalieInstagramImg1 from 'src/assets/images/fake/natalie/instagramImg1.png';
import natalieInstagramImg2 from 'src/assets/images/fake/natalie/instagramImg2.png';
import natalieInstagramImg3 from 'src/assets/images/fake/natalie/instagramImg3.png';
import natalieYoutubeImg1 from 'src/assets/images/fake/natalie/youtubeImg1.png';
import natalieYoutubeImg2 from 'src/assets/images/fake/natalie/youtubeImg2.png';
import natalieYoutubeImg3 from 'src/assets/images/fake/natalie/youtubeImg3.png';

const dataRankings = [
    {
        id: '1',
        img: anthonyImg,
        nickname: 'Anthony',
        fullname: 'Anthony',
        youtube: 50,
        telegram: 20,
        instagram: 88,
        twitter: 74,
        reddit: 85,
        about: [
            { id: 1, img: anthonyAboutImg1 },
            { id: 2, img: anthonyAboutImg2 },
            { id: 3, img: anthonyAboutImg1 },
            { id: 4, img: anthonyAboutImg2 },
        ],
        youtubeDetails: [
            { id: 1, content: 'How Andrew Tate Got Rich', img: anthonyYoutubeImg1, view: '4.5M', lastSeen: '1 year' },
            { id: 2, content: 'Building A Million Dollar Milksha...', img: anthonyYoutubeImg2, view: '940', lastSeen: '1 day' },
            { id: 3, content: 'Bitcoin Will Fly In 2024', img: anthonyYoutubeImg3, view: '65K', lastSeen: '3 days' }
        ],
        instagramDetails: [
            { id: 1, title: 'pompglobal', img: anthonyInstagramImg1, description: `pompglobal` },
            { id: 2, title: 'pompglobal', img: anthonyInstagramImg2, description: `pompglobal` },
            { id: 3, title: 'pompglobal', img: anthonyInstagramImg3, description: `pompglobal` }
        ],
        twitterDetails: [
            { id: 1, title: 'Pomp', subTitle: '@APompliano', time: '11:31', lastSeen: 'Nov 13, 2023', content: 'My rules of business: Build shit people want, never give up, avoid assholes, question assumptions, learn new ideas & always reward ambition' },
            { id: 2, title: 'Pomp', subTitle: '@APompliano', time: '11:31', lastSeen: 'Nov 13, 2023', content: 'Every once in awhile I talk with a true operator. The hard stuff. The dirty details. @DanReese21 was full of insights & revealed lots of secrets behind The Milkshake Factory.' },
            { id: 3, title: 'Pomp', subTitle: '@APompliano', time: '11:31', lastSeen: 'Nov 13, 2023', content: 'The man is pushing civilization forward at a faster pace than anyone else alive, yet he has a life that almost no one would want.' }
        ],
        feeds: [
            { id: 1, title: 'TokenVisit', img: anthonyFeedImg1, text: `You mean tweeting?`, date: '11.10.2023', time: '15:34' },
            { id: 2, title: 'Matt fiford', img: anthonyFeedImg2, text: `Very true`, date: '12.10.2023', time: '06:34' }
        ],
        FeedMessageCount: '12',
        FeedLikeCount: '219',
        commentDate: '10.11.2023',
        commentTime: '10:34',
        feedText: `Great operators don't waste their time with bullshit.\nThey are too busy executing.`,
        commentText: `This company is unlocking 3% mortgage rates for home buyers even though interest rates are much higher.\nHow are they doing it?\n<span style="text-decoration: underline">@NewsLambert</span>\nbreaks it down.`,
        aboutText: `Hey everyone!\nMy name is Anthony Pompliano, but most people know me as “Pomp.” I write this newsletter three times per week, and I share my analysis on the latest in business, finance, the economy, and bitcoin.`,
        relevantText: `Our audience includes everyone from legendary investors on Wall Street to CEOs of publicly traded companies to the average retail investor who is trying to get started. We work hard to bring you actionable insights on what’s happening in the economy. Subscribe now & join our community of over 235,000 investors!`,
        information: `We're living in unprecedented times. The US government created trillions of dollars and artificially suppressed interest rates near 0%. This led to an explosion in asset prices and inflation, but now the Fed has been forced to reverse course.\n\nAs they are increasing interest rates and destroying demand, asset prices are falling and consumers are wondering how bad the recession will be. These are all obvious questions that I try to wrap my head around each week.\n\nThis is not investment advice. It’s important to do your own research. We aim to be a part of that research, helping distill complex trends and ideas into simple terms. To better understand what's happening in the global economy, and what to do about it, join The Pomp Letter today.`
    },
    {
        id: '2',
        img: layahImg,
        nickname: 'Layah',
        fullname: 'Layah Heilpern',
        youtube: 80,
        telegram: 30,
        instagram: 46,
        twitter: 63,
        reddit: 33,
        about: [
            { id: 1, img: layahAboutImg1 },
            { id: 2, img: layahAboutImg2 },
            { id: 3, img: layahAboutImg1 },
        ],
        youtubeDetails: [
            { id: 1, content: 'LUKE BELMAR: A Glitch in the Matrix | LAYAH HEILPERN!', img: layahYoutubeImg1, view: '115K', lastSeen: '10 days' },
            { id: 2, content: 'THE CRYPTO BULL MARKET IS COMING HERE’S WHAT YOU ....', img: layahYoutubeImg2, view: '9.3K', lastSeen: '3 weeks' },
            { id: 3, content: 'I WAS IN A BOMB SHELTER SCARED FOR MY LIFE ISRAEL VS PALE...', img: layahYoutubeImg3, view: '18K', lastSeen: '1 month' }
        ],
        instagramDetails: [
            { id: 1, title: 'layahheilpern', img: layahInstagramImg1, description: `pompglobal` },
            { id: 2, title: 'layahheilpern', img: layahInstagramImg2, description: `That new dress feeling! ` },
            { id: 3, title: 'layahheilpern', img: layahInstagramImg3, description: `I take responsibility for that large bitcoin sell off. Sorry I had some ...` }
        ],
        twitterDetails: [
            { id: 1, title: 'Layah Heilpern', subTitle: '@LayahHeilpern', time: '11:31', lastSeen: 'Nov 13, 2023', content: `If #bitcoin became the world's reserve currency and hit $1 million, Peter would still be screaming it's a ponzi...\nI think it's fair to stay I won this round.\n@PeterSchiff\nI'm inviting you for a rematch on my podcasts, whatdya say?` },
            { id: 2, title: 'Layah Heilpern', subTitle: '@LayahHeilpern', time: '11:31', lastSeen: 'Nov 13, 2023', content: 'Just waiting on $doge etf now' },
            { id: 3, title: 'Layah Heilpern', subTitle: '@LayahHeilpern', time: '11:31', lastSeen: 'Nov 13, 2023', content: 'XRP pumps on fake blackrock news \n\nOnly in crypto' }
        ],
        feeds: [
            { id: 1, title: 'uncasual takes', img: layahFeedImg1, text: `Its called jail lol`, date: '11.10.2023', time: '15:34' },
            { id: 2, title: 'BBroccoli2', img: layahFeedImg2, text: `they put a WEF puppet instead of CZ, this is bad`, date: '12.10.2023', time: '06:34' }
        ],
        FeedMessageCount: '12',
        FeedLikeCount: '219',
        feedText: `Thank you for everything CZ!\n\nExcited to see what’s next for Binance and looking forward to see where you go from here! `,
        commentText: `CZ did more for bitcoin and crypto than you did screaming behind your keyboard.`,
        commentDate: '22.11.2023',
        commentTime: '10:34',
        aboutText: `📈| Crypto Investor\n💰| Podcast Host\n💸| Marketing consultantJoin my FREE and exclusive group\n📱Manager @gideon.___👩\n🍳 Healthy Baking tips! 🥞`,
        relevantText: `Host of The Layah Heilpern Show: <a style="text-decoration: underline">http://youtube.com/layahheilpern</a> Freedom\nMaximalist • Marketing Consultant • Web 3 • Manager\n<span style="text-decoration: underline">@gideonheilpern2</span>`,
        information: `Host of The Layah Heilpern Show: <a style="text-decoration: underline">http://youtube.com/layahheilpern</a> Freedom Maximalist • Marketing Consultant • Web\n3 • Manager\n<span style="text-decoration: underline">@gideonheilpern2</span>`
    },
    {
        id: '3',
        img: chrisImg,
        nickname: 'Chris',
        fullname: 'Chris',
        youtube: 220,
        telegram: 75,
        instagram: 500,
        twitter: 200,
        reddit: 67,
        about: [
            { id: 1, img: chrisAboutImg1 },
            { id: 2, img: chrisAboutImg2 },
            { id: 3, img: chrisAboutImg1 },
        ],
        youtubeDetails: [
            { id: 1, content: 'BITCOIN: MICHAEL SAYLOR SHOCKING PREDICTION!!!', img: chrisYoutubeImg1, view: '121K', lastSeen: '3 days' },
            { id: 2, content: 'Bitcoin & Ethereum: I can NOT believe my eyes…', img: chrisYoutubeImg2, view: '55K', lastSeen: '5 days' },
            { id: 3, content: 'Bitcoin!!!! NOW!!!!!!!!', img: chrisYoutubeImg3, view: '45K', lastSeen: '6 days' }
        ],
        instagramDetails: [
            { id: 1, title: 'mm_crypto_official_', img: chrisInstagramImg1, description: `#BTC & #ETH TRADE UPDATE!` },
            { id: 2, title: 'mm_crypto_official_', img: chrisInstagramImg2, description: `against Bitcoin! 👇` },
            { id: 3, title: 'mm_crypto_official_', img: chrisInstagramImg3, description: `#ETHEREUM 3 YEAR CHART!!!!` }
        ],
        twitterDetails: [
            { id: 1, title: 'MMCrypto', subTitle: '@MMCrypto', time: '14:34', lastSeen: 'Nov 15, 2023', content: '#Bitcoin has the same number of users as the internet in 1997.\n\nYou are early.' },
            { id: 2, title: 'MMCrypto', subTitle: '@MMCrypto', time: '13:26', lastSeen: 'Nov 14, 2023', content: `If a massage doesn’t give you excruciating pain you are being scammed!!!!` },
            { id: 3, title: 'MMCrypto', subTitle: '@MMCrypto', time: '16:51', lastSeen: 'Nov 14, 2023', content: '#BTC even winning at NFT Volume!' }
        ],
        feeds: [
            { id: 1, title: 'Sir 3rd Hokage', img: chrisFeedImg1, text: `I think so, too! There are too many factors to mention. But one thing is that #Binance  is still the number 1 exchange.`, date: '11.10.2023', time: '15:34' },
            { id: 2, title: 'K4r4n P4tel #IBCgang', img: chrisFeedImg2, text: `Unfortunately, Their intimidation tactics worked, and we saw one of the greatest crypto leader go down. This was one of the crypto's shadiest hostile takeover by the cabal ran organisations.`, date: '12.10.2023', time: '06:34' }
        ],
        FeedMessageCount: '12',
        FeedLikeCount: '219',
        commentDate: '22.11.2023',
        commentTime: '10:34',
        feedText: `Binance was about to become the biggest company in the world (very soon).\n\nI will leave the following without any additional comment. But this is the new CEO of Binance.`,
        commentText: `Who got FUD out and will miss the whole Bull Market now?`,
        aboutText: `I educate the world about Bitcoin. Investor, Trader, YouTuber. Learn everything regarding Cryptocurrency and Blockchain related topics. Also join our Telegram Community! :) `,
        relevantText: `<span style="text-decoration: underline">#Bitcoin</span> Price is manipulated\nWhat are the consequences? Well, no <span style="text-decoration: underline">#ETF</span> can be accepted by the SEC!\But wat does such an ETF mean? If you want it to be approved you must accept the same set of rules from the stock market being applied to the Bitcoin market.\nThus you must sacrifice a certain degree of Freedom of Bitcoin $BTC.`,
        information: `Shop with KAI-CHING (KAIC) to save time and money. How? Pay less if you pay with KAIC in hundreds of shops & earn extra KAIC while playing & shopping on KAIKAI and KAIKAINOW.\n\nKAIKAINOW is a new innovation in personalised smartphone lock screen technology.\n\nIt’s an opt-in capability that provides a personalized experience, allowing users to stay informed and engaged with the world around them without the hassle of unlocking their phones. Curated content, including news, language learning, games, and trending stories can be interspersed between users’ very own personal wallpaper pictures.\n\nFive smartphone providers have already integrated KAIKAINOW into their devices providing a seamless experience to their users.`
    },
    {
        id: '4',
        img: georgeImg,
        nickname: 'George',
        fullname: 'George',
        youtube: 40,
        telegram: 90,
        instagram: 80,
        twitter: 70,
        reddit: 87,
        about: [
            { id: 1, img: georgeAboutImg1 },
            { id: 2, img: georgeAboutImg2 },
            { id: 3, img: georgeAboutImg1 },
        ],
        youtubeDetails: [
            { id: 1, content: 'BITCOIN: MICHAEL SAYLOR SHOCKING PREDICTION!!!', img: georgeYoutubeImg1, view: '121K', lastSeen: '3 days' },
            { id: 2, content: 'Bitcoin & Ethereum: I can NOT believe my eyes…', img: georgeYoutubeImg2, view: '55K', lastSeen: '5 days' },
            { id: 3, content: 'Bitcoin!!!! NOW!!!!!!!!', img: georgeYoutubeImg3, view: '45K', lastSeen: '6 days' }
        ],
        instagramDetails: [
            { id: 1, title: 'cryptosrus', img: georgeInstagramImg1, description: `#Avalanche #AVAX recently confirmed a double bottom on the daily chart, surpassing its price target by nearly 50%.Risk-on sentiment is strong the #Bitcoin and #crypto markets heading into full bull market season!! 🐂🚀` },
            { id: 2, title: 'cryptosrus', img: georgeInstagramImg2, description: `#Binance is making a huge change to #bitcoin and #crypto wallets` },
            { id: 3, title: 'cryptosrus', img: georgeInstagramImg3, description: `Why #Solana Is Going PARABOLIC#SOL #crypto #cryptocurrency` }
        ],
        twitterDetails: [
            { id: 1, title: 'CryptosRus', subTitle: '@CryptosR_Us', time: '14:34', lastSeen: 'Nov 15, 2023', content: 'I forgot, did we have a dip yesterday?' },
            { id: 2, title: 'CryptosRus', subTitle: '@CryptosR_Us', time: '13:26', lastSeen: 'Nov 14, 2023', content: "I took advantage of yesterday's dip $avax :)" },
            { id: 3, title: 'CryptosRus', subTitle: '@CryptosR_Us', time: '16:51', lastSeen: 'Nov 14, 2023', content: 'Analysts pick top three crypto coins set to explode after a BlackRock ETF' }
        ],
        feeds: [
            { id: 1, title: 'dogetesla', img: georgeFeedImg1, text: `Time to build`, date: '11.10.2023', time: '15:34' },
            { id: 2, title: 'PMowl', img: georgeFeedImg2, text: `and then if everything goes to s**t, the Bitcoin will see the new lows?`, date: '12.10.2023', time: '06:34' }
        ],
        FeedMessageCount: '12',
        FeedLikeCount: '219',
        commentDate: '22.11.2023',
        commentTime: '10:34',
        feedText: `Argentina’s Pro-Bitcoin President Is Ready to 'Take a Chainsaw to the State'`,
        commentText: `<span style="text-decoration: underline">#Bitcoin</span> BREAKOUT Happening RIGHT NOW (More to Come)`,
        aboutText: `My story began in 2013 when I first heard about Bitcoin hitting $1200. Every news outlet was talking about how Bitcoin was a ‘ponzi’ scheme reaching new heights. At the time I was working in IT and thought that Bitcoin was the greatest invention ever created. Using computers to generate a digital currency worth real-world money, out of thin air…`,
        relevantText: `Even though my YT channel now has over 400k subs and over 20M views, my message\nis still the same in 2021. Focus on the future, havepatience, and HODL. Those who have\ncalled Bitcoin a ‘Ponzi have all seen the light and are now some of the biggest\nsupporters. Today we have the largest institutions, banks, financial companies,\nand conglomerates actively buying and supporting Bitcoin, blockchain, and altcoins. The\nfuture has just begun!`,
        information: `I started mining Litecoin and instantly became obsessed. Soon I had over a dozen mining rigs running in my basement, running at all times of the day. However, this phase of my life only lasted 4 months, as I decided to shut down my mining operations, due to a sharp fall in the market. I fell for the FUD, I didn’t have conviction and I didn’t HODL. Big mistake.`
    },
    {
        id: '5',
        img: natalieImg,
        nickname: 'Natalie',
        fullname: 'Natalie Brunell',
        youtube: 140,
        telegram: 80,
        instagram: 38,
        twitter: 130,
        reddit: 36,
        about: [
            { id: 1, img: natalieAboutImg1 },
            { id: 2, img: natalieAboutImg2 },
            { id: 3, img: natalieAboutImg1 },
        ],
        youtubeDetails: [
            { id: 1, content: 'CLIP: Vivek Ramaswamy on Root Causes of Wealth Concentra...', img: natalieYoutubeImg1, view: '445', lastSeen: '3 days' },
            { id: 2, content: "Vivek Ramaswamy: Reviving the U.S. Economy, Bitcoin's Promise...", img: natalieYoutubeImg2, view: '5.8K', lastSeen: '5 days' },
            { id: 3, content: 'James Lavish: Disaster in Bond Market Auction, Chinese Bank ...', img: natalieYoutubeImg3, view: '22K Views', lastSeen: '6 days' }
        ],
        instagramDetails: [
            { id: 1, title: 'nataliebrunell', img: natalieInstagramImg1, description: `It was an honor to interview independent candidate for President @robertfkennedyjr at a private Sovereign Wealth Fund Institute event in Dallas, Texas today.` },
            { id: 2, title: 'nataliebrunell', img: natalieInstagramImg2, description: `Gazing out for my next pasta dish 🥰🧡🍝 #italy #italia #travel #tuscany` },
            { id: 3, title: 'nataliebrunell', img: natalieInstagramImg3, description: `Under the Tuscan clouds 🌥️🕊️` }
        ],
        twitterDetails: [
            { id: 1, title: 'Natalie Brunell', subTitle: '@natbrunell', time: '14:34', lastSeen: 'Nov 15, 2023', content: 'Loved ending my week with this little rant about money printing and #Bitcoin on Making Money with @cvpayne ! Thanks for having me and shout out to @PrestonPysh for my ending thought' },
            { id: 2, title: 'Natalie Brunell', subTitle: '@natbrunell', time: '13:26', lastSeen: 'Nov 14, 2023', content: 'I talked with Republican presidential candidate @VivekGRamaswamy about his origin story and early influences, the American Dream, the rise of corporatism and wealth concentration, bank bailouts, the promise of #Bitcoin and uniting a divided country. Let us know what you think.' },
            { id: 3, title: 'Natalie Brunell', subTitle: '@natbrunell', time: '16:51', lastSeen: 'Nov 14, 2023', content: 'I’m here to learn, share #Bitcoin, and try to find the positive side of things. Those who have been following my work, I really appreciate it. Thank you.' }
        ],
        feeds: [
            { id: 1, title: 'John Marazzi', img: natalieFeedImg1, text: `Amazing response. Absolutely on point and perfectly delivered.`, date: '11.10.2023', time: '15:34' },
            { id: 2, title: 'Jeff Booth', img: natalieFeedImg2, text: `Great job Natalie!`, date: '12.10.2023', time: '06:34' }
        ],
        FeedMessageCount: '12',
        FeedLikeCount: '219',
        commentDate: '22.11.2023',
        commentTime: '10:34',
        feedText: `Loved ending my week with this little rant about money printing and <span style="text-decoration: underline">#Bitcoin</span> on Making Money with\n<span style="text-decoration: underline">@cvpayne</span> ! Thanks for having me and shout out to <span style="text-decoration: underline">@PrestonPysh</span> for my ending thought`,
        commentText: `SMH we’re surrounded by lies and gaslighting.`,
        relevantText: `Host, Coin Stories Podcast | Hope & Freedom | <span style="text-decoration: underline">#Bitcoin</span> Educator | Media Commentator\n| Awarded Journalist | Positive & Curious Content | First Generation`,
        aboutText: `Natalie Brunell is an investing journalist, podcaster and Bitcoin\neducator who hosts the Coin Stories Podcast on Apple, Google Play\nand Spotify.\nFollow Host Natalie Brunell:\nX https://twitter.com/natbrunell\nInstagram https://www.instagram.com/nataliebrunell\nApple Podcasts https://podcasts.apple.com/us/podcast/coin-\nstories/id1569130932\nI self-produce this show and would be grateful for your support:\nDonations can be sent to this BTC wallet:\nbc1ql8dqjp46s4eq9k3lxt0lxzh6f2wcu35cl6944d`,
        information: `🎙️Coin Stories Host, Bitcoin Educator\n📍STL & wherever Bitcoin speaking takes me\n☀️ This is my only IG account\n☀️⚡️Fix the Money, Fix the World`

    },

]

export default dataRankings;
import dataRoadmap from '../assets/fake-data/data-roadmap';
import PageTitle from '../components/pagetitle';
import RoadMap from '../features/roadmap/home-v3';
import Header from "../components/header";

function RoadMapTwo() {
    return (<>
        <Header />
        <div className='page-roadmap-v2'>
            <PageTitle title='Roadmap' />

            <RoadMap data={dataRoadmap} />
        </div>
    </>);
}

export default RoadMapTwo;
import { ILogin } from "src/models/authApi";
import * as yup from 'yup';
import parse from "date-fns/parse";

export const initialValue: ILogin.Register = {
  fullName: '',
  birthDate: '',
  email: '',
}
export const validateSchema = yup.object({
  email: yup
    .string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      , 'Email is Not valid').required('Email is required')
    .required('Email is required')
    .required('Email is required'),
  fullName: yup
    .string()
    .required('Full name is required'),

  birthDate: yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
      const result = parse(originalValue, "dd/MM/yyyy", new Date());
      return result;
    })
    .typeError("please enter a valid date")
    .required()
    .min("1969-11-13", "Date is too early")
})

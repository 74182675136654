import { Stack } from "@mui/material";
import { ReactElement } from "react";
import Subscribe from "src/features/authentication/pages/submit/Subscribe";
import Header from "src/components/header";

export default function SubscribePage(): ReactElement {
  return (<>
        <Header />
        <Stack height={'100%'} alignItems={'center'}>
            <Subscribe />
        </Stack>
    </>);
}
import { useState } from 'react';
import { Swiper as SwiperProps, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import { Stack, Typography, Grid, Box, Divider } from '@mui/material';
import { RightArrow, LeftArrow } from 'src/assets/icons';
import LatestNewsItem from './LatestArtileItem';
import { colors } from 'src/colors';
import dataLatestNews from 'src/assets/fake-data/data-latest-news';
import { Container } from 'src/components';

export default function RelatedArticle() {

  const [swiper, setSwiper] = useState<SwiperProps>();
  const [isEnd, setIsEnd] = useState<boolean | undefined>(undefined);

  return (
    <Stack id='latestnews' sx={{
      paddingY: '32px'
    }}>
      <Container>
        <Divider sx={{ marginY: '24px' }} />
        <Stack sx={{
          position: 'relative',
          gap: '45px'
        }}>
          <Stack sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Typography
              fontSize={44}
              fontWeight={700}
              color={colors.white}
            >
              Related articles
            </Typography>

          </Stack>
          <Grid container>
            <Swiper
              modules={[Scrollbar, A11y]}
              spaceBetween={15}
              scrollbar={{ draggable: true }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                320: {
                  slidesPerView: 2
                },
                480: {
                  slidesPerView: 2
                },
                640: {
                  slidesPerView: 2
                },
                768: {
                  slidesPerView: 2,
                },
                991: {
                  slidesPerView: 4.03,
                },
              }}
              onSwiper={(swiper: SwiperProps) => setSwiper(swiper)}
              onReachEnd={(e: any) => {
                if (!e.isBeginning) {
                  setIsEnd(true);
                } else {
                  setIsEnd(false);
                }
              }}
              onReachBeginning={() => { setIsEnd(false); }}
              style={{ position: 'unset', marginLeft: 0, marginRight: 0 }}
            >
              {
                dataLatestNews.map((item) => (
                  <SwiperSlide style={{ justifyContent: 'center', display: 'flex' }} key={item.id}>
                    <LatestNewsItem items={item} />
                  </SwiperSlide>

                ))
              }
            </Swiper>
          </Grid>
        </Stack>
      </Container>
    </Stack>
  );
}
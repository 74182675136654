import { Stack } from "@mui/material";
import { ReactElement } from "react";
import Submit from "src/features/authentication/pages/submit/Submit";
import Header from "src/components/header";

export default function SubmitPage(): ReactElement {
  return (<>
        <Header />
        <Stack height={'100%'} alignItems={'center'}>
            <Submit />
        </Stack>
    </>);
}
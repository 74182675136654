import * as Yup from 'yup';

export interface initialValuesProps {
    email: string,
    password: string
}

export const initialValues: initialValuesProps = {
    email: '',
    password: ''
};

export const validationSchema = Yup.object().shape({

    email: Yup.string().matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        , 'Email is Not valid').required('Email is required')
        .required('Email is required'),
    password: Yup
        .string()
        .required('Password is required')
        .min(8, 'Password must be longer than or equal to 8 characters')
        .test("isValidPass", " is not valid", (value, context) => {
            const hasNumber = /[0-9]/.test(value);
            const hasSymbole = /[!@#%&]/.test(value);
            let validConditions = 0;
            const numberOfMustBeValidConditions = 3;
            const conditions = [hasNumber, hasSymbole];
            conditions.forEach((condition) =>
                condition ? validConditions++ : null
            );
            if (validConditions >= numberOfMustBeValidConditions) {
                return true;
            }
            return false;
        })
});
export const validationSchemaFirst = Yup.object().shape({
    email: Yup.string().matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        , 'Email is Not valid').required('Email is required'),

});
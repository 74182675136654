import React, { ReactElement, useState } from "react";
import { Stack, Typography, Tabs, Tab, Grid, Card, Divider, Menu, MenuItem } from "@mui/material";
import { Button } from "src/components";
import { colors } from "src/colors";

function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

export default function MyTabs(): ReactElement {

    const [value, setValue] = React.useState(1);
    

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Tabs
            sx={{
                alignItems: 'center',
                '& .MuiTab-root': {
                    maxWidth: '41.',
                    height: '26px',
                    minHeight: 0,
                    minWidth: 0,
                    fontWeight: 700,
                    color: colors.green1B,
                    padding: '4px 10px'            
                },
                '& .MuiTab-root.Mui-selected': {
                    color: colors.white,
                    fontSize: '13px',
                    fontWeight: 500,
                    backgroundColor: colors.gray26,
                    borderRadius: '16px',
                    padding: '4px 10px'
                },
                '& .MuiTabs-flexContainer': {
                    gap: '7px'
                },
                '& .MuiTabs-indicator': {
                    height: 0
                }
                }}
                value={value} onChange={handleChange} aria-label='chart tabs'>
                    <Tab label='24H' {...a11yProps(0)} />
                    <Tab label='1W' {...a11yProps(1)} />
                    <Tab label='1M' {...a11yProps(2)} />
                    <Tab label='3M' {...a11yProps(3)} />
                    <Tab label='6M' {...a11yProps(4)} />
                    <Tab label='1Y' {...a11yProps(5)} />
                    <Tab label='ALL' {...a11yProps(6)} />
        </Tabs>
    );
 
}

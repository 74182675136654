import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Stack } from '@mui/material';
import { colors } from "../../../colors";

AboutItem.propTypes = {
    item: PropTypes.object,
};

function AboutItem(props) {

    const {item} = props;

    return (
        <Grid item xs={8} key={item.id} 
            // className={`box-text corner-box ${item.class}`}
        >
           <Stack sx={{
                backgroundColor: colors.green0E22,
                border: `1px solid rgba(34, 183, 143, .5)`,
                padding: '17px 18px 17.96px 18px',
                gap: '15.98px'
           }}>
                <div className="h7">{item.title}</div>
                <p>{item.desc}</p>
           </Stack>
        </Grid>
    );
}

export default AboutItem;
import { FC } from 'react';
import { SxProps } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { colors } from '../../colors';

export interface TagProps {
    mode: 'square' | 'circle',
    title: string,
    hasIcon?: boolean,
    sx?: SxProps,
    border?: string,
    bg?: string,
    textSize?: number
}

export const Tag: FC<TagProps> = ({ mode, title, sx, border, bg, textSize }) => {
    return (
        <Stack
            flexDirection={'row'}
            alignItems={'center'}
            gap={'4px'}
            bgcolor={bg ? bg : 'rgba(20, 194, 163, 0.12)'}
            borderRadius={mode === 'circle' ? '24px' : '6px'}
            border={border ? border : `2px solid ${colors.green10}`}
            sx={sx}
        >
            <Typography
                fontSize={textSize ? textSize : 14}
                fontWeight={500}
                color={colors.grayB1}
                whiteSpace={'pre'}
            >
                {title}
            </Typography>
        </Stack>
    );
};

export default Tag;
import { Stack, Card, CardContent, Typography  } from '@mui/material';
import { colors } from 'src/colors';
import { GreenRightArrow } from 'src/assets/icons';
import { News1, News2, News3, News4 } from 'src/assets/icons';
import '../styles.scss';
interface Props {
    items?: any
}

export const LatestNewsItem = (props: Props) => {
 const { items } = props;

  return (
       <Card sx={{ 
            width: '367px',
            borderRadius: '8px',
            border: '1px solid rgba(22, 49, 89)',
            backgroundColor: 'transparent',
            position: 'relative'
        }}>
            <CardContent sx={{
                gap: '15px',
                display: 'flex',
                flexDirection: 'column',
            }}>
                {items.img}
                <Typography
                    fontSize={14}
                    fontWeight={700}
                    color={colors.white}
                    lineHeight={'21px'}
                    height={'42px'}
                >
                    {items.content}
                </Typography>
                <Stack sx={{
                    
                }}>
                    <Stack className='time-box'  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                        <Typography
                            fontSize={14}
                            fontWeight={400}
                            color={colors.grayB1}
                            lineHeight={'21px'}
                        >
                            {items.lastSeen}
                        </Typography>
                        <Stack sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '15px'
                        }}>
                            <Typography
                                fontSize={16}
                                fontWeight={700}
                                color={colors.green}
                                lineHeight={'16px'}
                            >
                                Read Now
                            </Typography>
                            <GreenRightArrow />
                        </Stack>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
  );
}

export default LatestNewsItem;
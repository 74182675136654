import {
    TableCell, TableHead, TableRow,
    Stack, Typography
} from '@mui/material';
import { colors } from 'src/colors';
import { Extra } from 'src/assets/icons';

//////
//Gh//
//////

const TableHeader = () => {
    
    const cellStyle = {
        color: '#B1BBCE',
        fontSize: 14,
        fontWeight: 600,
        alignItems: 'center',
        whiteSpace: 'nowrap',
        justifyContent: 'right'
    };

    return (
        <TableHead>
            <TableRow
                sx={{
                    '& th': {
                        borderBottom: `1px solid ${colors.default}`,
                        padding: '16px 16px 20px 16px'
                    }
                }}
            >
                <TableCell
                    align={'left'}
                    sx={cellStyle}
                    width={'276.04px'}
                >
                    <Typography fontSize={14} fontWeight={400} lineHeight={'20px'} color={colors.green1B}>Name</Typography>
                </TableCell>

                <TableCell align={'center'}>
                    <Stack direction='row'
                        sx={cellStyle}>
                         <Typography fontSize={14} fontWeight={400} lineHeight={'20px'} color={colors.green1B} >Amount</Typography>
                    </Stack>
                </TableCell>

                <TableCell align={'right'}>
                    <Stack direction='row'
                        sx={cellStyle}>
                        <Typography fontSize={14} fontWeight={400} lineHeight={'20px'} color={colors.green1B} >24h Change</Typography>
                    </Stack>
                </TableCell>

                <TableCell align={'right'}>
                    <Stack direction='row'
                        sx={cellStyle}>
                        <Typography fontSize={14} fontWeight={400} lineHeight={'20px'} color={colors.green1B} >Price</Typography>
                    </Stack>
                </TableCell>

                <TableCell align={'right'}>
                    <Stack direction='row'
                        sx={cellStyle}>
                        <Typography fontSize={14} fontWeight={400} lineHeight={'20px'} color={colors.green1B} >Total</Typography>
                    </Stack>
                </TableCell>

                <TableCell align={'right'}>
                    <Stack direction='row'
                        sx={cellStyle}>
                        <Typography fontSize={14} fontWeight={400} lineHeight={'20px'} color={colors.green1B} >All Time...</Typography>
                    </Stack>
                </TableCell>
                <TableCell
                    align={'right'}
                    sx={{ ...cellStyle}}
                    
                >
                    <Extra />
                </TableCell>
            </TableRow>

        </TableHead >
    );
};

export default TableHeader;
import { ReactElement } from 'react';
import "./styles.scss";
import { Stack, Typography } from '@mui/material';
import { colors } from 'src/colors';
import { GreenLikeIcon, LikeIcon, DislikeIcon, GreenLikeIcon2 } from 'src/assets/icons';

export default function Grade(props: any): ReactElement {
    const { item } = props;

    return (
        <Stack id='grade-overview' sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            backgroundColor: colors.green0E2,
            padding: '24px',
            gap: '13px'
        }}>
            <Stack className='grade-section' sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '13px',
                width: '100%'
            }}>
                <Stack gap={'4px'}>
                    <Typography 
                        fontSize={14}
                        fontWeight={400}
                        color={colors.grayB1}
                        lineHeight={'18px'}
                    >
                        {item.nickname}
                    </Typography>
                    <Stack className='grade-box' sx={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingBottom: '20px'
                    }}>
                        <Typography 
                            fontSize={18}
                            fontWeight={700}
                            color={colors.white}
                            lineHeight={'27px'}
                        >
                            How do you feel about this KOL?
                        </Typography>
                        <Stack sx={{
                                flexDirection: 'column',
                                gap:'10px',
                                alignItems: 'center',
                        }}>
                            <Stack className='rate-box' sx={{
                                flexDirection: 'row',
                                gap:'80px',
                                alignItems: 'center',

                            }}>
                                {item.id === '1' && (<>
                                        <Stack sx={{
                                            flexDirection: 'row',
                                            gap:'10px',
                                            alignItems: 'center'
                                        }}>
                                        <LikeIcon width={'14.63px'} height={'14.17px'} />
                                        <Typography 
                                            fontSize={14}
                                            fontWeight={400}
                                            color={colors.white}
                                            lineHeight={'18px'}
                                        >
                                            GOOD
                                        </Typography>
                                        </Stack>
                                        <Stack sx={{
                                            flexDirection: 'row',
                                            gap:'10px',
                                            alignItems: 'center'
                                        }}>
                                            <DislikeIcon width={'14.63px'} height={'14.17px'} />
                                            <Typography 
                                                fontSize={14}
                                                fontWeight={400}
                                                color={colors.white}
                                                lineHeight={'18px'}
                                            >
                                                BAD
                                            </Typography>
                                        </Stack>
                                    </>)
                                }
                                {item.id === '2' && (<>
                                        <Stack sx={{
                                            flexDirection: 'row',
                                            gap:'10px',
                                            alignItems: 'center'
                                        }}>
                                            <GreenLikeIcon2 width={'14.63px'} height={'14.17px'} />
                                            <Typography 
                                                fontSize={14}
                                                fontWeight={400}
                                                color={colors.green}
                                                lineHeight={'18px'}
                                            >
                                                GOOD
                                            </Typography>
                                        </Stack>
                                        <Stack sx={{
                                            flexDirection: 'row',
                                            gap:'10px',
                                            alignItems: 'center'
                                        }}>
                                            <DislikeIcon width={'14.63px'} height={'14.17px'} />
                                            <Typography 
                                                fontSize={14}
                                                fontWeight={400}
                                                color={colors.white}
                                                lineHeight={'18px'}
                                            >
                                                BAD
                                            </Typography>
                                        </Stack>
                                    </>)
                                }
                            </Stack>
                            {item.id === '2' && (<Stack sx={{ flexDirection: 'column',
                                gap:'2px',
                                alignItems: 'center',
                                width: '100%'
                            }}>
                                <Stack sx={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%'
                                }}>
                                    <Typography 
                                        fontSize={12}
                                        fontWeight={400}
                                        color={colors.green}
                                        lineHeight={'21px'}
                                    >
                                        68%
                                    </Typography>
                                    <Typography 
                                        fontSize={12}
                                        fontWeight={400}
                                        color={colors.orangeFF}
                                        lineHeight={'21px'}
                                    >
                                        32%
                                    </Typography>
                                </Stack>
                                <Stack sx={{ width: '100%', backgroundColor: colors.orangeFF }}>
                                    <Stack sx={{ width: '65%', height: '2px', backgroundColor: colors.green }} />
                                </Stack>
                            </Stack>)}
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>

        </Stack>
    );
}
import * as yup from 'yup';

export interface initialValuesProps {
  confirmpassword: string,
  password: string
}

export const initialValues: initialValuesProps = {
  confirmpassword: '',
  password: ''
};

export const validationSchema = yup.object({
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be longer than or equal to 8 characters')
    .test("isValidPass", " is not valid", (value, context) => {
      const hasNumber = /[0-9]/.test(value);
      const hasSymbole = /[!@#%&]/.test(value);
      let validConditions = 0;
      const numberOfMustBeValidConditions = 3;
      const conditions = [hasNumber, hasSymbole];
      conditions.forEach((condition) =>
        condition ? validConditions++ : null
      );
      if (validConditions >= numberOfMustBeValidConditions) {
        return true;
      }
      return false;
    }),
  confirmpassword: yup
    .string()
    .required()
    .oneOf([yup.ref('password')], 'Passwords must match')
});
import { useEffect } from 'react';
import Cookies from '../features/cookies';
import { useLocation } from 'react-router-dom';
import dataCookies from '../assets/fake-data/data-cookies';
import Header from "../components/header";

function CookiesPage() {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (<>
        <Header />
        <div className='cookies-v2'>
            <Cookies data={dataCookies} />
        </div>
    </>);
}

export default CookiesPage;
import {
  Avatar,
  Divider,
  Typography
}

  from "@mui/material";
import Stack from "@mui/material/Stack";

import {
  ReactElement
}

  from "react";
import dataTab from "src/assets/fake-data/data-tab";
import { BlueTik } from "src/assets/icons";
import image from 'src/assets/images/fake/chrisImg.png';

export default function WriteRow(): ReactElement {
  return (<Stack direction='row'
    py='24px'

    justifyContent={
      'space-between'
    }

    spacing='22px'

    sx={
      {
        borderTop: "1px solid #FFF",
        borderBottom: "1px solid #FFF"
      }
    }

  >
    <Stack
      direction='row'
      alignItems='center'
      spacing='22px'
    >
      <Avatar src={
        image
      }

        sx={
          {
            width: 64,
            height: 64,
            borderRadius: 0,
          }
        }

      /> <Typography sx={
        {
          color: "#FFF",
          fontFamily: "Chakra Petch",
          fontSize: "32px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "48px"
        }
      }

      >Chris <BlueTik /></Typography>
      <Divider orientation='vertical' style={{ height: '100%' }} />
      <Typography sx={{
        color: "#FFF", fontSize: "16px", fontWeight: 400
      }}>
        7 Dec 2023 · 2 min read
      </Typography>
    </Stack>
    <Stack direction='row'
      alignItems={'center'}
      spacing={'32px'}>
      {dataTab.slice(1, 6).map((item) =>
        <Stack direction='row' spacing={'12px'}>
          {item.img}
          <Typography sx={{
            color: "#B1BBCE",
            fontFamily: "Chakra Petch",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "20px"
          }}>{item.label}</Typography>
        </Stack>

      )}
    </Stack>
  </Stack >);
}
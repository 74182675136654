import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { _TOKEN_NAME } from 'src/config/urls';
import useLocalStorage from 'src/hooks/useLocalStorage';

export const api = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({

        baseUrl: `${process.env.REACT_APP_BASE_URL}/api/app`,
        prepareHeaders: (headers: any) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const { getLocal } = useLocalStorage();
            const token = getLocal(_TOKEN_NAME);
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }

            return headers;
        },

    }),
    tagTypes: [],
    endpoints: () => ({}),
});
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface Props {
    user: IUser;
    sample: boolean;
    forgotUser: IForgotUser;
    updateUserInfo: IUpdateUserInfo;
}


interface IForgotUser {
    email?: string;
    setPasswordToken?: string;
}
interface IUser {
    fullName: string;
    email: string;
    birthDate: string;
    role?: string;
    status?: string;
    emailVerified?: boolean;
    phoneVerified?: boolean;
    _id?: string;
    createdAt?: string;
    updatedAt?: string;
    setPasswordToken?: string;
    accessToken?: string;
    refreshToken?: string;
    registered?: boolean;

}
export interface IUpdateUserInfo {
    avatar?: string;
    favoriteChain?: string;
    favoriteCoin?: string;
    about?: string;
    favoritePost?: string[];
    biography?: string;
    relevantCalls?: string;
    youtube?: string;
    telegram?: string;
    instagram?: string;
    twitter?: string;
    reddit?: string;
    ethWallet?: string;
    bnbWallet?: string;
    trcWallet?: string;
    btcWallet?: string;
    ftmWallet?: string;
    cryptoProjects?: { avatar: string, link: string, title: string }[];

}
const initialState: Props = {
    user: {
        fullName: '',
        email: '',
        birthDate: '',
    },
    updateUserInfo: {},
    forgotUser: {},
    sample: false
};

export const mainSlice = createSlice({
    name: 'mainSlice',
    initialState,
    reducers: {
        sampleAction: (state, action: PayloadAction<boolean>) => {
            state.sample = action.payload;
            return state;
        },
        setUserRegisterInfo: (state, action: PayloadAction<IUser>) => {
            state.user = action.payload;
            return state;
        },
        setUpdateUserInfo: (state, action: PayloadAction<IUpdateUserInfo>) => {
            state.updateUserInfo = { ...state.updateUserInfo, ...action.payload };
            return state;
        },
        setForgotUser: (state, action: PayloadAction<IForgotUser>) => {
            state.forgotUser = action.payload;
            return state;
        },
    },
});

export const { sampleAction, setUserRegisterInfo, setUpdateUserInfo, setForgotUser } = mainSlice.actions;

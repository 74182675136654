import { Stack, Card, CardContent, Typography } from '@mui/material';
import '../styles.scss';
import { colors } from '../../../../colors';
interface Props {
    items?: any
}

export const Wallet = (props: Props) => {

 const { items } = props;

  return (
       <Card id='wallet' sx={{ 
            width: '265px',
            height: '76px',
            borderRadius: '8px',
            backgroundColor: 'rgba(20, 194, 163, 0.12)'
        }}>
            <CardContent sx={{
                gap: '30px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                
                <Typography
                    fontSize={15}
                    fontWeight={700}
                    color={colors.white}
                    lineHeight={'23px'}
                >
                    {items.title}
                </Typography>
                <Stack sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <Typography
                        fontSize={17}
                        fontWeight={400}
                        color={colors.grayB1}
                        lineHeight={'23px'}
                    >
                        {items.price}
                    </Typography>
                    <Typography
                        fontSize={13}
                        fontWeight={600}
                        color={colors.red}
                        lineHeight={'20px'}
                    >
                        {items.percent}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
  );
}

export default Wallet;
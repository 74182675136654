import React from 'react';
import dataProject from '../assets/fake-data/dataProject';
import PageTitle from '../components/pagetitle';
import Project from '../features/project/nftitem';
import Header from "../components/header";

function NftItem() {
    return (<>
        <Header />
        <div className='page-nft'>
            <PageTitle title='NFT ITEM’S' />
            <Project data={dataProject} />
        </div>
    </>);
}

export default NftItem;
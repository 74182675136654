import { useEffect } from "react";
import AOS from "aos";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/footer";
import "../src/assets/font/font-awesome.css";
import routes from "./pages";
import Page404 from "./pages/404";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
function App() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <>
      <Routes>
        {routes.map((data, idx) => (
          <Route key={idx} path={data.path} element={data.component} />
        ))}
        <Route path="*" element={<Page404 />} />
      </Routes>
      <ToastContainer
        position='top-left'
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Footer />
    </>
  );
}

export default App;

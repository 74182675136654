import {
    InstagramImg,
    TelegramGroupImg,
    DiscardImg,
    TelegramImg,
    TwitchImg,
    WebsiteImg,
    YoutubeImg
} from 'src/assets/icons';

const dataGroupButton = [
    {
        id: 1,
        icon: <></>,
        name: 'Influ Vote'
    },
    {
        id: 2,
        icon: <></>,
        name: 'Track Portfolio'
    },
    {
        id: 3,
        icon: <WebsiteImg />,
        name: 'Website',
    },
    {
        id: 4,
        icon: <InstagramImg />,
        name: 'Instagram',
    },
    // {
    //     id: 5,
    //     icon: <DiscardImg /> ,
    //     name: 'Discord',
    // },
    {
        id: 6,
        icon: <TelegramImg />,
        name: 'Telegram Channel',
    },
    {
        id: 7,
        icon: <TelegramGroupImg />,
        name: 'Telegram Group',
    },
    // {
    //     id: 9,
    //     icon: <TwitchImg /> ,
    //     name: 'Twitch Group',
    // },
    {
        id: 9,
        icon: <YoutubeImg />,
        name: 'YouTube',
    },
]

export default dataGroupButton;
import { styled, makeStyles } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import { colors } from 'src/colors';

export const StyledMoreMenu = styled(Menu)(() => ({
    '& .MuiPaper-root': {
        height: 'auto',
        border: `2px solid ${colors.green}`,
        backgroundColor: colors.green0E2,
        borderRadius: 0
    }
}));

import { ReactElement } from 'react';
import { Stack, Typography, Divider, Box } from '@mui/material';
import { Button } from 'src/components';
import { colors } from 'src/colors';
import { UserLogo, CircleIcon, ChatIcon, GreenLikeIcon } from 'src/assets/icons';
import { createMarkup } from 'src/helpers/utils';
import OverviewComment from '../Feed/Comment';

export default function Comment(props: any): ReactElement {
    const { item } = props;
    return (
        <Stack sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: colors.green0E2,
            justifyContent: 'center',
            padding: '24px',
            gap: '50px'
        }}>
            <Stack sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: colors.green0E2,
                justifyContent: 'flex-start',
                gap: '25px',
                overflow: 'hidden'
            }}>
                <Stack sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    gap: '25px'
                }}>
                    <Stack sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <Stack flexDirection={'row'} alignItems={'center'} gap={'15px'}>
                            <Stack flexDirection={'row'} alignItems={'center'} gap={'4px'}>
                                {/* <Stack 
                                    width={'44px'}
                                    height={'44px'}
                                    borderRadius={'50%'}
                                >
                                    {item.img}
                                </Stack> */}
                                <img 
                                    src={item.img} 
                                    alt={`img-${item.id}`}
                                    width={44}
                                    height={44}
                                    style={{ borderRadius: '50%' }}
                                />
                                <Typography 
                                    fontSize={18}
                                    fontWeight={700}
                                    color={colors.white}
                                    lineHeight={'27px'}
                                >
                                {item.fullname}
                                </Typography>
                            </Stack>
                            
                            <CircleIcon width={'6px'} height={'6px'} />
                            <Typography 
                                fontSize={14}
                                fontWeight={400}
                                color={colors.grayB1}
                                lineHeight={'18px'}
                            >
                                {item.commentDate}
                            </Typography>
                            <CircleIcon width={'6px'} height={'6px'} />
                            <Typography 
                                fontSize={14}
                                fontWeight={400}
                                color={colors.grayB1}
                                lineHeight={'18px'}
                            >
                                {item.commentTime}
                            </Typography>
                        </Stack>
                        
                    </Stack>
                    <Typography 
                        fontSize={14}
                        fontWeight={400}
                        color={colors.grayB1}
                        lineHeight={'18px'}
                        whiteSpace={'pre-wrap'}
                    >
                        <span dangerouslySetInnerHTML={createMarkup(item.feedText!)}></span>
                    </Typography>
                </Stack>
                <Stack flexDirection={'row'} gap={'35px'}>
                    <Stack flexDirection={'row'} alignItems={'center'} gap={'11px'}>
                                <ChatIcon />
                                <Typography 
                                    fontSize={14}
                                    fontWeight={400}
                                    color={colors.grayB1}
                                    lineHeight={'18px'}
                                >
                                    {item.FeedMessageCount}
                                </Typography>
                            </Stack>
                            <Stack flexDirection={'row'} alignItems={'center'} gap={'11px'}>
                                <GreenLikeIcon />
                                <Typography 
                                    fontSize={14}
                                    fontWeight={400}
                                    color={colors.grayB1}
                                    lineHeight={'18px'}
                                >
                                    {item.FeedLikeCount}
                                </Typography>
                            </Stack>
                            {/* <Stack flexDirection={'row'} alignItems={'center'} gap={'11px'}>
                                <UploadIcon />
                                <Typography 
                                    fontSize={14}
                                    fontWeight={400}
                                    color={colors.grayB1}
                                    lineHeight={'18px'}
                                >
                                    0
                                </Typography>
                            </Stack> */}
                            
                        </Stack>
                        {
                            item.feeds.map((comment: any) => (
                                <OverviewComment comment={comment} />  
                            ))
                        }  
            </Stack>
            {/* <Stack sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}>
                <Typography 
                    fontSize={18}
                    fontWeight={700}
                    color={colors.white}
                    lineHeight={'27px'}
                >
                    3 Comments
                </Typography>
                <Typography color={colors.white} fontSize={14} fontWeight={600} paddingX={'6px'}> . </Typography>
                <Typography 
                    fontSize={14}
                    fontWeight={500}
                    color={colors.white}
                    lineHeight={'27px'}
                    whiteSpace={'pre-wrap'}
                >
                   2 replies
                </Typography>
            </Stack>
            
            <Stack sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: colors.green12,
                justifyContent: 'center',
                padding: '24px',
                gap: '10px'
            }}>
                <Stack sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                    alignItems: 'center'
                }}>
                    <img src={item.img} width={'44px'} height={'44px'} style={{ borderRadius: '50%' }} alt={`img-${item.img}`}  />
                    <Typography 
                        fontSize={14}
                        fontWeight={700}
                        color={colors.white}
                        lineHeight={'24px'}
                    >
                        {item.fullname}
                    </Typography>
                    <Typography 
                        fontSize={14}
                        fontWeight={500}
                        color={colors.grayB1}
                        lineHeight={'24px'}
                    >
                        on Dec 14 , 2022
                    </Typography>
                </Stack>
                <Typography 
                    fontSize={15}
                    fontWeight={400}
                    color={colors.grayB1}
                    lineHeight={'18px'}
                >
                    Lorem ipsum dolor sit amet consectetur. Sit in nibh facilisis volutpat mauris feugiat nisl. Semper pellentesque quis mauris id urna at sed. Dictum tincidunt sit risus ridiculus sed sit urna egestas molestie.
                </Typography>
            </Stack>

            <Divider 
                orientation={"vertical"}
                sx={{
                    height: "1px",
                    borderWidth: "1px",
                    borderColor: colors.green16
                }} 
            />
            <Stack>
                <Stack sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px'
                }}>
                    <Stack alignItems={'center'}>
                        <img src={item.img} width={'44px'} height={'44px'} style={{ borderRadius: '50%' }} alt={`img-${item.img}`}  />
                        <Box
                            sx={{
                                width: '0.5px',
                                backgroundColor: colors.gray3D,
                                margin: '8px 8px',
                                height: '8em'
                            }}
                            component="span"
                            display='inline-block'
                        ></Box>
                    </Stack>
                    <Stack sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px'
                    }}>
                        <Box display={'flex'} flexDirection={'row'} gap={'10px'}>
                            <Typography 
                                fontSize={14}
                                fontWeight={700}
                                color={colors.white}
                                lineHeight={'24px'}
                            >
                                {item.fullname}
                            </Typography>
                            <Typography 
                                fontSize={14}
                                fontWeight={500}
                                color={colors.grayB1}
                                lineHeight={'24px'}
                            >
                                on Dec 14 , 2022
                            </Typography>
                        </Box>
                        <Typography 
                            fontSize={15}
                            fontWeight={400}
                            color={colors.grayB1}
                            lineHeight={'18px'}
                        >
                            Lorem ipsum dolor sit amet consectetur. Sit in nibh facilisis volutpat mauris feugiat nisl. Semper pellentesque quis mauris id urna at sed. Dictum tincidunt sit risus ridiculus sed sit urna egestas molestie.
                        </Typography>
                    </Stack>
                    
                </Stack>

                <Stack sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px'
                }}>
                    <Stack alignItems={'center'}>
                        <img src={item.img} width={'44px'} height={'44px'} style={{ borderRadius: '50%' }} alt={`img-${item.img}`}  />
                    </Stack>
                    
                    <Stack sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px'
                    }}>
                        <Box display={'flex'} flexDirection={'row'} gap={'10px'}>
                            <Typography 
                                fontSize={14}
                                fontWeight={700}
                                color={colors.white}
                                lineHeight={'24px'}
                            >
                                {item.fullname}
                            </Typography>
                            <Typography 
                                fontSize={14}
                                fontWeight={500}
                                color={colors.grayB1}
                                lineHeight={'24px'}
                            >
                                on Dec 14 , 2022
                            </Typography>
                        </Box>
                        <Typography 
                            fontSize={15}
                            fontWeight={400}
                            color={colors.grayB1}
                            lineHeight={'18px'}
                        >
                            Lorem ipsum dolor sit amet consectetur. Sit in nibh facilisis volutpat mauris feugiat nisl. Semper pellentesque quis mauris id urna at sed. Dictum tincidunt sit risus ridiculus sed sit urna egestas molestie.
                        </Typography>
                    </Stack>
                    
                </Stack>
            </Stack>

            <Divider 
                orientation={"vertical"}
                sx={{
                    height: "1px",
                    borderWidth: "1px",
                    borderColor: colors.green16
                }} 
            /> */}

            <Stack position={'relative'}>
                <label style={{ 
                    position: 'absolute',
                    top: '22px',
                    left: '12px',
                    fontSize: '14px',
                    fontWeight: 400,
                    color: colors.white
                }}></label>
                <input
                    type="text"
                    id="comment"
                    placeholder='Enter your comment'
                    style={{
                        width: "100%",
                        height: '76px',
                        padding: "11px",
                        border: `2px solid ${colors.green}`,
                        color: colors.white,
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '16.41px'
                    }}
                />
                <Button
                    variant={"outlined"}
                    LabelColor={colors.white}
                    backgroundColor={`rgba(20, 194, 163, 0.2)`}
                    sx={{
                        position: 'absolute',
                        top: '13px',
                        right: '13px',
                        width: '145px',
                        border: 0
                    }}
                >
                    <Typography 
                        fontSize={16}
                        fontWeight={700}
                        color={colors.white}
                        lineHeight={'16px'}
                    >
                        Comment
                    </Typography>
                </Button>
            </Stack>
      </Stack>
        
    );
}
import { ReactElement } from 'react';
import "./styles.scss";
import { Stack, Typography } from '@mui/material';
import { Button } from 'src/components';
import { colors } from 'src/colors';
// import { TopArrow } from 'src/assets/icons';
import { useNavigate } from "react-router-dom";
import { createMarkup } from 'src/helpers/utils';

export default function Information(props: any): ReactElement {
    const { item } = props;
    const navigate = useNavigate();

    return (
        <Stack id='information' sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: colors.green0E2,
            justifyContent: 'center',
            padding: '24px',
            gap: '20px'
        }}>
            <Typography 
                fontSize={18}
                fontWeight={700}
                color={colors.white}
                lineHeight={'27px'}
            >
                Who is {item.nickname}
            </Typography>
            <Typography 
                fontSize={14}
                fontWeight={400}
                color={colors.grayB1}
                lineHeight={'18px'}
                whiteSpace={'pre-wrap'}
            >
                <span dangerouslySetInnerHTML={createMarkup(item.information!)}></span>
            </Typography>
            <Stack display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
              <Button
                  variant={"outlined"}
                  LabelColor={colors.white}
                  borderColor={colors.green}
                //   endIcon={<TopArrow />}
                  onClick={() => { navigate('/portfolio') }}
                  sx={{ width: 258 }}
                  className='btn-information'
              >
                  <Typography 
                      fontSize={16}
                      fontWeight={700}
                      color={colors.white}
                      lineHeight={'16px'}
                      textTransform={'uppercase'}
                  >
                      Track the Portofolio
                  </Typography>
              </Button>
            </Stack>
      </Stack>
        
    );
}
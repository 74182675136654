import { ReactElement, useState } from 'react';
import "./styles.scss";
import { Stack, Typography, Divider, Tabs, Tab, Box, Grid } from '@mui/material';
import { Tag } from 'src/components';
import { colors } from 'src/colors';
import { Clock, Info2Icon, Warning } from 'src/assets/icons';
import { RankingCard } from '../../components/Card';

function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
}

export default function Stats(props: any): ReactElement {
    const { item } = props;
    const [value, setValue] = useState<number>(0);


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    
      const renderTabs: { [key: number]: any } = {
        0:  <Stack gap={'10px'} sx={{ opacity: 0.3, filter: "blur(11.600000381469727px)" }}>
                <Stack display={'flex'} flexDirection={'row'} gap={'16px'} className='stats-card-box'>
                    {stats.slice(0, 2).map((item)=>(<RankingCard className='stats-card' width='50%' items={item} />))}
                </Stack>
                <Stack display={'flex'} flexDirection={'row'} gap={'16px'} className='stats-card-box'>
                    {stats.slice(2, 5).map((item)=>(<RankingCard className='stats-card' width='33.3%' items={item} />))}
                </Stack>
            </Stack>,
        1: <Stack gap={'10px'} sx={{ opacity: 0.3, filter: "blur(11.600000381469727px)" }}>
                <Stack display={'flex'} flexDirection={'row'} gap={'16px'} className='stats-card-box'>
                    {stats.slice(5, 7).map((item)=>(<RankingCard className='stats-card' width='50%' items={item} />))}
                </Stack>
                <Stack display={'flex'} flexDirection={'row'} gap={'16px'} className='stats-card-box'>
                    {stats.slice(2, 5).map((item)=>(<RankingCard className='stats-card' width='33.3%' items={item} />))}
                </Stack>
            </Stack>,
        2: <Stack gap={'10px'} sx={{ opacity: 0.3, filter: "blur(11.600000381469727px)" }}>
                <Stack display={'flex'} flexDirection={'row'} gap={'16px'} className='stats-card-box'>
                    {stats.slice(0, 2).map((item)=>(<RankingCard className='stats-card'  width='50%' items={item} />))}
                </Stack>
                <Stack  display={'flex'} flexDirection={'row'} gap={'16px'} className='stats-card-box'>
                    {stats.slice(2, 5).map((item)=>(<RankingCard className='stats-card' width='33.3%' items={item} />))}
                </Stack>
            </Stack>,
      };

    return (
        <Stack id='stats' sx={{
            backgroundColor: colors.green0E2,
            padding: '24px'
        }}>
            <Stack gap={'4px'}>
                <Typography
                    fontSize={14}
                    fontWeight={400}
                    color={colors.grayB1}
                    lineHeight={'18px'}
                >
                    {item.nickname}
                </Typography>
                <Typography
                    fontSize={18}
                    fontWeight={700}
                    color={colors.white}
                    lineHeight={'27px'}
                >
                    Trade with this KOL
                </Typography>
            </Stack>

            <Divider
                orientation={"vertical"}
                className='stats-divider'
                sx={{
                    height: "1px",
                    borderWidth: "1px",
                    borderColor: colors.green16,
                    marginY: '20px'
                }}
            />
            <div style={{ position: 'relative' }}>
                <Box className='time-items' sx={{
                    width: '100%', marginBottom: '28px',
                    opacity: 0.3, filter: "blur(11.600000381469727px)"

                }}>
                    <Tabs
                        sx={{
                            '& .MuiTab-root': {
                                maxWidth: '42px',
                                height: '36px',
                                minHeight: 0,
                                minWidth: 0,
                                fontWeight: 700,
                                color: colors.grayB1,
                            },
                            '& .MuiTab-root.Mui-selected': {
                                color: colors.white,
                                fontSize: '14px',
                                fontWeight: 700,
                                backgroundColor: colors.green12
                            },
                            '& .MuiTabs-indicator': {
                                height: 0
                            }
                        }}
                        value={value} onChange={handleChange} aria-label='basic tabs example'>
                        <Tab label='24h' {...a11yProps(0)} />
                        <Tab label='7d' {...a11yProps(1)} />
                        <Tab label='30d' {...a11yProps(2)} />
                    </Tabs>
                </Box>

            </div >
            <Stack>
                {
                    renderTabs[value]
                }
                <Stack
                    justifyContent={'center'}
                    alignItems={'center'}
                    direction={'row'}
                    flexGrow={1}
                    spacing={'4px'}
                    sx={{
                        position: 'relative',
                        top: '-150px',

                    }}
                >
                    <Info2Icon />
                    <Typography color='white'>
                        No data available at the moment
                    </Typography>
                </Stack>
            </Stack>

            <Divider
                orientation={"vertical"}
                sx={{
                    height: "1px",
                    borderWidth: "1px",
                    borderColor: colors.green16,
                    marginY: '20px'
                }}
            />

            <Stack className='stats-seen' sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '10px',
                paddingTop: '10px',
                opacity: 0.3, filter: "blur(11.600000381469727px)"
            }}>
                <Clock className='mb-clock' />
                <Typography 
                    fontSize={14}
                    fontWeight={700}
                    color={colors.grayB1}
                    lineHeight={'21px'}
                >
                    7 hours ago (02:00 AM, UTC+01:00, 24 Sep 2023)
                </Typography>
            </Stack>
        </Stack >

    );
}
const stats = [
    {
        id: 1,
        text: "Wallet Value",
        price: '582.94k',
        pricePercent: '-2.84%',
        img: <Warning />
    },
    {
        id: 2,
        text: "Transactions (buy/sell)",
        price: '587.87k',
        pricePercent: '-2.78%',
        img: <Warning />
    },
    {
        id: 3,
        text: "Profit",
        price: '$0',
        pricePercent: '0%',
        img: <Warning />
    },
    {
        id: 4,
        text: "Losses",
        price: '$20.9k',
        pricePercent: '-4.19%',
        img: <Warning />
    },
    {
        id: 5,
        text: "Reliability",
        price: '$20.9k',
        pricePercent: '-4.19%',
        img: <Warning />
    },
    {
        id: 6,
        text: "Transactions (buy/sell)",
        price: '587.87k',
        pricePercent: '-2.78%',
        img: <Warning />
    },
    {
        id: 7,
        text: "Wallet Value",
        price: '582.94k',
        pricePercent: '-2.84%',
        img: <Warning />
    }
];
import { Stack } from "@mui/material";
import { ReactElement } from "react";
import CreatePassword from "src/features/authentication/pages/login/CreatePassword";
import Header from "src/components/header";

export default function CreatePasswordPage(): ReactElement {
  return (<>
        <Header />
        <Stack height={'100%'} alignItems={'center'}>
            <CreatePassword />
        </Stack>
    </>);
}
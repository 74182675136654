import { useState } from 'react';
import './styles.scss';
import { Swiper as SwiperProps, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import { Stack, Typography, Grid, Box } from '@mui/material';
import { Container} from '../../../components';
import { colors } from '../../../colors';
import Feed from './components/Feed';
import { GrayArrowRight, GrayArrowLeft } from 'src/assets/icons';

function LatestFeeds() {

    const [swiper, setSwiper] = useState<SwiperProps>();
    const [isEnd, setIsEnd] = useState<boolean | undefined>(undefined);

    return (
        <Stack id='latest-feeds' sx={{
            paddingY: '80px'
        }}>
            <Container>
                <Stack className='latest-feeds-box' sx={{
                    position: 'relative',
                    gap: '20px'
                }}>
                    <Stack sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Typography
                            fontSize={44}
                            fontWeight={700}
                            color={colors.white}
                            lineHeight={'52.8px'}
                            className='latest-feeds-title'
                        >
                            Latest feeds
                        </Typography>
                        <Stack sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'row-reverse',
                            gap: '12px'
                        }}>
                            <Box
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                width={32}
                                height={32}
                                bgcolor={colors.gray2B}
                                borderRadius={'50px'}
                                zIndex={11}
                                sx={{
                                    cursor: 'pointer',
                                    opacity: isEnd ? .5 : 1
                                }}
                                onClick={() => swiper?.slideNext()}
                                >
                                   <GrayArrowRight />
                            </Box>
                            <Box
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                width={32}
                                height={32}
                                bgcolor={colors.gray2B}
                                borderRadius={'50px'}
                                zIndex={11}
                                sx={{
                                    cursor: 'pointer',
                                    opacity: !isEnd ? .5 : 1
                                }}
                                onClick={() => swiper?.slidePrev()}
                            >
                                <GrayArrowLeft />
                            </Box>
                        </Stack>
                    </Stack>
                    <Grid container>
                        <Swiper
                            modules={[ Scrollbar, A11y ]}
                            spaceBetween={20}
                            scrollbar={{ draggable: true }}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                320: {
                                    slidesPerView: 1
                                },
                                350: {
                                    slidesPerView: 1
                                },
                                380: {
                                    slidesPerView: 1.05
                                },
                                400: {
                                    slidesPerView: 1.15
                                },
                                420: {
                                    slidesPerView: 1.2
                                },
                                440: {
                                    slidesPerView: 1.3
                                },
                                450: {
                                    slidesPerView: 1.3
                                },
                                460: {
                                    slidesPerView: 1.3
                                },
                                480: {
                                    slidesPerView: 1.35
                                },
                                500: {
                                    slidesPerView: 1.45
                                },
                                520: {
                                    slidesPerView: 1.48
                                },
                                530: {
                                    slidesPerView: 1.55
                                },
                                540: {
                                    slidesPerView: 1.55
                                },
                                600: {
                                    slidesPerView: 1.7
                                },
                                767: {
                                    slidesPerView: 1.2
                                },
                                991: {
                                    slidesPerView: 1.2,
                                },
                            }}
                            onSwiper={(swiper: SwiperProps) => setSwiper(swiper)}
                            onReachEnd={(e: any) => { 
                                if(!e.isBeginning){
                                    setIsEnd(true);
                                } else {
                                    setIsEnd(false);
                                } 
                            }}
                            onReachBeginning={() => { setIsEnd(false); }}
                            style={{ position: 'unset' }}
                            className='latest-feeds-swiper'
                        >
                            {
                                feeds.map((item: any) => (
                                    <SwiperSlide style={{ justifyContent: 'center', display: 'flex' }} key={item.id}>
                                        <Feed />
                                    </SwiperSlide>
                                        
                                ))
                            }
                        </Swiper>
                    </Grid>
                </Stack>
            </Container>
        </Stack>
    );
}

const feeds = [
    {
        id: 1,
        title : 'Reese Witherspoon',
        price: '$6.28k',
        percent: '-4.28%'
    },
    {
        id: 2,
        title : 'Timbaland',
        price: '$660.86',
        percent: '-1.03%'
    },
    {
        id: 3,
        title : 'Post Malone',
        price: '$326.97k',
        percent: '-10.11%'
    }
]

export default LatestFeeds;
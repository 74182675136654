import { ReactElement } from 'react';
import "./styles.scss";
import { Stack, Typography } from '@mui/material';
import { colors } from 'src/colors';
import { SimilarImg7,
    SimilarImg2, SimilarImg3 } from 'src/assets/icons';

export default function CryptoProjects(props: any): ReactElement {
    const { item } = props;
    
    return (
        <Stack id='crypto-project' sx={{
            backgroundColor: colors.green0E2,
            padding: '24px',
            gap: '30px'
        }}>
          <Stack gap={'4px'}>
            <Typography 
                fontSize={14}
                fontWeight={400}
                color={colors.grayB1}
                lineHeight={'18px'}
            >
                {item.nickname}
            </Typography>
            <Typography 
                fontSize={18}
                fontWeight={700}
                color={colors.white}
                lineHeight={'27px'}
            >
                Crypto Projects
            </Typography>
          </Stack>
          <Stack className='crypto-project-section' display={'flex'} flexDirection={'row'} gap={'38px'}>
            {projects.map((project) => (
                <Stack className='crypto-box' width='33.3%' flexDirection={'column'}>
                    <Stack flexDirection={'row'} alignItems={'center'} gap={'18px'}>
                        <Stack sx={{ borderRadius: '50%' }} width={'44px'} height={'44px'}>
                            {project.img}
                        </Stack>
                        <Stack flexDirection={'column'} gap={'10px'}>
                            <Stack flexDirection={'row'} alignItems={'center'} gap={'10px'}>
                                <Typography 
                                    fontSize={14}
                                    fontWeight={600}
                                    color={colors.white}
                                    lineHeight={'18px'}
                                >
                                    {project.name}
                                </Typography>
                                <Stack sx={{
                                    padding: '4px 11.53px 4px 11.74px',
                                    backgroundColor: colors.green0E,
                                    borderRadius: '24px'
                                }}>
                                    <Typography 
                                        fontSize={12}
                                        fontWeight={500}
                                        color={project.statusColor}
                                        lineHeight={'16px'}
                                        sx={{ textWrap: 'nowrap' }}
                                    >
                                        {project.status}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Typography 
                                fontSize={14}
                                fontWeight={400}
                                color={colors.grayB1}
                                lineHeight={'18px'}
                            >
                                Score: {project.score}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            ))}
          </Stack>
        </Stack>
        
    );
}

const projects = [
    {
        id: 1,
        name: "MailZero",
        score: '100',
        status: 'Secure',
        statusColor: colors.green,
        img: <SimilarImg2 width={60} height={60} />
    },
    {
        id: 2,
        name: "MailZero",
        score: '100',
        status: 'Under Review',
        statusColor: colors.orangeFFB,
        img: <SimilarImg3 width={60} height={60} />
    },
    {
        id: 3,
        name: "MailZero",
        score: '100',
        status: 'High Risk',
        statusColor: colors.redFF,
        img: <SimilarImg7 width={60} height={60} />
    }
];
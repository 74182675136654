import Header from "src/components/header";
import { Stack, Typography } from '@mui/material';
import { colors } from 'src/colors';
import { Container, MyCard } from 'src/components';
import Banner from '../features/home/banner';
import Performance from '../features/home/performance';
import LatestNews from '../features/home/latestnews';
import Control from '../features/home/control';
import Browse from '../features/home/browse';
import Featured from '../features/home/Featured';
import Community from '../features/home/community';
import MoreVerification from "src/features/home/moreVerification";
import LatestFeeds from "src/features/home/latestfeeds";
import useResponsive from 'src/hooks/useResposive';

function Home() {
    const isMobile = useResponsive('down', 'sm');
    return (<>
        <Header />
        <Stack className="home-section" sx={{ paddingTop: isMobile ? '35px' : '50px' , marginTop: isMobile ? 0 : '50px' }} gap={'50px'}>
            
            <Banner />

            <Stack className='empty' sx={{ height: "50px" }} />

            <Performance />
            
            <LatestNews />

            <MoreVerification />

            <LatestFeeds />

            <Control />

            <Browse />

            <Featured />

            <Community />

        </Stack>
    </>);
}

export default Home;
// login
export const LOGIN_URL = '/auth';
export const LOGIN_ADD_PASSWORD_URL = '/auth/create-password';
export const LOGIN_FORGOT_PASSWORD_URL = '/auth/forgot-password';
export const LOGIN_VERIFICATION_CODE_URL = '/auth/verification-code';
export const LOGIN_CONFIRM_PASSWORD_URL = '/auth/confirm-password';

//register
export const REGISTER_URL = '/auth/register';
export const REGISTER_CREATE_ACCOUNT_URL = '/auth/register/create-account';
export const REGISTER_VERIFICATION_CODE_URL = '/auth/register/verification-code';
export const REGISTER_CREATE_PASSWORD_URL = '/auth/register/create-password';
export const REGISTER_CHOOSE_PICTURE_URL = '/auth/register/choose-picture';

//local
export const TOP_BLOCK_CHAIN_URL = '/top-influencers';
export const SUBMIT_URL = '/auth/submit';
export const SUBSCRIBE_URL = '/auth/submit/subscribe';
export const SUBMIT_KOL_URL = '/auth/submit/kol';

export const _TOKEN_NAME = 'kol-dapp-token';
export const _REFRESH_TOKEN = 'kol-dapp-refresh-token';
export const _RESET_TOKEN = 'kol-dapp-reset-token';
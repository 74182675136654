import { ReactElement, useState } from 'react';
import "./styles.scss";
import { Stack, Typography, Divider, Tabs, Tab, Box, Grid } from '@mui/material';
import { Tag } from 'src/components';
import { colors } from 'src/colors';
import { Clock, Warning } from 'src/assets/icons';
import { RankingCard } from '../../components/Card';
import Youtube from './Youtube';
import Instagram from './Instagram';
import Twitter from './Twitter';

function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
}

export default function LatestActivities(props: any): ReactElement {
    const { item } = props;
    const [value, setValue] = useState<number>(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

      const renderTabs: { [key: number]: any } = {
        0:  <Grid className='latest-activities-box' container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} style={{ marginTop: '0'}}>
                <Youtube className='activity-card-box' item={item.youtubeDetails} />
            </Grid>,
        1: <Grid className='latest-activities-box' container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} style={{ marginTop: '0'}}>
                <Instagram className='activity-card-box' item={item.instagramDetails} />
            </Grid>,
        2: <Stack className='latest-activities-box twitter-box' style={{ marginTop: '0'}}>
                <Twitter className='activity-card-box twitter' item={item.twitterDetails} />
           </Stack>,
      };

    return (
        <Stack id='latest-activities' sx={{
            backgroundColor: colors.green0E2,
            padding: '24px'
        }}>
          <Typography 
                fontSize={18}
                fontWeight={700}
                color={colors.white}
                lineHeight={'27px'}
                className='activity-title'
            >
                Latest activities
            </Typography>

            <Stack className='activity-menu' sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '15px'
            }}>
                <Tabs
                  sx={{
                      minHeight: 0,
                      paddingTop: '15px',
                      paddingBottom: '8',
                      '& .MuiTab-root': {
                        backgroundColor: colors.gray49,
                        minHeight: 0,
                        minWidth: 0,
                        fontWeight: 700,
                        color: colors.grayB1,
                        padding: '8px 16px',
                        fontSize: '16px',
                        lineHeight: '20px',
                      },
                      '& .MuiTabs-flexContainer': {
                        gap: '13px'
                      },
                      '& .MuiTab-root.Mui-selected': {
                        color: colors.white,
                        backgroundColor: colors.green12,
                        border:`1px solid ${colors.green}`
                      },
                      '& .MuiTabs-indicator': {
                        height: 0
                      }
                  }}
                  value={value} onChange={handleChange} aria-label='tabs'
              >
                  <Tab label='YouTube' {...a11yProps(0)} />
                  <Tab label='Instagram' {...a11yProps(1)} />
                  <Tab label='Twitter' {...a11yProps(2)} />
                </Tabs>
            </Stack>
            <Stack className='activity-slider-box' paddingTop={'15px'}>{renderTabs[value]} </Stack>
        </Stack>
        
    );
}

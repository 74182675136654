export const colors = {
    white: '#FFF',
    default: '#0c1226',
    blue45: '#4575EA',
    blue40: '#0E2240',
    blue06: '#062A67',
    blue05: '#051c46',
    blue4C: '#162C4C',
    green6C: '#6CCF59',
    green34: '#34B349',
    green22: '#22B78F',
    green2D: '#2D4A74',
    green2A: '#2AD2A5',
    green1B: '#1BF5CE',
    green16: '#163159',
    green31: '#163159',
    green: '#14C2A3',
    green12: '#123142',
    green10: '#105252',
    green0E: '#0E3841',
    green0E22: '#0E2230',
    green0F: '#0F4348',
    green0D: '#0D7966',
    green00: '#00A485',
    grayE5: '#E5E5E5',
    grayCC: '#CCCCCC',
    grayC9: '#C9D2DA',
    grayBF: '#BFBFBF',
    grayB9: '#B9B9BF',
    grayB1: '#B1BBCE',
    gray8C: '#8CBBE5',
    gray8A: '#8A93A5',
    gray49: '#49697A',
    gray44: '#44587C',
    gray3D: '#3D5579',
    gray2B: '#2B426A',
    gray26: '#262626',
    black47: '#474747',
    black: '#042860',
    black04: '#040C0A',
    green0E2: '#0E2331',
    redFA: '#FA6868',
    redFF: '#FF4D4D',
    red: '#F04F4F',
    redF0: '#F02934',
    orangeFFB: '#FFB347',
    orangeFF: '#FF9332',
    orangeED: '#ED8D75',
    orangeFF6: '#FF6D6D',
    yellowF2: '#F2C114',
    yellowD6: '#D6A614'
};
import { Stack, Card, CardContent, Typography, Grid } from '@mui/material';
import { colors } from 'src/colors';
import {  CircleIcon } from 'src/assets/icons';

export const Twitter = (props: any) => {
  const { item, className= '' } = props;

  return (<>
        {item.map((_item: any) => ( 
          <Stack className={className} >
            <Card 
                sx={{ 
                    backgroundColor: colors.white,
                    boxShadow: 'none',
                    borderRadius: 0,
                    height: '100%',
                    position: 'relative'
                }}
            >
                    <Stack sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        padding: '10px'
                    }}>
                        <Stack flexDirection={'column'} >
                            <Typography
                                fontSize={12}
                                fontWeight={500}
                                color={colors.black}
                                lineHeight={'20px'}
                            >
                                {_item.title}
                            </Typography>
                            <Typography
                                fontSize={11}
                                fontWeight={400}
                                color={colors.black47}
                                lineHeight={'20px'}
                            >
                                {_item.subTitle}
                            </Typography>
                        </Stack>
                        <Typography
                            fontSize={11}
                            fontWeight={500}
                            color={colors.black}
                            lineHeight={'20px'}
                            paddingBottom={'30px'}
                            whiteSpace={'pre-wrap'}
                        >
                            {_item.content}
                        </Typography>
                        <Stack display={'flex'} flexDirection={'row'} position={'absolute'} bottom={'7px'} gap={'10px'} >
                            <Typography
                                fontSize={11}
                                fontWeight={400}
                                color={colors.black47}
                                lineHeight={'20px'}
                            >
                                {_item.time}
                            </Typography>
                            <Typography
                                fontSize={11}
                                fontWeight={400}
                                color={colors.black47}
                                lineHeight={'20px'}
                            >
                                {_item.lastSeen}
                            </Typography>
                        </Stack>
                    </Stack>
                </Card>
            </Stack>
        ))}
        
    </>);
}

export default Twitter;
import { Stack } from "@mui/material";
import Header from "src/components/header";
import NewsDetails from "src/features/latestNewsDetails";
import RelatedArticle from "src/features/latestNewsDetails/RelatedArticle";

export default function LatestNewsDetails() {

  return (
    <>
      <Header />
      <Stack id='latest-news' sx={{ width: '100%', marginTop: '45px' }}>
        <NewsDetails />
        <RelatedArticle />
      </Stack>
    </>

  )
}
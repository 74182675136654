import axios from 'axios';
import { _REFRESH_TOKEN, _TOKEN_NAME } from 'src/config/urls';
import { IUser } from 'src/models/authentication.model';

export const convertNumberToFloat = (num: any) => {
    num += '';
    if (num === "") {
        return num;
    }
    let [x1, x2] = num.split('.');
    if (x2 === undefined) {
        x2 = '';
    } else {
        x2 = '.' + x2;
    }

    return x1.split('').reverse().join('').match(/\d\d?\d?/g).reverse().map((v: any) => v.split('').reverse().join('')).join(',') + x2;
}

export const getGoogleAccountInfo = (value: any, callback: any) => {
    axios.get(`https://www.googleapis.com/oauth2/v1/userinfo`, {
        headers: {
            Authorization: `Bearer ${value.access_token}`,
            Accept: 'application/json'
        }
    }).then((res) => {
        callback(res.data);
        localStorage.setItem('userInfo', JSON.stringify({
            name: res.data.name,
            id: res.data.email
        }));
    })
        .catch((err) => console.log(err));
}

export function isLogin() {
    const userProfileStr = localStorage.getItem('userInfo') ?? '';
    const userToken = localStorage.getItem(_TOKEN_NAME) ?? '';
    let profile: IUser | undefined;
    if (userProfileStr !== '') {
        profile = JSON.parse(userProfileStr);
    }

    if ((profile) || userToken !== '') {
        return true;
    }
    return false;
}

export const removeToken: () => void = () => {
    localStorage.removeItem('userInfo');
    localStorage.removeItem(_TOKEN_NAME);
    localStorage.removeItem(_REFRESH_TOKEN);
};

export const createMarkup = (text: string) => {
    return { __html: text };
};
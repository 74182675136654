import React, { FunctionComponent } from 'react';
import { AreaChart, Area ,XAxis ,ResponsiveContainer } from 'recharts';

const data = [
  {
    day: "03 Oct",
    pv: 9000,
  },
  {
    day: "04 Oct",
    pv: 7200,
  },
  {
    day: "05 Oct",
    pv: 7000,
  },
  {
    day: "06 Oct",
    pv: 6800,
  },
  {
    day: "07 Oct",
    pv: 10,
  },
  {
    day: "08 Oct",
    pv: 5500,
  },
  {
    day: "09 Oct",
    pv: 4500,
  },
  {
    day: "10 Oct",
    pv: 4500,
  },
];

const CustomizedAxisTick: FunctionComponent<any> = (props: any) => {
    const { x, y, payload, fill } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={10}
          y={0}
          dy={16}
          fontSize={'12px'}
          fontWeight={400}
          textAnchor="end"
          fill={fill}
        >
          {payload.value}
        </text>
      </g>
    );
};

export default function Chart() {

    return (
      <ResponsiveContainer width='100%' height='100%'>
        <AreaChart width={100} height={149.2} data={data}>
        <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="1%" stopColor="#00E0B5" stopOpacity={0.009}/>
            <stop offset="80%" stopColor="#0b2036" stopOpacity={.328}/>
            </linearGradient>
        </defs>
          <XAxis dataKey="day" stroke='transparent' tick={<CustomizedAxisTick fill={'#00E0B5'} />} />
          <Area type="monotone" dataKey='pv' stroke="#00E0B5" fillOpacity={1} fill="url(#colorUv)" strokeWidth={2} />
        </AreaChart >
      </ResponsiveContainer>
    );
 
}

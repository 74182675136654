const dataCondition = [
    {
        id: 1,
        title : `**1. Acceptance of Terms and Conditions**`,
        content1: `By accessing or using the KOL Watch website ("the Website") and any associated services, you agree to be bound by these Terms and Conditions ("T&C"). If you do not agree with any part of these T&C, please do not use our services.`,
        content2: ``,
        content3: ``
    },
    {
        id: 2,
        title : `**2. Use of Services**`,
        content1: `2.1. You must be at least 18 years old or the legal age of majority in your jurisdiction to use our services. By using the Website, you confirm that you meet these age requirements.`,
        content2: `2.2. You agree not to engage in any activity on the Website that violates any applicable laws, regulations, or the rights of others.`,
        content3: `2.3. We reserve the right to modify, suspend, or terminate our services or access to the Website at any time without notice.`
    },
    {
        id: 3,
        title : `**3. Registration and User Accounts**`,
        content1: `3.1. To access certain features of the Website, you may be required to register for an account. You agree to provide accurate, current, and complete information during the registration process.`,
        content2: `3.2. You are responsible for maintaining the confidentiality of your account credentials and for any activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.`,
        content3: ``
    },
    {
        id: 4,
        title : `**4. Privacy and Data Protection**`,
        content1: `Our Privacy Policy governs the collection, use, and disclosure of your personal information. By using the Website, you consent to the practices described in our Privacy Policy.`,
        content2: ``,
        content3: ``
    },
    {
        id: 5,
        title : `**5. Intellectual Property**`,
        content1: `5.1. All content on the Website, including but not limited to text, graphics, logos, images, videos, and software, is the property of KOL Watch and is protected by intellectual property laws.`,
        content2: `5.2. You may not reproduce, distribute, modify, or create derivative works from any part of the Website without our prior written consent.`,
        content3: ``
    },
    {
        id: 6,
        title : `**6. Disclaimers and Limitations of Liability**`,
        content1: `6.1. The Website and its content are provided "as is" without warranties of any kind, either express or implied. We do not guarantee the accuracy, completeness, or reliability of any information on the Website.`,
        content2: `6.2. To the fullest extent permitted by law, KOL Watch and its affiliates shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of the Website or any errors or omissions in its content.`,
        content3: ``
    },
    {
        id: 7,
        title : `**7. Third-Party Websites and Services**`,
        content1: `7.1. The Website may contain links to third-party websites or services. We are not responsible for the content, privacy practices, or terms and conditions of these third-party sites. Your use of such sites is at your own risk.`,
        content2: ``,
        content3: ``
    },
    {
        id: 8,
        title : `**8. Governing Law and Jurisdiction**`,
        content1: `These T&C shall be governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law principles. Any legal action arising from these T&C shall be filed in the courts of UAE.`,
        content2: ``,
        content3: ``
    },
    {
        id: 9,
        title : `**9. Changes to Terms and Conditions**`,
        content1: `We reserve the right to modify these T&C at any time without notice. Any changes will be posted on this page, and the date of the latest revision will be indicated at the top.`,
        content2: ``,
        content3: ``
    },
    {
        id: 10,
        title : `**10. Contact Us**`
    }
]

export default dataCondition;

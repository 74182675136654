import { FC, ReactNode } from 'react';
import MuiDialog from '@mui/material/Dialog';

import { Transition } from './Transiation';
import { Box, IconButton, Stack } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
export interface DialogProps {
    children: ReactNode,
    width: string,
    open: boolean,
    onClose: () => void;
}

export const Dialog: FC<DialogProps> = ({ open, onClose, width, children }) => {
        return (
            <MuiDialog
                open={open}
                onClose={onClose}
                scroll={'paper'}
                maxWidth={'xs'}
                fullWidth={true}
                TransitionComponent={Transition}
                sx={{
                    '& div.MuiDialog-paper': {
                        width: width ? width : '100%',
                        maxWidth: width ? width : '100%',
                        // border: '2px solid #22B78F',
                        overflowY: 'hidden',

                        background: "transparent",
                        borderRadius: "0px",

                    }
                }}
            >
                <Stack justifyContent={'end'}>
                    <IconButton
                        sx={{
                            // top: '-22px',
                            // right: '-22px',
                            border: '1px solid #22B78F',
                            padding: '10x',
                            background: "#0E2230",
                            // position: "absolute"
                            marginLeft: 'auto',
                        }}
                        onClick={onClose}
                    >
                        <CloseRoundedIcon sx={{ color: 'white' }} />
                    </IconButton>
                </Stack>
                <Box sx={{
                    border: '2px solid #22B78F',
                    overflowY: 'hidden',
                    padding: '44px',
                    background: "#0E2230",
                    borderRadius: "0px",
                    marginRight: '44px',
                }}>

                    <Box sx={{ overflowY: 'auto' }}>
                        {children}
                    </Box>
                </Box>
            </MuiDialog>

        );
};
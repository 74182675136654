import {
    CommunityImg1,
    CommunityImg2,
    CommunityImg4
} from 'src/assets/icons';

const dataCommunity = [
    {
        id: 1,
        img: <CommunityImg2 />,
        content: 'Follow us and be first for all the things',
        btnText: 'Follow twitter'

    },
    {
        id: 2,
        img: <CommunityImg1 />,
        content: 'Follow us on Telegram',
        btnText: 'Open TELEGRAM'
    },

    {
        id: 3,
        img: <CommunityImg4 />,
        content: 'Subscribe to us on Youtube',
        btnText: 'Open Youtube'
    },
];
export default dataCommunity;
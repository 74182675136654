import { Stack, Typography, Card, CardContent, Box } from '@mui/material';
import './styles.scss';
import { Container, Button } from 'src/components';
import { colors } from 'src/colors';
import mbControlBanner from 'src/assets/images/common/mbControlBanner.png';
import controlBanner from 'src/assets/images/common/controlBanner.png';

function Control() {

    return (
        <Container>
            <Stack id='control' sx={{
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    <Stack className='control-box' sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '50%',
                        paddingTop: '70px'
                    }}>
                        <Typography
                            fontSize={52}
                            fontWeight={700}
                            lineHeight={'78px'}
                            color={colors.white}
                            className='control-box-title'
                        >
                            You're in control
                        </Typography>

                        <Typography
                            fontSize={21}
                            fontWeight={400}
                            color={colors.white}
                            lineHeight={'31.5px'}
                            padding={'11px 0 24px 0'}
                            whiteSpace={'pre-wrap'}
                            className='control-box-content'
                        >
                            {`Effectively manage your digital assets, swap, buy, sell, \n(even take a little inspiration from the famous) all \nfrom within your DappRadar Portfolio.`}
                        </Typography>

                        <Button
                            variant={"outlined"}
                            LabelColor={colors.white}
                            borderColor={colors.green}
                            backgroundColor={colors.green}
                            sx={{
                                width: 180
                            }}
                            className='btn-control'
                            onClick={() =>console.log("") }
                        >
                            <Typography 
                                fontSize={16}
                                fontWeight={600}
                                color={colors.white}
                                lineHeight={'34px'}
                            >
                                Go to portfolio app
                            </Typography>
                        </Button>

                        <Stack className='control-items-box' pt={'45px'} gap={'10px'}>
                            <Card className='control-card-items' sx={{ 
                                width: '426px',
                                height: '59px',
                                backgroundColor: 'rgba(20, 194, 163, 0.12)',
                                borderRadius: '10px'
                            }}>
                                <CardContent sx={{
                                    gap: '10px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                    <Stack sx={{
                                        width: '18px',
                                        height: '18px',
                                        borderRadius: '50%',
                                        backgroundColor: colors.white,
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <Typography 
                                            fontSize={14}
                                            fontWeight={700}
                                            color={colors.black}
                                            lineHeight={'18px'}
                                        >
                                            !
                                        </Typography>
                                    </Stack>
                                    <Typography 
                                        fontSize={21}
                                        fontWeight={700}
                                        color={colors.white}
                                        lineHeight={'32px'}
                                    >
                                        YouTube
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Card className='control-card-items' sx={{ 
                                width: '426px',
                                height: '59px',
                                backgroundColor: 'rgba(20, 194, 163, 0.12)',
                                borderRadius: '10px'
                            }}>
                                <CardContent sx={{
                                    gap: '10px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    padding: '16px 16px 7.5px 16px'
                                }}>
                                    <Stack sx={{
                                        width: '18px',
                                        height: '18px',
                                        borderRadius: '50%',
                                        backgroundColor: colors.white,
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <Typography 
                                            fontSize={14}
                                            fontWeight={700}
                                            color={colors.black}
                                            lineHeight={'18px'}
                                        >
                                            !
                                        </Typography>
                                    </Stack>
                                    <Typography 
                                        fontSize={21}
                                        fontWeight={700}
                                        color={colors.white}
                                        lineHeight={'32px'}
                                    >
                                        Telegram
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Card className='control-card-items' sx={{ 
                                width: '426px',
                                backgroundColor: 'rgba(20, 194, 163, 0.12)',
                                borderRadius: '10px 10px 0px 0px',
                                gap: '13px'
                            }}>
                                <CardContent sx={{
                                    gap: '10px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    padding: '16px 16px 11.5px 16px'
                                }}>
                                    <Stack sx={{
                                        width: '18px',
                                        height: '18px',
                                        borderRadius: '50%',
                                        backgroundColor: colors.white,
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <Typography 
                                            fontSize={14}
                                            fontWeight={700}
                                            color={colors.black}
                                            lineHeight={'18px'}
                                        >
                                            !
                                        </Typography>
                                    </Stack>
                                    <Typography 
                                        fontSize={21}
                                        fontWeight={700}
                                        color={colors.white}
                                        lineHeight={'32px'}
                                    >
                                        Twitter
                                    </Typography>
                                </CardContent>
                                <CardContent sx={{
                                    padding: '0 16px 11.5px 16px !important'
                                }}>
                                    <Typography 
                                        fontSize={16}
                                        fontWeight={400}
                                        color={colors.white}
                                        whiteSpace={'pre-wrap'}
                                        className='control-items-text'
                                    >
                                        Analyse your token collection trends over time, view
                                        individual token performance, explore the market &
                                        swap
                                    </Typography>
                                </CardContent>
                                <Stack 
                                    sx={{
                                        position: 'relative',
                                        width: '100%',
                                        height: "1px",
                                        backgroundColor: 'rgba(255, 255, 255, 0.2)'
                                    }} 
                                >
                                    <Stack
                                        sx={{
                                            width: '2%',
                                            height: "1px",
                                            backgroundColor: '#516B9B'
                                        }} 
                                    />
                                </Stack>
                                
                            </Card>
                            
                        </Stack>

                    </Stack>

                    <Stack className='control-banner-box' sx={{ width: '50%' }}>
                        <Box 
                            className='control-banner-img' 
                            width={780} 
                            height={800} 
                            style={{ maxWidth: 'initial' }}
                            component='img' 
                            src={controlBanner} 
                        />
                        <Box 
                            className='mb-control-banner-img' 
                            width={'100%'} 
                            height={'100%'}
                            component='img' 
                            src={mbControlBanner} 
                        />
                    </Stack>           
            </Stack>
        </Container>
    );
}

export default Control;
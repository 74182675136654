export function AllRankingIcon({
    color,
    width,
    height
}: { color?: string, width?: string, height?: string }) {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg"
            width={width ?? "24"} 
            height={height ?? "24"} 
            viewBox="0 0 24 24"
            fill="none"
        >
            <path 
                fill-rule="evenodd" 
                clip-rule="evenodd" 
                d="M12 22.5498C11.67 22.5498 11.34 22.4698 11.05 22.2998L3.55001 17.9698C3.26331 17.802 3.02506 17.5627 2.85854 17.2752C2.69203 16.9878 2.60295 16.662 2.60001 16.3298V7.66982C2.60001 6.98982 2.96001 6.36982 3.55001 6.02982L11.05 1.69982C11.63 1.35982 12.36 1.35982 12.95 1.69982L20.44 6.02982C21.02 6.36982 21.39 6.99982 21.39 7.66982V16.3198C21.39 16.9998 21.03 17.6198 20.44 17.9598L12.95 22.2898C12.66 22.4598 12.33 22.5398 12 22.5398V22.5498ZM4.61001 16.2698L12 20.5398L19.39 16.2698V7.72982L12 3.46982L4.61001 7.72982V16.2698Z"
                fill={color ?? "#B1BBCE"}
            />
            <path 
                fill-rule="evenodd" 
                clip-rule="evenodd" 
                d="M9.60001 11.55H7.60001V15.07H9.60001V11.55ZM16.12 10.27H14.12V15.07H16.12V10.27ZM12.86 8.20996H10.86V15.07H12.86V8.20996Z"
                fill={color ?? "#B1BBCE"}
            />
        </svg>
    );
}

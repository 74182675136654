import { Stack } from "@mui/material";
import { ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "src/components/header";
import CompleteYourProfile from "src/features/authentication/pages/register/CompleteYourProfile";
import CreateYourProfile from "src/features/authentication/pages/register/CreateYourProfile";
import IntroduceYourCryptoProjects from "src/features/authentication/pages/register/IntroduceYourCryptoProjects";
import MoreAboutYou from "src/features/authentication/pages/register/MoreAboutYou";

export default function SubmitKol(): ReactElement {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  return (<>
    <Header />
    <Stack height={'100%'} alignItems={'center'}>
      {step === 1
        ? <CreateYourProfile setStep={setStep} />
        : step === 2
          ? <CompleteYourProfile setStep={setStep} />
          : step === 3
            ? <IntroduceYourCryptoProjects setStep={setStep} />
            : step === 4
              ? <MoreAboutYou setStep={setStep} />
              : <></>

      }
    </Stack>
  </>
  )
}
import { useState } from 'react';
import './styles.scss';
import { Swiper as SwiperProps, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import { Stack, Typography, Grid, Box } from '@mui/material';
import dataWallet from '../../../assets/fake-data/data-wallet';
import { Container} from '../../../components';
import { colors } from '../../../colors';
import Wallet from './components/Wallet';
import { GrayArrowRight, GrayArrowLeft, Fire } from 'src/assets/icons';

function Browse() {

    const [swiper, setSwiper] = useState<SwiperProps>();
    const [isEnd, setIsEnd] = useState<boolean | undefined>(undefined);

    return (
        <Stack id='browse' sx={{
            paddingY: '80px'
        }}>
            <Container>
                <Stack className='browse-box' sx={{
                    position: 'relative',
                    gap: '7.95px'
                }}>
                    <Stack sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Stack sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '10px'
                        }}>
                            <Fire className='title-icon' />
                            <Typography
                                fontSize={24}
                                fontWeight={700}
                                color={colors.white}
                                lineHeight={'36px'}
                                className='browse-title'
                            >
                                Browse and be inspired by celebrity and crypto whale wallets
                            </Typography>
                            <Typography
                                fontSize={24}
                                fontWeight={700}
                                color={colors.white}
                                lineHeight={'36px'}
                                className='mb-browse-title'
                            >
                                You're in control
                            </Typography>
                        </Stack>
                        <Stack sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'row-reverse',
                            gap: '12px'
                        }}>
                            <Box
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                width={32}
                                height={32}
                                bgcolor={colors.gray2B}
                                borderRadius={'50px'}
                                zIndex={11}
                                sx={{
                                    cursor: 'pointer',
                                    opacity: isEnd ? .5 : 1
                                }}
                                onClick={() => swiper?.slideNext()}
                                >
                                    <GrayArrowRight />
                            </Box>
                            <Box
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                width={32}
                                height={32}
                                bgcolor={colors.gray2B}
                                borderRadius={'50px'}
                                zIndex={11}
                                sx={{
                                    cursor: 'pointer',
                                    opacity: !isEnd ? .5 : 1
                                }}
                                onClick={() => swiper?.slidePrev()}
                            >
                                <GrayArrowLeft />
                            </Box>
                        </Stack>
                    </Stack>
                    <Grid container>
                        <Swiper
                            modules={[ Scrollbar, A11y ]}
                            spaceBetween={20}
                            scrollbar={{ draggable: true }}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                320: {
                                    slidesPerView: 1.4
                                },
                                480: {
                                    slidesPerView: 1.7
                                },
                                520: {
                                    slidesPerView: 1.9
                                },
                                600: {
                                    slidesPerView: 2
                                },
                                767: {
                                    slidesPerView: 3,
                                },
                                991: {
                                    slidesPerView: 5,
                                },
                            }}
                            onSwiper={(swiper: SwiperProps) => setSwiper(swiper)}
                            onReachEnd={(e: any) => { 
                                if(!e.isBeginning){
                                    setIsEnd(true);
                                } else {
                                    setIsEnd(false);
                                } 
                            }}
                            onReachBeginning={() => { setIsEnd(false); }}
                            style={{ position: 'unset' }}
                            className='browse-swiper'
                        >
                            {
                                dataWallet.map((item: any) => (
                                    <SwiperSlide style={{ justifyContent: 'center', display: 'flex' }} key={item.id}>
                                        <Wallet items={item} />
                                    </SwiperSlide>
                                        
                                ))
                            }
                        </Swiper>
                    </Grid>
                </Stack>
            </Container>
        </Stack>
    );
}

export default Browse;
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss'

Cookies.propTypes = {
    data: PropTypes.array,
};

function Cookies(props) {

    const { data } = props;
  
    const [dataBlock] = useState(
        {
            subtitle: 'cookies policy',
            title: 'KOLWatch',
        }
    )
   
    return (
        <section className="tf-section tf-cookies">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="content-cookies mobie-40" data-aos="fade-up" data-aos-duration="800">
                            <div className="tf-title">
                                <p className="h8 sub-title">{dataBlock.subtitle}</p>
                                <h4 className="title">{dataBlock.title}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row text" data-aos="fade-up" data-aos-duration="800">
                <div className="container">
                    <div className="row">
                        <p>
                            At KOL WATCH, we value your privacy and aim to provide you with the best possible user experience. To achieve this, our website uses cookies and similar technologies. This Cookies Policy explains what cookies are, how we use them, and your choices regarding their usage.
                        </p>
                    </div>  
                    <div className="row">
                        {
                            data.slice(0,5).map(item => (<>
                                <p>{item.title}</p>
                                <p>{item.content1}</p>
                                <p>{item.content2}</p>
                                <p>{item.content3}</p>
                            </>))
                        }
                    </div> 
                    <div className="row">
                        <p>
                            
                        </p>
                        <p style={{
                            whiteSpace: 'pre-wrap'
                        }}>
                            {`If you have any questions or concerns about this Cookies Policy or the use of cookies on our website, please contact us at [contact email].\nBy continuing to use KOL WATCH, you acknowledge that you have read and understood this Cookies Policy and consent to the use of cookies as described herein.`}
                        </p>
                    </div>      
                </div>
            </div>
        </section >
    );
}

export default Cookies;
import { ReactElement, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { colors } from 'src/colors';
import Card from './Card';
import Comment from './Comment';
import AddPost from './AddPost';
import { ChatIcon, GreenLikeIcon, UploadIcon } from 'src/assets/icons';
import { Button } from 'src/components';

export default function Feed({ id, data }: { id: any, data?: any }): ReactElement {

    const [more, setMore] = useState<boolean>(true);
    
    const handleClick = () => { 
        setMore(!more); 
    }; 

    return (<>
        {data.map((info: any) => {
            if (info.id === id) {
            return (<>
                {/* <AddPost item={info} /> */}
                <Stack gap={'2px'}>
                    <Stack sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: colors.green0E2,
                        justifyContent: 'flex-start',
                        padding: '24px',
                        gap: '25px',
                        overflow: 'hidden'
                    }}>
                        <Card status={'feed'} item={info} />
                        <Stack flexDirection={'row'} gap={'35px'}>
                            <Stack flexDirection={'row'} alignItems={'center'} gap={'11px'}>
                                <ChatIcon />
                                <Typography 
                                    fontSize={14}
                                    fontWeight={400}
                                    color={colors.grayB1}
                                    lineHeight={'18px'}
                                >
                                    {info.FeedMessageCount}
                                </Typography>
                            </Stack>
                            <Stack flexDirection={'row'} alignItems={'center'} gap={'11px'}>
                                <GreenLikeIcon />
                                <Typography 
                                    fontSize={14}
                                    fontWeight={400}
                                    color={colors.grayB1}
                                    lineHeight={'18px'}
                                >
                                    {info.FeedLikeCount}
                                </Typography>
                            </Stack>
                            {/* <Stack flexDirection={'row'} alignItems={'center'} gap={'11px'}>
                                <UploadIcon />
                                <Typography 
                                    fontSize={14}
                                    fontWeight={400}
                                    color={colors.grayB1}
                                    lineHeight={'18px'}
                                >
                                    0
                                </Typography>
                            </Stack> */}
                            
                        </Stack>
                        {
                            info.feeds.map((comment: any) => (
                                <Comment comment={comment} />  
                            ))
                        } 
                    {/* {!more &&  <Comment />} */}
                    {!more && <Stack position={'relative'}>
                            <label style={{ 
                                position: 'absolute',
                                top: '22px',
                                left: '12px',
                                fontSize: '14px',
                                fontWeight: 400,
                                color: colors.white
                            }}></label>
                            <input
                                type="text"
                                id="comment"
                                placeholder='Enter your comment'
                                style={{
                                    width: "100%",
                                    height: '76px',
                                    padding: "11px",
                                    border: `2px solid ${colors.green}`,
                                    color: colors.white,
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    lineHeight: '16.41px'
                                }}
                            />
                            <Button
                                variant={"outlined"}
                                LabelColor={colors.white}
                                backgroundColor={`rgba(20, 194, 163, 0.2)`}
                                sx={{
                                    position: 'absolute',
                                    top: '13px',
                                    right: '13px',
                                    width: '145px',
                                    border: 0
                                }}
                            >
                                <Typography 
                                    fontSize={16}
                                    fontWeight={700}
                                    color={colors.white}
                                    lineHeight={'16px'}
                                >
                                    Comment
                                </Typography>
                            </Button>
                        </Stack>}
                    {more && <Typography 
                            fontSize={16}
                            fontWeight={700}
                            color={colors.green}
                            lineHeight={'16px'}
                            textAlign={'center'}
                            sx={{ cursor: 'pointer' }}
                            onClick={handleClick}
                        >
                            See more...
                        </Typography>}
                    </Stack>

                    <Stack sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: colors.green0E2,
                        justifyContent: 'center',
                        padding: '24px',
                        gap: '25px'
                    }}>
                        <Card status={'comment'} item={info} />
                        <Stack flexDirection={'row'} gap={'55px'}>
                            <Stack flexDirection={'row'} alignItems={'center'} gap={'11px'}>
                                <ChatIcon />
                                <Typography 
                                    fontSize={14}
                                    fontWeight={400}
                                    color={colors.grayB1}
                                    lineHeight={'18px'}
                                >
                                    0
                                </Typography>
                            </Stack>
                            <Stack flexDirection={'row'} alignItems={'center'} gap={'11px'}>
                                <GreenLikeIcon />
                                <Typography 
                                    fontSize={14}
                                    fontWeight={400}
                                    color={colors.grayB1}
                                    lineHeight={'18px'}
                                >
                                    150
                                </Typography>
                            </Stack>
                            {/* <Stack flexDirection={'row'} alignItems={'center'} gap={'11px'}>
                                <UploadIcon />
                                <Typography 
                                    fontSize={14}
                                    fontWeight={400}
                                    color={colors.grayB1}
                                    lineHeight={'18px'}
                                >
                                    0
                                </Typography>
                            </Stack> */}
                            
                        </Stack>
                    </Stack>
                </Stack>
            </>)
            }
        })}
    </>);
}
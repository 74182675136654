import { Stack, Typography, Hidden } from '@mui/material';
import './styles.scss';
import dataTag from '../../../assets/fake-data/data-tag';
import { Container, Button, Tag } from 'src/components';
import { colors } from 'src/colors';
import { useNavigate } from 'react-router-dom';
import { BannerIcon } from 'src/assets/icons';
import { useState } from 'react';
import { ExitingDialog } from 'src/components/dialog/exitingdialog/ExcitingDialog';

function Banner() {

    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);

    return (
        <Container>
            <Stack className='banner' sx={{
                display: 'flex',
                flexDirection: 'row'
            }}>
                <Stack className='banner-header-left' sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '50%'
                }}>
                    <Typography
                        fontSize={82}
                        fontWeight={700}
                        color={colors.white}
                        lineHeight={'82px'}
                        whiteSpace={'pre-wrap'}
                        className='title'
                    >
                        {`The World’s KOL \nVerification \nDapp`}
                    </Typography>

                    <Typography
                        fontSize={21}
                        fontWeight={400}
                        color={colors.white}
                        paddingTop={'20px'}
                        lineHeight={'31.92px'}
                        whiteSpace={'pre-wrap'}
                        className='subtitle'
                    >
                        {`Discover, track and invest in your favourite KOL’s \nin the crypto world.`}
                    </Typography>

                    <Stack className='btn-box' sx={{
                        flexDirection: 'row',
                        paddingTop: '30px',
                        gap: '16px',
                        paddingBottom: '50px'
                    }}>
                        <Button
                            variant={"outlined"}
                            LabelColor={colors.white}
                            borderColor={colors.green}
                            backgroundColor={colors.green}
                            sx={{ width: 180 }}
                            className='btn-explore'
                            onClick={() => navigate('/top-influencers')}
                        >
                            <Typography
                                fontSize={16}
                                fontWeight={600}
                                color={colors.white}
                                lineHeight={'34px'}
                            >
                                Explore Ranking
                            </Typography>
                        </Button>
                        <Button
                            variant={"contained"}
                            LabelColor={colors.white}
                            borderColor={colors.yellowD6}
                            backgroundColor={colors.yellowD6}
                            bgHover={colors.yellowD6}
                            className='btn-listed'
                            sx={{
                                fontSize: 16,
                                fontWeight: 600,
                                width: 137,
                            }}
                            onClick={() => {
                                setOpenDialog(true);
                            }
                            }
                        >
                            <Typography
                                fontSize={16}
                                fontWeight={600}
                                color={colors.white}
                                lineHeight={'34px'}
                            >
                                Get Listed!
                            </Typography>
                        </Button>

                    </Stack>

                    <Stack pt={'30px'} gap={'20px'} className='trending'>
                        <Typography
                            fontSize={16}
                            fontWeight={400}
                            color={colors.white}
                        >
                            Trending
                        </Typography>

                        <Stack
                            display={"flex"}
                            flexDirection={"row"}
                            alignItems={"center"}
                            gap={"12px"}
                            className='trending-tag'
                        >
                            {dataTag.slice(0, 7).map((item: any) => {
                                return <Tag
                                    title={item.text}
                                    mode={'circle'}
                                    sx={{ padding: '8px 17.52px' }}
                                />
                            })}
                        </Stack>

                    </Stack>
                </Stack>
                <Hidden smDown>                
                    <Stack sx={{ width: '50%' }}>
                        <BannerIcon width={806.41} height={683.47} />
                    </Stack>
                </Hidden>
            </Stack>
            {/* <ExitingDialog 
                open={openDialog} 
                onClose={() => setOpenDialog(false)}
            /> */}
        </Container>
    );
}

export default Banner;
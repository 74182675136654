import { UPDATE_PROFILE_URL, UPLOAD_PHOTO_URL } from "src/config/apiUrls";
import { api } from "./api";
import { IUpdateUserInfo } from "src/slices/mainSlice";
import { useAppSelector } from "src/hooks/ReduxHooks";
interface IToken { token: string; }
interface IUpdateUserInfoToken extends IToken { data: IUpdateUserInfo }
interface IUpdatePhoto extends IToken { photo: any }
const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    uploadProfile: builder.mutation<any, IUpdatePhoto>({
      query: (params) => ({
        url: UPLOAD_PHOTO_URL,
        method: 'POST',
        body: params.photo,
        headers: {
          Authorization: `Bearer ${params.token}`,
        }
      })
    }),
    updateProfile: builder.mutation<any, IUpdateUserInfoToken>({
      query: (params) => ({
        url: UPDATE_PROFILE_URL,
        method: 'PUT',
        body: params.data,
        headers: {
          Authorization: `Bearer ${params.token}`,
        }
      })
    })
  })


})
export const {
  useUploadProfileMutation,
  useUpdateProfileMutation,

} = userApi;
import { Stack } from "@mui/material";
import { ReactElement } from "react";
import Login from "src/features/authentication/pages/login";
import Header from "src/components/header";
import { GoogleOAuthProvider } from '@react-oauth/google';
export default function LoginPage(): ReactElement {
    return (<>
        <Header />
        <Stack height={'100%'} alignItems={'center'}>
            <GoogleOAuthProvider clientId="248586544888-8mpt84r60uhjar4kvsvja5p7ka0k776m.apps.googleusercontent.com">
                <Login />
            </GoogleOAuthProvider>
        </Stack>
    </>);
}
export function TwitterIcon({
    color,
    width,
    height
}: { color?: string, width?: string, height?: string }) {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg"
            width={width ?? "18"} 
            height={height ?? "18"} 
            viewBox="0 0 18 18"
            fill="none"
        >
            <path 
                d="M14.1757 0.864746H16.9357L10.9058 7.75725L18 17.1345H12.4455L8.0955 11.4465L3.117 17.1345H0.3555L6.8055 9.762L0 0.865496H5.6955L9.62775 6.0645L14.1757 0.864746ZM13.2075 15.483H14.7367L4.8645 2.43H3.2235L13.2075 15.483Z" 
                fill={color ?? "#B1BBCE"}
            />
        </svg>
    );
}


import { Stack, Typography, Card, CardContent } from '@mui/material';
import './styles.scss';
import dataCommunity from '../../../assets/fake-data/data-community';
import { Container, Button } from '../../../components';
import { colors } from '../../../colors';
import { communityBackground } from 'src/assets/icons';

function Community() {

    return (
        <Stack id='community' sx={{
            paddingY: '80px'
        }}>
            <Container className='community-container'>

                <div className='community-box' style={{
                    // backgroundImage: `url("/static/media/communityBackground.d8f2eb1033fd319ccb411acd65e858be.svg")`,
                    backgroundImage: `url(${communityBackground})`,
                }}>
                    <Stack className='community-title-box'>
                        <Typography
                            fontSize={52}
                            fontWeight={700}
                            color={colors.white}
                            lineHeight={'78px'}
                            whiteSpace={'pre-wrap'}
                            className='community-title'
                        >
                            {`One World, \nOne Community`}
                        </Typography>
                    </Stack>
                    <Stack className='community-section-left' sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        gap: '17px',
                        paddingX: '25px'
                    }}>
                        {
                            dataCommunity.map((item: any) => (
                                <Card className='community-card-box'>
                                    <CardContent sx={{
                                        gap: '36px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '24px 24px 16px 24px !important'
                                    }}>
                                        <Stack sx={{
                                            width: '80px',
                                            height: '80px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            backgroundColor: colors.blue4C,
                                            borderRadius: '50%',
                                            justifyContent: 'center',
                                        }}>
                                            {item.img}
                                        </Stack>
                                        <Typography
                                            fontSize={18}
                                            fontWeight={400}
                                            color={colors.white}
                                            textAlign={'center'}
                                            lineHeight={'24px'}
                                            height={'48px'}
                                        >
                                            {item.content}
                                        </Typography>
                                        <Button
                                            variant={"outlined"}
                                            LabelColor={colors.white}
                                            borderColor={colors.green}
                                            backgroundColor={colors.green}
                                            sx={{
                                                padding: '16px 24px'
                                            }}
                                            onClick={() => console.log("")}
                                        >
                                            <Typography
                                                fontSize={16}
                                                fontWeight={700}
                                                color={colors.white}
                                                lineHeight={'16px'}
                                                textTransform={'uppercase'}
                                            >
                                                {item.btnText}
                                            </Typography>
                                        </Button>
                                    </CardContent>

                                </Card>
                            ))
                        }
                    </Stack>
                </div>
            </Container>
        </Stack>
    );
}

export default Community;
import { Box, Breadcrumbs, Stack, Typography, Container as MuiContainer } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Link from '@mui/material/Link';
import WriteRow from "./WriterRow";
import image from 'src/assets/images/news/news1.png';
import off from 'src/assets/images/news/off.png';
import { Button, Container } from 'src/components';
import { colors } from "src/colors";

export default function LatestNewsDetails() {
  const titleStyle = {
    color: "#FFF",
    fontFamily: "Chakra Petch",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "18px"
  }
  const contentStyle = {
    color: "#B1BBCE",
    fontFamily: "Chakra Petch",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "26px"
  }
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      sx={{
        color: "#FFF",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        textTransform: "capitalize"
      }}
      href="/" >
      Latest news
    </Link>,
    <Link
      sx={{
        color: "#B1BBCE",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        textTransform: "capitalize"
      }}
      underline="hover"
      key="2"
      color="inherit"
      href="/"
    >
      Web3 Dates for NFT Mints, Game Tests and DeFi Upgrades
    </Link>,

  ];
  return (
    <Container
    >
      <Stack direction='row' mb='43px'>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" style={{ color: 'white' }} />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>

      <Typography sx={{
        color: "#FFF",
        fontFamily: "Chakra Petch",
        fontSize: "80px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "80px",
        marginBottom: '24px',
      }}>
        Web3 Dates for NFT Mints, Game Tests and DeFi Upgrades
      </Typography>


      <WriteRow />
      <Box component='img'
        width='100%'
        marginY={'24px'}
        src={image} />
      <MuiContainer maxWidth='md'>
        <Stack spacing={'32px'}>
          <Typography sx={titleStyle}>A week full of fun, ending with a major announcement </Typography>
          <Typography sx={contentStyle}>On 14 December, it will be 2 years since DappRadar launched the RADAR token and the World’s Dapp Store started its journey towards a decentralized and community-governed future. Starting today, we will celebrate this milestone with a week-long celebration together with various key partners in the Web3 industry.</Typography>
          <Box component={'img'}
            width={'100%'}
            src={off} />
          <Typography sx={contentStyle}>On 14 December, it will be 2 years since DappRadar launched the RADAR token and the World’s Dapp Store started its journey towards a decentralized and community-governed future. Starting today, we will celebrate this milestone with a week-long celebration together with various key partners in the Web3 industry.</Typography>
          <Typography pt={'32px'} sx={titleStyle}>A week-long celebration</Typography>
          <Typography sx={contentStyle}>From 7 December until 14 December, DappRadar will celebrate the second anniversary of RADAR together with our community and key industry partners. Through our Quests platform, during this week full of celebrations Web3 explorers can complete awesome quests from partners like Aavegotchi, Phantom Galaxies, Forest Knight, Planet IX, PlayDapp and more.</Typography>
          <Typography sx={contentStyle}>Come back regularly to see which new quests have been unlocked. We will end the week-long celebration with a major reveal. So make sure to complete the quests and get ready for year 3 of RADAR.</Typography>

          <Button
            variant={"outlined"}
            LabelColor={colors.white}
            borderColor={colors.green}
            backgroundColor={colors.green}
            sx={{ width: 154 }}
            className='btn-explore'
          >
            <Typography
              fontSize={16}
              fontWeight={600}
              color={colors.white}
              lineHeight={'34px'}
            >
              Lorem ipsum
            </Typography>
          </Button>
          <Typography sx={titleStyle}>A week full of fun, ending with a major announcement </Typography>
          <Typography sx={contentStyle}>On 14 December, it will be 2 years since DappRadar launched the RADAR token and the World’s Dapp Store started its journey towards a decentralized and community-governed future. Starting today, we will celebrate this milestone with a week-long celebration together with various key partners in the Web3 industry.</Typography>
          <Box component={'img'}
            width={'100%'}
            src={off} />
          <Typography sx={contentStyle}>On 14 December, it will be 2 years since DappRadar launched the RADAR token and the World’s Dapp Store started its journey towards a decentralized and community-governed future. Starting today, we will celebrate this milestone with a week-long celebration together with various key partners in the Web3 industry.</Typography>
          <Typography pt={'32px'} sx={titleStyle}>A week-long celebration</Typography>
          <Typography sx={contentStyle}>From 7 December until 14 December, DappRadar will celebrate the second anniversary of RADAR together with our community and key industry partners. Through our Quests platform, during this week full of celebrations Web3 explorers can complete awesome quests from partners like Aavegotchi, Phantom Galaxies, Forest Knight, Planet IX, PlayDapp and more.</Typography>
          <Typography sx={contentStyle}>Come back regularly to see which new quests have been unlocked. We will end the week-long celebration with a major reveal. So make sure to complete the quests and get ready for year 3 of RADAR.</Typography>

          <Button
            variant={"outlined"}
            LabelColor={colors.white}
            borderColor={colors.green}
            backgroundColor={colors.green}
            sx={{ width: 154 }}
            className='btn-explore'
          >
            <Typography
              fontSize={16}
              fontWeight={600}
              color={colors.white}
              lineHeight={'34px'}
            >
              Lorem ipsum
            </Typography>
          </Button>

        </Stack>
      </MuiContainer>
    </Container>
  )
}
import { Stack, Typography, Grid } from '@mui/material';
import './styles.scss';
import { colors } from 'src/colors';
import { Container, MyCard } from 'src/components';
import { dataInfluence } from 'src/assets/fake-data/data-influence';

function Performance() {

    return (
            <Stack id='performance' sx={{ paddingY: '80px' }} >
                <Container>
                    <Stack gap={'42px'} className='performance-box' >
                        <Typography
                            fontSize={44}
                            fontWeight={700}
                            color={colors.white}
                            whiteSpace={'pre-wrap'}
                            lineHeight={'52.8px'}
                            className='performance-title'
                        >
                            {`ALL KOL’S and influencers \nhistory and performance in one place!`}
                        </Typography>

                        <Stack gap={'16px'} className='ds-card-box'>
                            <Stack display={'flex'} flexDirection={'row'} gap={'16px'}>
                                {dataInfluence.slice(0, 5).map((item: any) => (
                                    <MyCard
                                        width={'274.51px'}
                                        items={item}
                                        key={item.id}
                                    />
                                ))}
                            </Stack>
                            <Stack display={'flex'} flexDirection={'row'} gap={'16px'}>
                                {dataInfluence.slice(5, 11).map((item: any) => (
                                    <MyCard
                                        width={'226.17px'}
                                        items={item}
                                        key={item.id}
                                    />
                                ))}
                            </Stack>
                        </Stack>
                        <Stack gap={'16px'} className='mb-card-box'>
                            <Grid container spacing={1} className='card-section'>
                                {dataInfluence.map((item: any) => (
                                    <MyCard
                                        items={item}
                                        className='card-items'
                                        key={item.id}
                                    />
                                ))}
                            </Grid>
                        </Stack>
                    </Stack>
                </Container>
            </Stack>
    );
}

export default Performance;
import { useState } from 'react';
import './styles.scss';
import { Swiper as SwiperProps, Scrollbar, A11y  } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import { Stack, Typography, Grid, Box } from '@mui/material';
import dataLatestNews from '../../../assets/fake-data/data-latest-news';
import { Container } from '../../../components';
import { colors } from '../../../colors';
import LatestNewsItem from './components/LatestNewsItem';
import { RightArrow, LeftArrow} from 'src/assets/icons';

function LatestNews() {

    const [swiper, setSwiper] = useState<SwiperProps>();
    const [isEnd, setIsEnd] = useState<boolean | undefined>(undefined);

    return (
        <Stack id='latestnews' sx={{
            backgroundColor: colors.green0E2,
            paddingY: '80px'
        }}>
            <Container>
                <Stack className='latestnews-box' sx={{
                    position: 'relative',
                    gap: '45px'
                }}>
                    <Stack sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Typography
                            fontSize={44}
                            fontWeight={700}
                            color={colors.white}
                            className='latestnews-title'
                        >
                            Latest news
                        </Typography>
                        <Stack sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'row-reverse',
                            gap: '12px'
                        }}>
                            <Box
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                width={32}
                                height={32}
                                bgcolor={colors.green}
                                borderRadius={'50px'}
                                zIndex={11}
                                sx={{
                                    cursor: 'pointer',
                                    opacity: isEnd ? .5 : 1
                                }}
                                onClick={() => swiper?.slideNext()}
                                >
                                    <RightArrow />
                            </Box>
                            <Box
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                width={32}
                                height={32}
                                bgcolor={colors.green}
                                borderRadius={'50px'}
                                zIndex={11}
                                sx={{
                                    cursor: 'pointer',
                                    opacity: !isEnd ? .5 : 1
                                }}
                                onClick={() => swiper?.slidePrev()}
                            >
                                <LeftArrow />
                            </Box>
                        </Stack>
                    </Stack>
                    <Grid container>
                        <Swiper
                            modules={[ Scrollbar, A11y ]}
                            spaceBetween={15}
                            scrollbar={{ draggable: true }}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                320: {
                                    slidesPerView: 2
                                },
                                480: {
                                    slidesPerView: 2
                                },
                                640: {
                                    slidesPerView: 2
                                },
                                768: {
                                    slidesPerView: 2,
                                },
                                991: {
                                    slidesPerView: 4.03,
                                },
                            }}
                            onSwiper={(swiper: SwiperProps) => setSwiper(swiper)}
                            onReachEnd={(e: any) => { 
                                if(!e.isBeginning){
                                    setIsEnd(true);
                                }else {
                                    setIsEnd(false);
                                } 
                            }}
                            onReachBeginning={() => { setIsEnd(false); }}
                            style={{ position: 'unset', marginLeft:0, marginRight: 0 }}
                        >
                            {
                                dataLatestNews.map(item => (
                                    <SwiperSlide style={{ justifyContent: 'center', display: 'flex' }}  key={item.id}>
                                        <LatestNewsItem items={item} />
                                    </SwiperSlide>
                                        
                                ))
                            }
                        </Swiper>
                    </Grid>
                </Stack>
            </Container>
        </Stack>
    );
}

export default LatestNews;
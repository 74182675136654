import { FC } from 'react';
import { Stack, Typography } from "@mui/material";
import { Dialog } from "src/components/dialog/Dialog";
import { colors } from "src/colors";
import { Button } from "src/components";
export interface IProps {
  open: boolean,
  onClose: () => void;
}

export const ExitingDialog: FC<IProps> = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      width="670px"
    >
      <Stack>
        <Stack gap={'15px'}>
          <Typography 
            fontSize={44} 
            fontWeight={700} 
            lineHeight={'52.8px'}
            color={colors.green2A}
            textTransform={'uppercase'}
          >
              Exciting Features on the Horizon!
          </Typography>
          <Typography 
            fontSize={24} 
            fontWeight={500} 
            color={colors.white}
            textTransform={'uppercase'}
          >
            Get ready for some amazing new features
            coming your way soon!  Stay tuned for these
            updates!
          </Typography>
        </Stack>
        <Button
          variant={"contained"}
          LabelColor={colors.white}
          borderColor={colors.green}
          backgroundColor={colors.green}
          fullWidth={true}
          sx={{
            fontSize: 16,
            fontWeight: 600,
            marginTop: '30px',
          }}
          onClick={onClose}
        >
          <Typography
            fontSize={16}
            fontWeight={600}
            color={colors.white}
            lineHeight={'34px'}
            textTransform={'uppercase'}
          >
            Got it
          </Typography>
        </Button>

      </Stack>
    </Dialog>
  );
}
import { ReactElement, useState } from 'react';
import { Stack, FormControlLabel, Typography } from '@mui/material';
import { colors } from 'src/colors';
import MyAreaChart from './AreaChart';
import Tabs from './Tabs';
import PieChart from './PieChart';
import { MyCheckbox } from "src/components/checkbox/CheckBox";
import { Sample } from 'src/assets/icons';

//////
//Gh//
//////

export function Charts(): ReactElement {

    const [isChecked, setIsChecked] = useState<any>({ 'GBP': true });

    const handleChange = ({target:{checked, value}}: any) => {
        setIsChecked({
          ...isChecked,
          [value]: checked
        })
    }
    
    return (
            <Stack display={'flex'} flexDirection={'row'} sx={{ width: '100%' }} gap={'15px'}>
                <Stack sx={{ width: '70%', flexDirection: 'column', gap: '30px' }}>
                    <Stack height={'330px'}>
                        <MyAreaChart />
                    </Stack>   
                    <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Tabs /> 
                        <Stack flexDirection={'row'}>
                            {chartItems.slice(0, 3).map(item => (
                                <FormControlLabel
                                    key={item.id}
                                    sx={{
                                        '& .MuiTypography-root': {
                                            fontSize: '14px', 
                                            fontWeight: 400,
                                            lineHeight: '20px',
                                            color: isChecked[item.label] ? colors.white : colors.grayCC
                                        }
                                    }}
                                    control={
                                        <MyCheckbox
                                            defaultChecked={isChecked[item.label]} 
                                            onChange={handleChange}
                                            checked={isChecked[item.label]}
                                            value={item.label}
                                        />
                                    } 
                                    label={item.label} 
                                />
                            ))}               
                        </Stack>  
                    </Stack>          
                </Stack>
                <Stack sx={{ width: '30%', flexDirection: 'column', gap: '30px' }}>
                    <Stack height={'230px'}>
                        <PieChart />  
                    </Stack>  
                    <Stack flexDirection={'row'} gap={'23px'}>
                        <Stack 
                            flexDirection={'column'} 
                            justifyContent={'space-between'}
                            alignItems={'stretch'} 
                            gap={'20px'} 
                            width={'50%'}
                        >
                            {pieChartItems.slice(0, 4).map((item: any) => (
                                    <Stack 
                                        key={item.id} 
                                        flexDirection={'row'} 
                                        justifyContent={'space-between'} 
                                        alignItems={'center'} 
                                        gap={'10px'}
                                    >
                                        <Stack flexDirection={'row'} alignItems={'center'} gap={'5px'}>
                                            <Sample />
                                            <Typography
                                                fontSize={13}
                                                fontWeight={400}
                                                lineHeight={'18px'}
                                                color={colors.grayCC}
                                            >
                                                {item.name}
                                            </Typography>
                                            
                                        </Stack>
                                        <Stack flexDirection={'row'} alignItems={'center'} gap={'8px'}>
                                            <Typography
                                                fontSize={13}
                                                fontWeight={500}
                                                lineHeight={'18px'}
                                                color={colors.white}
                                            >
                                                {`${item.price}%`}
                                            </Typography>
                                            <Stack width={8} height={8} sx={{backgroundColor: item.color}} />
                                        </Stack>  
                                    </Stack> 
                            ))}           
                        </Stack>  
                        <Stack 
                            flexDirection={'column'} 
                            justifyContent={'space-between'}
                            alignItems={'stretch'} 
                            gap={'20px'} 
                            width={'50%'}
                        >
                            {pieChartItems.slice(4, 8).map((item: any) => (
                                    <Stack 
                                        key={item.id} 
                                        flexDirection={'row'} 
                                        justifyContent={'space-between'} 
                                        alignItems={'center'} 
                                        gap={'10px'} 
                                    >
                                        <Stack flexDirection={'row'} alignItems={'center'} gap={'5px'}>
                                            <Sample />
                                            <Typography
                                                fontSize={13}
                                                fontWeight={400}
                                                lineHeight={'18px'}
                                                color={colors.grayCC}
                                            >
                                                {item.name}
                                            </Typography>
                                            
                                        </Stack>
                                        <Stack flexDirection={'row'} alignItems={'center'} gap={'8px'}>
                                            <Typography
                                                fontSize={13}
                                                fontWeight={500}
                                                lineHeight={'18px'}
                                                color={colors.grayCC}
                                            >
                                                {`${item.price}%`}
                                            </Typography>
                                            <Stack width={8} height={8} sx={{backgroundColor: item.color}} />
                                        </Stack>   
                                    </Stack>  
                            ))}
                        </Stack> 
                    </Stack>      
                </Stack>
            </Stack>
    );
}

const chartItems = [
    {
        id: 1,
        label: 'GBP'
    },
    {
        id: 2,
        label: 'BTC'
    },
    {
        id: 3,
        label: 'ETH'
    },
];

const pieChartItems = [
    {
        id: 1,
        name: 'PSY',
        price: 97.65,
        color: colors.blue45
    },
    {
        id: 2,
        name: 'ETH',
        price: 2.35,
        color: colors.orangeFF
    },
    {
        id: 3,
        name: 'INS',
        price: 0.00,
        color: colors.green1B
    },
    {
        id: 4,
        name: 'HEX',
        price: 0.00,
        color: colors.orangeFFB
    },
    {
        id: 5,
        name: 'PLS',
        price: 0.00,
        color: colors.gray8C
    },
    {
        id: 6,
        name: 'HEX',
        price: 0.00,
        color: colors.green1B
    },
    {
        id: 7,
        name: 'HSF',
        price: 0.00,
        color: colors.orangeED
    },
    {
        id: 8,
        name: 'Other',
        price: 0.00,
        color: colors.grayC9
    },
]

export default Charts;
import React from 'react';
import "./styles.scss";
import Tabs from '@mui/material/Tabs';
import Tab, { TabProps } from '@mui/material/Tab';
import { Stack, Typography, styled, TabsProps } from '@mui/material';
import RightSide from './RightSide';
import {

  ReportImg
} from 'src/assets/icons';

import { Container, Button } from '../../components';
import { colors } from '../../colors';
import dataGroupButton from "../../assets/fake-data/data-group-button";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import RankingDetail from './RankingDetail';
import Header from "src/components/header";
import dataTab from 'src/assets/fake-data/data-tab';

const StyledTabs = styled((props: TabsProps) => (
  <Tabs
    orientation='vertical'
    {...props}
    TabIndicatorProps={{ style: { display: 'none' } }}
  />
))({
  width: '220px',
  paddingRight: "16px",
  fontFamily: "Chakra Petch",
  fontSize: '16px',
  fontWeight: 700,
  '& .Mui-selected': {
    color: 'white',
    backgroundColor: '#14C2A3',
  },
});

const StyledTab = styled((props: TabProps) => (
  <Tab
    iconPosition="start"
    disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  justifyContent: 'left',
  marginBottom: "12px",
  gap: '15px',
  padding: '8px 0px 8px 16px',
  minHeight: '40px',
  color: '#B1BBCE',
  width: '100%',
  '&.Mui-selected': {
    color: '#fff',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));

export default function TopChainBlock(): React.ReactElement {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [value, setValue] = React.useState<string>(searchParams.get('sort') ?? "");
  const { id } = useParams();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate('/top-influencers?sort=' + newValue);
    // setSearchParams({ sort: newValue });
    // setValue(newValue);
  };
  React.useEffect(() => {
    setValue(searchParams.get('sort') ?? "");
  }, [searchParams.get('sort')])

  return (<>
    <Header />
    <Stack id='influencer' sx={{ width: '100%', marginTop: '45px' }}>
      <Container className='influencer-container'>
        <Stack className='influencer-box' direction='row' paddingBottom={'50px'}>

          <StyledTabs
            className='influencer-tab-box'
            sx={{
              // width: '234px !important',
              alignItems: 'end',
              paddingRight: 0,
              // '& .MuiTabs-scroller': {
              //   width: '218px !important'
              // },
              '& .MuiTab-labelIcon': {
                fontSize: 16,
                fontWeight: 700,
                lineHeight: '20px'
              }
            }}
            // startIcon={<ReportImg />}
            value={value}
            onChange={handleChange}
            aria-label="styled tabs"
          >
            {dataTab.map((tab: any) => {
              return (
                <StyledTab
                  key={tab.id}
                  icon={value === tab.value ? tab.targetImg : tab.img}
                  label={tab.label}
                  value={tab.value}
                />
              )
            })}
          </StyledTabs>

          {id ? (<>
            <RankingDetail id={id} />
            <Stack className='influencer-right-side' sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              padding: '37px 0 0 0'
            }}>
              {dataGroupButton.map((item: any) => (
                <Button
                  variant={"outlined"}
                  LabelColor={colors.white}
                  borderColor={colors.green}
                  sx={{
                    width: 301,
                  }}
                  startIcon={item.icon}
                  onClick={() => console.log("")}
                >
                  <Typography
                    fontSize={16}
                    fontWeight={600}
                    color={colors.white}
                    lineHeight={'34px'}
                  >
                    {item.name}
                  </Typography>
                </Button>
              ))}
              <Button
                variant={"text"}
                LabelColor={colors.white}
                borderColor={colors.green}
                sx={{
                  width: 301,
                }}
                startIcon={<ReportImg />}
                onClick={() => console.log("")}
              >
                <Typography
                  fontSize={16}
                  fontWeight={600}
                  color={colors.white}
                  lineHeight={'34px'}
                >
                  Report
                </Typography>
              </Button>
            </Stack>
          </>) : (
            <RightSide selectedMenu={value} />
          )}
        </Stack>
      </Container>
    </Stack>
  </>)
}
import { Checkbox, CheckboxProps, styled } from "@mui/material";
import { colors } from "src/colors";

//////
//Gh//
//////

const Icon = styled('span')(() => ({
    borderRadius: 4,
    width: 18,
    height: 18,
    backgroundColor: 'transparent',
    border: `2px solid ${colors.green1B}`
}));
  
const CheckedIcon = styled(Icon)({
    backgroundColor: colors.orangeFF,
    border: `1px solid ${colors.orangeFF}`,
    '&:before': {
      content: '""',
      display: 'block',
      width: '100%',
      height: '100%',
      borderRadius: 4,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 20%',
      backgroundImage: "url(\"data:image/svg+xml;charset=utf-8,%3Csvg width='11' height='12' viewBox='0 0 11 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='path-1-inside-1_1011_2231' fill='white'%3E%3Cpath d='M0.369873 7.7998L7.44095 0.728732L10.9765 4.26427L3.90541 11.3353L0.369873 7.7998Z'/%3E%3C/mask%3E%3Cpath d='M3.90541 11.3353L2.4912 12.7496L3.90541 14.1638L5.31963 12.7496L3.90541 11.3353ZM9.56227 2.85006L2.4912 9.92113L5.31963 12.7496L12.3907 5.67848L9.56227 2.85006ZM5.31963 9.92113L1.78409 6.38559L-1.04434 9.21402L2.4912 12.7496L5.31963 9.92113Z' fill='%230E2331' mask='url(%23path-1-inside-1_1011_2231)'/%3E%3C/svg%3E%0A\")"  
    }
});

export const MyCheckbox = (props: CheckboxProps) => {
    return (
      <Checkbox
        sx={{
          '&:hover': { bgcolor: 'transparent' },
        }}
        disableRipple
        checkedIcon={<CheckedIcon />}
        icon={<Icon />}
        {...props}
      />
    );
}

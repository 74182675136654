import React from 'react';
import { 
    Typography, 
    Menu, 
    Stack, 
    MenuItem,
    Divider
} from '@mui/material';
import { colors } from '../../colors';
import { isLogin, removeToken } from 'src/helpers/utils';
import { Button } from "..";
import { useNavigate } from "react-router-dom";

export const LoginMenuButton = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if(isLogin()) {
            setAnchorEl(event.currentTarget);
        } else {
            navigate('/auth');  
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logOut = () => {
        setAnchorEl(null);
        removeToken();
        handleClose();
        navigate('/auth');
    };

    return (
        <Stack direction="row" spacing={2}>
            <Button
                variant={"outlined"}
                LabelColor={colors.white}
                borderColor={colors.white}
                borderWidth="1px"
                sx={{
                    cursor: 'pointer',
                    width: 117 
                }}
                onClick={handleClick}
            >
                <Typography
                    fontSize={16}
                    fontWeight={600}
                    color={colors.white}
                    lineHeight={"24px"}
                    textTransform={'uppercase'}
                >
                    {isLogin() ? 'Account' : 'Login'}
                </Typography>
            </Button>
            {isLogin() && 
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                    sx={{
                        '& .MuiPaper-root': {
                            backgroundColor: colors.default,
                            border: `1px solid ${colors.green}`,
                            borderRadius: '0',
                            paddingY: '5px',
                            width: '235px'
                        }
                    }}
                >
                    <MenuItem 
                        sx={{  
                            paddingY: '3px',            
                            '& .MuiMenuItem-root': {
                                    color: colors.white
                            }
                        }}
                        // onClick={handleClose}
                    >
                        <Typography 
                            fontSize={16}
                            fontWeight={600}
                            color={colors.white}
                            lineHeight={'34px'}
                        >
                            Post a feed
                         </Typography>
                    </MenuItem>

                    <MenuItem 
                        sx={{  
                            paddingY: '3px',            
                            '& .MuiMenuItem-root': {
                                    color: colors.white
                            }
                        }}
                    >
                        <Typography 
                            fontSize={16}
                            fontWeight={600}
                            color={colors.white}
                            lineHeight={'34px'}
                        >
                            Contact us
                         </Typography>
                    </MenuItem>

                    <MenuItem 
                        sx={{  
                            paddingY: '3px',            
                            '& .MuiMenuItem-root': {
                                    color: colors.white
                            }
                        }}
                    >
                        <Typography 
                            fontSize={16}
                            fontWeight={600}
                            color={colors.white}
                            lineHeight={'34px'}
                        >
                            Settings
                         </Typography>
                    </MenuItem>

                    <Stack display={'flex'} justifyContent={'center'} flexDirection={'row'} paddingY={'6px'}>
                        <Divider 
                            orientation={"vertical"}
                            flexItem={true} 
                            sx={{
                                height: "1px",
                                borderWidth: "1px",
                                width: '86%',
                                borderColor: colors.green0D
                            }} 
                        />
                    </Stack>

                    <MenuItem 
                        sx={{  
                            paddingY: '3px',            
                            '& .MuiMenuItem-root': {
                                    color: colors.white
                            }
                        }}
                        onClick={() => logOut()}
                    >
                        <Typography 
                            fontSize={16}
                            fontWeight={600}
                            color={colors.white}
                            lineHeight={'34px'}
                        >
                            Log out
                         </Typography>
                    </MenuItem>
                </Menu>
            }
        </Stack>
    );
};


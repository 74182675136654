import { Stack, Card, CardContent, Typography } from '@mui/material';
import { colors } from 'src/colors';
import { GreenRightArrow } from 'src/assets/icons';
import { useNavigate } from 'react-router-dom';
interface Props {
    items?: any
}

export const LatestArticleItem = (props: Props) => {
    const { items } = props;
    const navigate = useNavigate();
    return (
        <Card sx={{
            width: '367px',
            borderRadius: '8px',
            border: '1px solid rgba(22, 49, 89)',
            backgroundColor: 'transparent',
            position: 'relative'
        }}>
            <CardContent sx={{
                gap: '15px',
                display: 'flex',
                flexDirection: 'column',
            }}>
                {items.img}
                <Typography
                    fontSize={14}
                    fontWeight={700}
                    color={colors.white}
                    lineHeight={'21px'}
                    height={'42px'}
                >
                    {items.content}
                </Typography>
                <Stack sx={{

                }}>
                    <Stack className='time-box' sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}>
                        <Typography
                            fontSize={14}
                            fontWeight={400}
                            color={colors.grayB1}
                            lineHeight={'21px'}
                        >
                            {items.lastSeen}
                        </Typography>
                        <Stack sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '15px'
                        }}>
                            <Typography
                                fontSize={16}
                                fontWeight={700}
                                color={colors.green}
                                lineHeight={'16px'}
                                sx={{ cursor: 'pointer' }}
                                onClick={() => navigate('latest-news')}
                            >
                                Read Now
                            </Typography>
                            <GreenRightArrow />
                        </Stack>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
}

export default LatestArticleItem;